import { Box, Center, VStack } from '@chakra-ui/react'
import '@fontsource/coming-soon'
import webpImg from '../images/backgrounds/bg.webp'
import bgImg from '../images/backgrounds/bg.jpg'

const comingSoon = {
    textShadow: '1px 1px 1px #000',
    fontWeight: '800',
    fontSize: '4.5em',
    fontVariantCaps: 'petite-caps',
    color: 'aliceblue',
    fontFamily: 'Coming Soon',
}

const app = {
    height: '100%',
    textAlign: 'center',
}

const overlay = {
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    position: 'fixed',
    zIndex: '10',
}

const backgroundImage = {
    backgroundImage: `url(${webpImg}), url(${bgImg})`,
    zIndex: '-1',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
}

function ComingSoon() {
    return (
        <Box style={overlay}>
            <Box style={app}>
                <VStack h="100vh" justify="center" alignItems="center">
                    <Box as="h1" style={comingSoon}>
                        Maintainance in progress
                    </Box>
                    <Box as="h3" style={comingSoon}>
                        We will be back online very soon
                    </Box>
                </VStack>
            </Box>
            <div style={backgroundImage}></div>
        </Box>
    )
}

export default ComingSoon
