import { Box, chakra, Image, Img, useMediaQuery } from '@chakra-ui/react'
import { breakpoints } from '../pages/layouts/CustomTheme'

import WorldImg from '../images/backgrounds/world.jpg'
import WorldGoImg from '../images/backgrounds/world-go.png'
import FrensImg from '../images/backgrounds/bg.png'
import WakumbaImg from '../images/backgrounds/wakumba.jpg'
import WakumbaBlessImg from '../images/backgrounds/wakumba-bless.png'
import StoryMobImg from '../images/backgrounds/story-mob.png'
import MintImg from '../images/backgrounds/mint.jpg'
import MintHoverImg from '../images/backgrounds/mint-hover.png'
import MintIdleImg from '../images/backgrounds/mint-idle.png'
import SoldoutImg from '../images/backgrounds/soldout.png'
import NextCapImg from '../images/backgrounds/cap2-bg.jpg'
import PureImg from '../images/backgrounds/pure.jpg'
import GalleryImg from '../images/backgrounds/gallery.png'
import MintOpenImg from '../images/backgrounds/mint-open.jpg'
import MintWLNotImg from '../images/backgrounds/mint-wl-not.png'
import MintPublicNotImg from '../images/backgrounds/mint-public-not.png'
import DoDoPrayImg from '../images/backgrounds/dodo-pray.jpg'
import bgCalimSwordModal from './ClaimSwordModal/bg.png'
import bgMeditationMp4 from '../images/backgrounds/bg-meditation.mp4'
import bgMeditationMobImg from '../images/backgrounds/bg-meditation-mobile.jpg'
import bgMountainPage from '../images/backgrounds/bg-mountain-page.png'
import bgForgeMp4 from '../images/backgrounds/bg-forge.m4v'
import bgForgeImg from '../images/backgrounds/bg-forge.jpg'

import MintGoGif from '../images/gifs/mint.gif'
import MintClickGif from '../images/gifs/mint-click.gif'
import MintLoadingGif from '../images/gifs/mint-loading.gif'
import CongrazGif from '../images/gifs/congraz.gif'
import CompassGif from '../images/gifs/compass.gif'
import TrailGif from '../images/gifs/trail.gif'
import WorldSvg from '../images/svgs/world.svg'

export function HomeMap(props) {
    return (
        <chakra.div
            zIndex="-2"
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            m="0 !important"
            draggable={false}
            userSelect="none"
            {...props}
        >
            <object type="image/svg+xml" data={WorldSvg} width="100%" height="100%" draggable={false}>
                ...
            </object>
        </chakra.div>
    )
}

const Background = chakra('div', {
    baseStyle: {
        zIndex: -2,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: '0 !important',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
})

export function WorldMap(props) {
    return <Background backgroundImage={`url(${WorldImg})`} {...props} />
}

export function WorldGoMap(props) {
    return <Background backgroundImage={`url(${WorldGoImg})`} {...props} />
}

export function CompassGifMap(props) {
    return <Background backgroundImage={`url(${CompassGif})`} zIndex="-1" {...props} id="comp" />
}

export function TrailGifMap(props) {
    return <Background backgroundImage={`url(${TrailGif})`} zIndex="-1" {...props} id="trail" />
}

export function DoDoPrayMap() {
    return <Background backgroundImage={`url(${DoDoPrayImg})`} />
}

export function FrensMap(props) {
    return <Background backgroundImage={`url(${FrensImg})`} {...props} />
}

export function WakumbaMap() {
    return <Background backgroundImage={`url(${WakumbaImg})`} />
}

export function ShareMap(props) {
    return <Background backgroundImage={`url(${WakumbaImg})`} {...props} />
}

export function WakumbaBlessMap(props) {
    return <Background backgroundImage={`url(${WakumbaBlessImg})`} {...props} />
}

export function MintMap() {
    return <Background backgroundImage={`url(${MintImg})`} />
}

export function MintHoverMap(props) {
    return <Background backgroundImage={`url(${MintHoverImg})`} {...props} />
}

export function MintOpenMap(props) {
    return <Background backgroundImage={`url(${MintOpenImg})`} {...props} />
}

export function MintGoGifMap(props) {
    return <Background backgroundImage={`url(${MintGoGif})`} {...props} />
}

export function CongrazGifMap(props) {
    return <Background backgroundImage={`url(${CongrazGif})`} {...props} />
}

export function MintLoadingGifMap(props) {
    return <Background backgroundImage={`url(${MintLoadingGif})`} {...props} />
}

export function MintClickGifMap(props) {
    return <Background backgroundImage={`url(${MintClickGif})`} {...props} />
}

export function MintIdleMap(props) {
    return <Background backgroundImage={`url(${MintIdleImg})`} {...props} />
}

export function MintWLNotMap(props) {
    return <Background backgroundImage={`url(${MintWLNotImg})`} {...props} />
}

export function MintPublicNotMap(props) {
    return <Background backgroundImage={`url(${MintPublicNotImg})`} {...props} />
}

export function SoldoutMap(props) {
    return <Background backgroundImage={`url(${SoldoutImg})`} {...props} />
}

export function NextCapMap(props) {
    return <Background backgroundImage={`url(${NextCapImg})`} {...props} />
}

export function GalleryMap(props) {
    return <Background backgroundImage={`url(${PureImg})`} {...props} />
}

export function StoryMap(props) {
    return (
        <Background
            backgroundColor={{ base: '#dcd2b9', sm: 'transparent' }}
            backgroundImage={{ base: 'none', sm: `url(${StoryMobImg})` }}
            {...props}
        />
    )
}

export function GalleryBgImg() {
    return <Image src={GalleryImg} objectFit="contain" />
}

export function MeditationMap({ id }) {
    return (
        <Box id={id}>
            <Box display={{ base: 'block', md: 'none' }}>
                <Background backgroundImage={bgMeditationMobImg}> </Background>
            </Box>
            <Box display={{ base: 'none', md: 'block' }}>
                <Background>
                    <chakra.div
                        as="video"
                        src={bgMeditationMp4}
                        autoPlay
                        loop
                        muted
                        objectFit="cover"
                        w="100%"
                        h="100%"
                        objectPosition="center"
                        zIndex="-2"
                        playsInline
                        controls={false}
                    />
                </Background>
            </Box>
        </Box>
    )
}

export function ClaimSwordModalMap() {
    return <Image src={bgCalimSwordModal} objectFit="cover" zIndex="-1" w="100%" h="100%" />
}

export function MountainMap({ id }) {
    return (
        <Box
            sx={{
                zIndex: -2,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                margin: '0 !important',
                backgroundSize: 'cover',
                backgroundImage: bgMountainPage,
                backgroundAttachment: 'fixed',
                backgroundPosition: '25%',
                backgroundRepeat: 'no-repeat',
            }}
        ></Box>
    )
}

export function ForgeMap({ id }) {
    const [isMd] = useMediaQuery(`(max-width: ${breakpoints.md})`)
    return (
        <chakra.div id={id}>
            {!isMd ? (
                <>
                    <Background>
                        <chakra.div
                            as="video"
                            src={bgForgeMp4}
                            autoPlay
                            loop
                            muted
                            objectFit="cover"
                            w="100%"
                            h="100%"
                            objectPosition="center"
                            zIndex="-2"
                            playsInline
                            controls={false}
                        />
                    </Background>
                </>
            ) : (
                <>
                    <Background backgroundImage={bgForgeImg}> </Background>
                </>
            )}
        </chakra.div>
    )
}

export default Background
