import React from 'react'
import { Box, Text, Button, Center, Img, Image, Input, useToast, HStack, VStack, Flex } from '@chakra-ui/react'
import mountain_rewards_mint_card_bg from './assets/mint/mountain_rewards_mint_card_bg.png'
import success_loading_bg from './assets/mint/success_loading_bg.png'
import ongoing_mountain from './assets/mint/ongoing_mountain.png'
import success_bottom from './assets/mint/success_bottom.png'
import view_on_opensea from './assets/mint/view_on_opensea.png'
import { useEnv } from '../../utils/Environment'
import { validateAmountRange } from '../../utils/validate'
import ImgButton from '../../components/ImgButton'
import { useRewardsMint } from './MountainContracts'
import { Circles } from 'react-loader-spinner'
import { errorToString, handleContractError } from '../../utils/ContractUtils'

const SummonCard = ({ args }) => {
    const styleSuccessBottom = {
        backgroundAttachment: 'local',
        backgroundPosition: 'center',
        backgroundImage: `url(${success_bottom})`,
        backgroundSize: '100% 110%',
    }
    const startBgStyle = {
        aspectRatio: '1142 / 1600',
        width: '300px',
        height: '450px',
        flexDirection: 'column',
        backgroundImage: `url(${mountain_rewards_mint_card_bg})`,
        backgroundAttachment: 'local',
        backgroundPosition: 'center',
        backgroundSize: '100% 100%',
    }

    const cancelBorderStyle = {
        border: 'none',
        _hover: {
            border: 'none',
        },
        _focusVisible: {
            border: 'none',
        },
    }

    const ongoingStyle = {
        ...startBgStyle,
        '@keyframes fga': {
            from: { transform: 'rotateY(0deg)' },
            to: { transform: 'rotateY(360deg)' },
        },
        animation: 'fga 6s infinite ease-in-out',
    }

    const [upgradeStatus, setUpgradeStatus] = React.useState('START')
    const toast = useToast()
    const { isDev, network, contracts } = useEnv()
    const rarityLevel = args[1]
    const [summonAmount, setSummonAmount] = React.useState(
        rarityLevel ? rarityLevel[0] + rarityLevel[1] + rarityLevel[2] + rarityLevel[3] + rarityLevel[4] : 0
    )
    // debug only
    const [id, setId] = React.useState(0)
    const [mountainSrc, setMountainSrc] = React.useState('')
    const [receivedMountainId, setReceivedMountainId] = React.useState(1)
    const [disableBtn, setDisableBtn] = React.useState(false)

    // ============== Contract: Enhance Sword ==============
    const summon = useRewardsMint({
        args: args,
        onLoadError: (error) => {
            const errStr = errorToString(error)
            if (errStr.indexOf('Nonce is already used') !== -1) {
                console.log('NOCE USED!')
                if (!toast.isActive('rewards-claimed'))
                    toast({
                        id: 'rewards-claimed',
                        title: `Rewards claimed already.`,
                        status: 'info',
                        isClosable: true,
                    })
                if (!disableBtn) setDisableBtn(true)
            } else {
                handleContractError('Prepare', error, toast)
            }
        },
        onLoading: () => {
            if (toast.isActive('confirmSummonOnWallet')) {
                toast.close('confirmSummonOnWallet')
            }
            toast({
                id: 'confirmSummonOnWallet',
                title: `Please confirm in your wallet to summon ${summonAmount} mountains`,
                status: 'info',
                isClosable: true,
                duration: 10_000,
            })
        },
        onTxStarted: () => {
            if (toast.isActive('confirmSummonOnWallet')) {
                toast.close('confirmSummonOnWallet')
            }
            setUpgradeStatus('ONGOING')
        },
        onTxCancelled: () => {
            if (toast.isActive('confirmSummonOnWallet')) {
                toast.close('confirmSummonOnWallet')
            }
        },
        onMintSuccess: (mountainId) => {
            setReceivedMountainId(mountainId)
            setUpgradeStatus('SUCCESS')
        },
    })

    const onMint = () => {
        summon?.()
    }

    function fetchMountainData(tokenId) {
        fetch(
            `https://api.opensea.io/api/v1/asset/0xf933f97d4d1f3a245401d0f70769ead59d34b047/${tokenId}/?force_update=true`
        )
            .then((msg) => {
                console.log('Got API UPDATE: ' + msg)
            })
            .catch((err) => {
                console.log('UPDATE API Failed: ', err)
            })

        console.log('Fetching MT image url ... ')

        setTimeout(() => {
            tokenId !== undefined &&
                fetch(`https://dodofrens.xyz/metadata/mtid/${tokenId}`)
                    .then((_msg) => _msg.json())
                    .then((_data) => {
                        const mountainSrc = `https://gateway.dodofrens.com/ipfs/QmYeSf6eLFJj6zGV3gvei1HtP8hw1EAuSJos6AAUiTBn2B/mt-images-prod/${_data.edition}.png`
                        console.log('Got MT Image ... ' + mountainSrc)
                        setMountainSrc(mountainSrc)
                    })
                    .catch((err) => {
                        console.log('Fetch RefCode Failed: ', err)
                        //ignore and set defaults
                        const mountainSrc = `https://gateway.dodofrens.com/ipfs/QmYeSf6eLFJj6zGV3gvei1HtP8hw1EAuSJos6AAUiTBn2B/mt-images-prod/0.png`
                        setMountainSrc(mountainSrc)
                    })
        }, 1500)
    }

    React.useEffect(() => {
        receivedMountainId && fetchMountainData(receivedMountainId)
    }, [receivedMountainId])

    return (
        <Flex pointerEvents="auto" w="100%" flexDirection="column" gap="5%">
            {isDev && (
                <Button
                    position="fixed"
                    left="10px"
                    top="10px"
                    onClick={() => {
                        setId(id + 1)
                        setUpgradeStatus(['START', 'ONGOING', 'SUCCESS'][(id + 1) % 3])
                    }}
                >
                    Debug: {['START', 'ONGOING', 'SUCCESS'][id % 3]} =&gt; Next:{' '}
                    {['START', 'ONGOING', 'SUCCESS'][(id + 1) % 3]}
                </Button>
            )}

            {
                {
                    START: (
                        <HStack justify="end" sx={startBgStyle} position="relative">
                            <Box w="100%" fontSize="2rem" pb="2rem" textAlign="center" color={'white'}>
                                x {summonAmount} MT
                            </Box>
                            <Box w="70%" pb="10px" pl="70px">
                                <ImgButton
                                    noMargin
                                    variant="mountain_free_mint"
                                    onClick={onMint}
                                    disabled={disableBtn || !summon}
                                />
                            </Box>
                        </HStack>
                    ),
                    ONGOING: (
                        <Center sx={ongoingStyle}>
                            <Img src={ongoing_mountain} />
                        </Center>
                    ),
                    SUCCESS: (
                        <VStack gap="0" justify="center" w="300px" h="450px">
                            <Box boxShadow=" 0 3px 6px rgba(0,0,0,0.4); " h="300px" w="300px">
                                <Image
                                    src={mountainSrc}
                                    fallback={
                                        <Center>
                                            <Image src={success_loading_bg} />
                                            <Box position="absolute">
                                                <Circles
                                                    height="80"
                                                    width="80"
                                                    color="white"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </Box>
                                        </Center>
                                    }
                                />
                            </Box>

                            <Center sx={styleSuccessBottom} position="relative" w="100%" h="150px" mt="0 !important">
                                <Img
                                    src={view_on_opensea}
                                    position="absolute"
                                    top="80px"
                                    w="200px"
                                    onClick={() =>
                                        window.open(
                                            `https://${isDev ? 'testnets.' : ''}opensea.io/assets/${network.name}/${
                                                contracts.mountain
                                            }/${receivedMountainId}`
                                        )
                                    }
                                    _hover={{ cursor: 'pointer' }}
                                />
                            </Center>
                        </VStack>
                    ),
                }[upgradeStatus]
            }
        </Flex>
    )
}

export default SummonCard
