import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { useAccount } from 'wagmi'
import { MintHoverMap } from '../components/Backgrounds'
import ImgButton from '../components/ImgButton'
import { FadeInOut } from './Animations'

import md5 from 'md5'

function shareTwitter(address, code) {
    const tags = [
        'NFT',
        'NFTs',
        'NFTs',
        'FreeMint',
        'FreeMint',
        'FreeMints',
        'FreeMints',
        'NFTCommunity',
        'NFTCommunity',
        'NFTCommunity',
        'NFTCommmunity',
        'NFTCommunitys',
        'NFTCommunitys',
        'NFTCommunityCryptoArt',
        'NFTGiveaway',
        'NFTGiveaways',
        'whitelist',
        'GiveawayAlert',
        'freemintNFT',
        'Ethereum',
        'NFTcollections',
        'nftcollector',
        'nftcollectors',
    ]
    const randTag = tags[Math.floor(Math.random() * tags.length)]
    const prefix = '%20%23'
    const hashTag = prefix + randTag
    const url = `https://twitter.com/intent/tweet?text=DoDoFrens%20are%20coming%20%F0%9F%90%B5%F0%9F%A6%A7%F0%9F%A6%A3%F0%9F%A6%9B%F0%9F%A6%93%0A%0AJoin%20me%3A%20dodofrens.xyz%0A%0A%20%F0%9F%94%A5Code%F0%9F%94%A5%3A%20${code}%0A%0AFree%20mint%20%23DoDoFrens%20%40DoDoFrens${hashTag}`
    window.open(url, '_blank', 'noopener,noreferrer')
    address &&
        fetch(`/api/log/${address}/shared`)
            .then((_msg) => _msg.json())
            .catch((err) => {
                console.log('Logging Failed: ', err)
            })
}

function ShareTwitterBtn(props) {
    const { refCode, ...rest } = props
    const { address, isConnected } = useAccount()
    return (
        <Box>
            <ImgButton variant="twitter" onClick={() => shareTwitter(address, refCode)} {...rest} />
        </Box>
    )
}

function ShareTwitterIcon() {
    const { address, isConnected } = useAccount()

    const [hover, setHover] = useState(false)

    const [refCode, setRefCode] = useState('')

    useEffect(() => {
        address &&
            fetch(`/api/ref/${address}`)
                .then((_msg) => _msg.json())
                .then((_data) => {
                    if (address === _data.addr) {
                        setRefCode(`${_data.code}`)
                    }
                })
                .catch((err) => {
                    console.log('Fetch RefCode Failed: ', err)
                    //ignore and set defaults
                    isConnected ? setRefCode(`${md5(address).substring(0, 8)}`) : setRefCode('rtc101')
                })
    }, [address, isConnected])

    return (
        <>
            <Box
                position="absolute"
                top="45%"
                left="40%"
                w="200px"
                h="200px"
                // backgroundColor="whiteAlpha.500"
                onMouseOver={() => isConnected && setHover(true)}
                onMouseOut={() => isConnected && setHover(false)}
                cursor={isConnected && 'pointer'}
                onClick={() => isConnected && shareTwitter(address, refCode)}
            ></Box>

            {isConnected && !hover && <MintHoverMap id="map1" zIndex="-1" animation={FadeInOut} />}
            {hover && <MintHoverMap id="map2" zIndex="-1" />}
        </>
    )
}

export { ShareTwitterBtn, ShareTwitterIcon }
