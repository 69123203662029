import { ethers } from 'ethers'
import { useNetwork } from 'wagmi'

import navis from '../components/data/navis.json'

function useEnv() {
    const { chain } = useNetwork()
    return {
        isDev: process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production',
        isProd: process.env.REACT_APP_CUSTOM_NODE_ENV === 'production',
        isStaging: process.env.REACT_APP_CUSTOM_NODE_ENV === 'staging',
        network: {
            name: process.env.REACT_APP_CUSTOM_NODE_ENV === 'production' ? 'ethereum' : chain?.network,
            chain: process.env.REACT_APP_CUSTOM_NODE_ENV === 'production' ? 'mainnet' : chain?.network,
        },
        contracts: {
            dodo: ethers.utils.getAddress(process.env.REACT_APP_MINT_CONTRACT_ADDR),
            sword: ethers.utils.getAddress(process.env.REACT_APP_AIRDROP_CONTRACT_ADDR),
            staking: ethers.utils.getAddress(process.env.REACT_APP_STAKING_CONTRACT_ADDR),
            faith: ethers.utils.getAddress(process.env.REACT_APP_FAITH_TOKEN_CONTRACT_ADDR),
            forge: ethers.utils.getAddress(process.env.REACT_APP_FORGE_CONTRACT_ADDR),
            mountain: ethers.utils.getAddress(process.env.REACT_APP_MOUNTAIN_CONTRACT_ADDR),
        },
        collections: {
            dodo: 'https://opensea.io/collection/dodofrensxyz',
            sword: 'https://opensea.io/collection/sword-of-bravery',
            mountain: 'https://opensea.io/collection/mountain-x-dodofrens',
        },
    }
}

function useLinks() {
    const env = useEnv()
    return {
        openSeaItemUrl: `https://${env.isDev ? 'testnets.' : ''}opensea.io/assets/${env.network.name}/`,
        etherscanTxUrl: `https://${env.isDev ? env.network.chain + '.' : ''}etherscan.io/tx/`,
    }
}

function useNavis() {
    return Object.fromEntries(
        navis
            .map((n) => {
                n['path'] = `${n.url}?from=${window.location.pathname.substring(1)}`
                return n
            })
            .map((n) => {
                return [n['name'], n]
            })
    )
}

export { useEnv, useLinks, useNavis }
