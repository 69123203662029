import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Link, Text, useToast } from '@chakra-ui/react'
import { BigNumber, ethers } from 'ethers'
import { useEffect, useRef, useState } from 'react'
import { useAccount, useContractEvent, useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi'
import WarriorsDrop from '../../contracts/WarriorsDrop'
import MountainContract from '../../contracts/MountainNFT'
import { useEnv } from '../../utils/Environment'
import { handleContractError } from '../../utils/ContractUtils'

const rewardsContractConfig = {
    addressOrName: process.env.REACT_APP_MT_DROP_CONTRACT_ADDR,
    contractInterface: WarriorsDrop.abi,
}

const mountainContractConfig = {
    addressOrName: process.env.REACT_APP_MOUNTAIN_CONTRACT_ADDR,
    contractInterface: MountainContract.abi,
}

function useRewardsMint({ args, onLoadError, onLoading, onTxStarted, onTxCancelled, onMintSuccess }) {
    // console.log('......debugging start Rewards mint mountain:', args)

    const { config: rewardsMintConfig, error } = usePrepareContractWrite({
        ...rewardsContractConfig,
        functionName: 'airdropMT',
        args: args,
    })

    if (error) {
        onLoadError(error)
    }

    return useContractWriter(rewardsMintConfig, onLoading, onTxStarted, onTxCancelled, onMintSuccess)
}

function usePublicMint({ args, overrides, onLoading, onTxStarted, onTxCancelled, onMintSuccess, onLoadError }) {
    // console.log('......debugging start public mint mountain')
    // console.log('ARGS: qty=', args, ',value=', overrides ? overrides.value.toNumber() : 0)

    const { config: publicMintConfig, error } = usePrepareContractWrite({
        ...mountainContractConfig,
        functionName: 'mint',
        args: args,
        overrides: overrides,
    })

    if (error) {
        onLoadError(error)
    }

    // add 5% extra gas to avoid OFG error
    if (publicMintConfig && publicMintConfig.request && publicMintConfig.request.gasLimit) {
        console.log('publicMintConfig Gas:', publicMintConfig.request.gasLimit.toBigInt())
        publicMintConfig.request.gasLimit = publicMintConfig.request.gasLimit
            .mul(BigNumber.from('105'))
            .div(BigNumber.from('100'))
        console.log('publicMintConfig NEW Gas:', publicMintConfig.request.gasLimit.toBigInt())
    }

    return useContractWriter(publicMintConfig, onLoading, onTxStarted, onTxCancelled, onMintSuccess)
}

function useSummonMountain({ args, onLoading, onTxStarted, onTxCancelled, onMintSuccess }) {
    // console.log('......debugging start summon mountain')

    const { config: summonMountainConfig } = usePrepareContractWrite({
        ...mountainContractConfig,
        functionName: 'swordMint',
        args: args,
    })

    // add 5% extra gas to avoid OFG error
    if (summonMountainConfig && summonMountainConfig.request && summonMountainConfig.request.gasLimit) {
        console.log('summonMountainConfig Gas:', summonMountainConfig.request.gasLimit.toBigInt())
        summonMountainConfig.request.gasLimit = summonMountainConfig.request.gasLimit
            .mul(BigNumber.from('105'))
            .div(BigNumber.from('100'))
        console.log('summonMountainConfig NEW Gas:', summonMountainConfig.request.gasLimit.toBigInt())
    }

    return useContractWriter(summonMountainConfig, onLoading, onTxStarted, onTxCancelled, onMintSuccess)
}

function useContractWriter(contractConfig, onLoading, onTxStarted, onTxCancelled, onMintSuccess) {
    const { address } = useAccount()
    const toast = useToast()
    const waitForTxToastRef = useRef()

    const {
        data: contractData,
        isLoading,
        write: summonMountain,
    } = useContractWrite({
        ...contractConfig,
        onError(error) {
            handleContractError('Write', error, toast)
            // if failed, refresh again
            // setRefresh(refresh + 1)
            onTxCancelled()
        },
        onSuccess(msg) {
            onTxStarted()
        },
    })

    useEffect(() => {
        if (isLoading) {
            onLoading()
        }
    }, [isLoading])

    const [txResponse, setTxResponse] = useState(null)
    const { isDev, network } = useEnv()

    // Checking Transactions
    const {
        isLoading: isEnhanceTxLoading,
        isSuccess: isEnhanceTxSuccess,
        isError: isEnhanceTxError,
    } = useWaitForTransaction({
        confirmations: 1,
        hash: contractData?.hash,
        wait: contractData?.wait,
        onSuccess(data) {
            console.log('summon success:', data)
            if (!txResponse || txResponse.hash !== contractData?.hash) {
                const topicMountMinted = '0xc7a8b43b9bd3f605bca9409eb06329be626d6829b735c1e9422d5a9b8d88430e'

                const mintData = data.logs.filter((s) => s.topics[0] === topicMountMinted)[0]?.data
                // console.log('......debugging mintData' + JSON.stringify(mintData))
                if (mintData) {
                    const data = {
                        hash: contractData?.hash,
                        status: 'SUCCESS',
                        owner: '0x' + mintData.substring(2).substring(24, 64),
                        rarityLevel: [
                            BigNumber.from('0x' + mintData.substring(2).substring(64, 64 * 2)).toNumber(),
                            BigNumber.from('0x' + mintData.substring(2).substring(64 * 2, 64 * 3)).toNumber(),
                            BigNumber.from('0x' + mintData.substring(2).substring(64 * 3, 64 * 4)).toNumber(),
                            BigNumber.from('0x' + mintData.substring(2).substring(64 * 4, 64 * 5)).toNumber(),
                            BigNumber.from('0x' + mintData.substring(2).substring(64 * 5, 64 * 6)).toNumber(),
                        ],
                        mountainId: BigNumber.from('0x' + mintData.substring(2).substring(64 * 6, 64 * 7)).toNumber(),
                        from: 'await',
                    }
                    console.log('The mintData:', data)
                    setTxResponse(data)
                } else {
                    setTxResponse(null)
                }
            }
        },
        onError(error) {
            handleContractError('EnhanceTxError', error, toast)

            setTxResponse(null)
        },
        onSettled(data, error) {
            console.log('EnhanceTxSettle:', data, error)
            toast({
                title: `Transaction Confirmed!`,
                description: (
                    <Link
                        href={`https://${isDev ? network.chain + '.' : ''}etherscan.io/tx/${contractData?.hash}`}
                        isExternal={true}
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        float="left"
                        width="320px"
                    >
                        <ExternalLinkIcon />
                        Tx# {contractData?.hash}
                    </Link>
                ),
                position: 'top-right',
                status: 'info',
                isClosable: true,
            })

            if (waitForTxToastRef.current) {
                toast.close(waitForTxToastRef.current)
            }
        },
    })

    useEffect(() => {
        if (isEnhanceTxLoading) {
            waitForTxToastRef.current = toast({
                title: `Processing transaction, please wait for a while...`,
                status: 'success',
                isClosable: true,
                duration: 15 * 1000,
            })
        }
    }, [isEnhanceTxLoading])

    // Event Listeners
    useContractEvent({
        ...mountainContractConfig,
        eventName: 'MountainMinted',
        listener: (event) => {
            console.log('Received: MountainMinted Event', event)
            console.log(JSON.stringify(event), event)
            console.log('addr match?', event[0] === address)
            const hash = event[event.length - 1].transactionHash
            console.log('TransHash:', hash, contractData?.hash)

            if (event[0] === address && hash === contractData?.hash) {
                if (!txResponse || txResponse.hash !== hash) {
                    const owner = event[0]
                    const rarityLevel = event[1]
                    const mountainId = event[2]

                    setTxResponse({
                        hash: contractData?.hash,
                        status: 'SUCCESS',
                        address: address,
                        owner: owner,
                        rarityLevel: rarityLevel,
                        mountainId: mountainId,
                        from: 'event',
                    })
                }
            }
        },
    })

    useEffect(() => {
        if (txResponse) {
            if (txResponse.status === 'SUCCESS') {
                const owner = txResponse.owner
                const rarityLevel = txResponse.rarityLevel
                let totalCount = 0
                if (rarityLevel) {
                    totalCount = rarityLevel[0] + rarityLevel[1] + rarityLevel[2] + rarityLevel[3] + rarityLevel[4]
                }
                const mountainId = txResponse.mountainId
                console.log(`minted mountain ID ${mountainId} with rarity Level ${rarityLevel} for owner ${owner}`)
                toast({
                    title: `Successfully summoned ${totalCount} Mountain${totalCount > 1 ? 's' : ''}`,
                    status: 'success',
                    duration: 15_000,
                    isClosable: true,
                })
                onMintSuccess(mountainId)
            }
        }
    }, [txResponse, setTxResponse])

    return summonMountain
}

export { useSummonMountain, useRewardsMint, usePublicMint }
