import { Box, ChakraProvider, VStack } from '@chakra-ui/react'
import { Outlet, useLocation } from 'react-router-dom'
import { BtnWallet } from '../../components/BtnConnect'
import Header from '../../components/Header'
import customTheme from './CustomTheme'
import DodoStoreProvider from '../../contexts/DodoStoreProvider'
import '@fontsource/amatic-sc'
import '@fontsource/josefin-sans'
import { useAccount } from 'wagmi'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'

const scrollable = ['/story', '/faq', '/faqs']
const showWallet = ['/mint', '/wakumba', '/forge', '/meditation', '/share', '/mountain']

ReactGA.initialize('G-J634D4HVSW')

function App() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

    const debug = false

    const location = useLocation()

    const { address } = useAccount({
        onConnect() {
            debug &&
                address &&
                fetch(`/api/log/${address}/connect`)
                    .then((_msg) => _msg.json())
                    .catch((err) => {
                        console.log('Logging Failed: ', err)
                    })
        },
    })

    const isScrollable = () => {
        return scrollable.includes(location.pathname)
    }

    function getWindowSize() {
        const { innerWidth, innerHeight } = window
        return [innerWidth, innerHeight]
    }

    const [windowSize, setWindowSize] = useState(getWindowSize())

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize())
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    function isHeighLessThan700() {
        return windowSize[1] < 700
    }

    return (
        <ChakraProvider theme={customTheme}>
            <DodoStoreProvider>
                <Box className={isScrollable() ? 'no-overlay' : 'overlay'}>
                    <Header
                        className="pageHeader"
                        shortWindowSize={isHeighLessThan700()}
                        bg="whiteAlpha.500"
                        activePath={location.pathname}
                        position={isScrollable() ? 'relative' : 'fixed'}
                        left="0px"
                        top="0px"
                    />

                    <VStack className="pageMainSection" as="main" h="100%">
                        <Outlet />
                    </VStack>

                    <Box
                        className="pageFooter"
                        position="fixed"
                        float="left"
                        bottom="10px"
                        left="10px"
                        display={showWallet.includes(location.pathname) ? '' : 'none'}
                        maxH="200px"
                        as="footer"
                    >
                        <BtnWallet />
                    </Box>
                </Box>
            </DodoStoreProvider>
        </ChakraProvider>
    )
}

export default App
