import { Center, Container, Link, Heading } from '@chakra-ui/react'
import { React } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { WakumbaBlessMap } from '../../components/Backgrounds'
import ImgButton from '../../components/ImgButton'
import { FadeInOut, GrowShrink } from '../../components/Animations'

export default function StageWakumbaNext() {
    const navigate = useNavigate()
    const okBackgrounds = ['blessOk1', 'blessOk2']
    const okBg = okBackgrounds[Math.floor(Math.random() * okBackgrounds.length)]

    return (
        <Container>
            <Center>
                <ImgButton
                    _hover={{
                        animation: GrowShrink,
                    }}
                    onClick={() => navigate('/mountain')}
                    variant={okBg}
                />
            </Center>

            <Center>
                <Link
                    pr="10px"
                    fontSize="3xl"
                    size="xl"
                    color="white"
                    textShadow="0 0 5px #fff, 0 0 10px #fff, 0 0 15px #0073e6, 0 0 20px #0073e6, 0 0 25px #0073e6, 0 0 30px #0073e6, 0 0 35px #0073e6;"
                    as={NavLink}
                    to="/faq"
                >
                    <Heading>FAQ: Chapter Ⅰ</Heading>
                </Link>
            </Center>

            <WakumbaBlessMap zIndex="-1" animation={FadeInOut} />
        </Container>
    )
}
