export const validateAmountRange = (amount, min, max) => {
    const result = { isValid: true, message: null }
    if (isNaN(amount)) {
        result.isValid = false
        result.message = 'amount must be a number'
    } else if (amount < min) {
        result.isValid = false
        result.message = `amount must be no less than ${min}`
    } else if (amount > max) {
        result.isValid = false
        result.message = `amount must be no more than ${max}`
    }
    return result
}
