import {
    Box,
    Button,
    Center,
    Flex,
    Img,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    OrderedList,
    ListItem,
    Link,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
    Divider,
    Spacer,
    Heading,
    Badge,
    Spinner,
    Icon,
} from '@chakra-ui/react'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useEffect, useState } from 'react'
import ImgButton from './ImgButton'
import { Circles } from 'react-loader-spinner'
import { Alchemy, Network } from 'alchemy-sdk'
import { useEnv } from '../utils/Environment'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { ethers } from 'ethers'
import { FaBoxOpen } from 'react-icons/fa'

function BtnPray(props) {
    const [loading, setLoading] = useState(false)
    return (
        <Flex justify="center">
            {loading ? (
                <Circles height="100" width="100" color="#4299e1" ariaLabel="loading" />
            ) : (
                <ImgButton w="270px" variant="pray" onClick={() => props.search(props.address, setLoading)}></ImgButton>
            )}
        </Flex>
    )
}

function BtnWLChecker({ whitelist, search, onValid, onInvalid }) {
    return (
        <ConnectButton.Custom>
            {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
                return (
                    <div
                        id="btn-connect"
                        {...(!mounted && {
                            'aria-hidden': true,
                            style: {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',
                            },
                        })}
                    >
                        {(() => {
                            if (mounted && account && chain) {
                                if (!whitelist || whitelist.addr !== account.address) {
                                    return <BtnPray search={search} address={account.address}></BtnPray>
                                }

                                if (whitelist && whitelist.wl === true) {
                                    return onValid
                                } else {
                                    return onInvalid
                                }
                            }

                            if (!mounted || !account || !chain) {
                                return <ImgButton w="400px" variant="connectWallet" onClick={openConnectModal} />
                            }

                            if (chain.unsupported) {
                                return <></>
                            }

                            return <></>
                        })()}
                    </div>
                )
            }}
        </ConnectButton.Custom>
    )
}

function BtnConnect({ variant = 'connectWallet', w = '400px' }, props) {
    return (
        <ConnectButton.Custom>
            {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
                return (
                    <div
                        id="btn-connect"
                        {...(!mounted && {
                            'aria-hidden': true,
                            style: {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',
                            },
                        })}
                    >
                        {(() => {
                            if (!mounted || !account || !chain) {
                                return <ImgButton w={w} variant={variant} onClick={openConnectModal} {...props} />
                            }

                            if (chain.unsupported) {
                                return <></>
                            }

                            return <></>
                        })()}
                    </div>
                )
            }}
        </ConnectButton.Custom>
    )
}

function InvDetail({ account, ...props }) {
    const { isProd, network, contracts, collections } = useEnv()

    function lessThanOneMinute(time) {
        return (Date.now() - Date.parse(time)) / 1000 / 60 <= 1
    }

    const [tokens, setTokens] = useState(undefined)

    function fetchNftInventory(address) {
        const alchemy = new Alchemy({
            apiKey: !isProd
                ? process.env.REACT_APP_PROVIDER_ALCHEMY_ID_RIN
                : process.env.REACT_APP_PROVIDER_ALCHEMY_ID_PRD,
            network: !isProd ? Network.ETH_GOERLI : Network.ETH_MAINNET,
        })
        alchemy.nft
            .getNftsForOwner(address, {
                contractAddresses: [contracts.dodo, contracts.mountain, contracts.sword],
            })
            .then((msg) => {
                console.log('>>> Got NFTs in Wallet:', msg)
                const nfts = msg.ownedNfts.map((nft) => {
                    return {
                        id: parseInt(nft.tokenId),
                        qty: nft.balance,
                        updated: nft.timeLastUpdated,
                        contract: ethers.utils.getAddress(nft.contract.address),
                        name: nft.title ? nft.title : 'UnknownNFT #' + nft.tokenId,
                        src: `https://gateway.dodofrens.com/ipfs/${nft.rawMetadata?.image?.substring(7)}`,
                        nftType: nft.tokenType,
                        attributes: nft.rawMetadata?.attributes,
                    }
                })
                return nfts
            })
            .then((tokens) => {
                console.log('Got NFTs in Wallet:', tokens)
                setTokens(tokens.sort((a, b) => (a.updated > b.updated ? -1 : 0)))
            })
    }

    useEffect(() => {
        if (account) {
            fetchNftInventory(account.address)
        }
    }, [account])

    return (
        <Box>
            <StatGroup>
                <Stat>
                    <StatLabel>MTs</StatLabel>
                    <StatNumber>
                        {tokens !== undefined ? tokens.filter((t) => contracts.mountain === t.contract).length : '...'}
                    </StatNumber>
                    <StatHelpText fontSize="0.8rem">
                        <Link title="View on OpenSea" href={collections.mountain} isExternal={true}>
                            Mountain NFT
                        </Link>
                    </StatHelpText>
                </Stat>

                <Stat>
                    <StatLabel>DODOs</StatLabel>
                    <StatNumber>
                        {tokens !== undefined ? tokens.filter((t) => contracts.dodo === t.contract).length : '...'}
                    </StatNumber>
                    <StatHelpText fontSize="0.8rem">
                        <Link title="View on OpenSea" href={collections.dodo} isExternal={true}>
                            DoDoFrens NFT
                        </Link>
                    </StatHelpText>
                </Stat>

                <Stat>
                    <StatLabel>Swords</StatLabel>
                    <StatNumber>
                        {tokens !== undefined
                            ? tokens.filter((t) => contracts.sword === t.contract).reduce((a, b) => a + b?.qty, 0)
                            : '...'}
                    </StatNumber>
                    <StatHelpText fontSize="0.8rem">
                        <Link title="View on OpenSea" href={collections.sword} isExternal={true}>
                            Sword of Bravery
                        </Link>
                    </StatHelpText>
                </Stat>
            </StatGroup>

            <Divider />

            <Box p="1rem" borderTop="1px solid">
                <OrderedList>
                    {tokens !== undefined ? (
                        tokens.length <= 0 ? (
                            <>No items found ... </>
                        ) : (
                            tokens.map((token, idx) => (
                                <ListItem key={idx}>
                                    <Link
                                        title="View on OpenSea"
                                        href={`https://${!isProd ? 'testnets.' : ''}opensea.io/assets/${network.name}/${
                                            token.contract
                                        }/${token.id}`}
                                        isExternal={true}
                                    >
                                        <Box as="span">
                                            <ExternalLinkIcon />
                                            {token.name}{' '}
                                            {token.nftType === 'ERC1155' && <Box as="span">(x{token.qty})</Box>}
                                        </Box>
                                    </Link>

                                    {lessThanOneMinute(token.updated) && (
                                        <Badge ml="1" colorScheme="green">
                                            New
                                        </Badge>
                                    )}
                                </ListItem>
                            ))
                        )
                    ) : (
                        <Center>
                            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
                        </Center>
                    )}
                </OrderedList>
            </Box>
        </Box>
    )
}

function Inventory({ account, displayName, ...props }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <Button onClick={onOpen} {...props}>
                <Icon as={FaBoxOpen} w="1.2em" h="1.2em" mr="4px" /> Inventory
            </Button>

            <Modal onClose={onClose} isOpen={isOpen} size="lg" isCentered scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>My NFT Inventory ({displayName})</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <InvDetail account={account}></InvDetail>
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    )
}

function BtnWallet() {
    return (
        <ConnectButton.Custom>
            {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
                return (
                    <div
                        id="btn-wallet"
                        {...(!mounted && {
                            'aria-hidden': true,
                            style: {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',
                            },
                        })}
                    >
                        {(() => {
                            if (!mounted || !account || !chain) {
                                return <></>
                            }

                            if (chain.unsupported) {
                                return (
                                    <Button onClick={openChainModal} color="red">
                                        Switch Network
                                    </Button>
                                )
                            }

                            return (
                                <div style={{ display: 'flex', gap: 12 }}>
                                    <Button
                                        size={['xs', 'md']}
                                        onClick={openChainModal}
                                        style={{ display: 'flex', alignItems: 'center' }}
                                        type="button"
                                    >
                                        {chain.hasIcon && (
                                            <Box
                                                style={{
                                                    background: chain.iconBackground,
                                                    borderRadius: 999,
                                                    overflow: 'hidden',
                                                    marginRight: 4,
                                                }}
                                            >
                                                {chain.iconUrl && (
                                                    <Img
                                                        alt={chain.name ?? 'Chain icon'}
                                                        src={chain.iconUrl}
                                                        w="1.2em"
                                                        h="1.2em"
                                                    />
                                                )}
                                            </Box>
                                        )}
                                        {chain.name}
                                    </Button>

                                    <Button onClick={openAccountModal} size={['xs', 'md']}>
                                        {account.displayName}
                                        {account.displayBalance ? ` (${account.displayBalance})` : ''}
                                    </Button>

                                    <Inventory
                                        account={account}
                                        size={['xs', 'md']}
                                        displayName={account.displayName}
                                    />
                                </div>
                            )
                        })()}
                    </div>
                )
            }}
        </ConnectButton.Custom>
    )
}

export { BtnConnect, BtnWallet, BtnWLChecker }
