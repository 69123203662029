import React from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Flex,
    Text,
} from '@chakra-ui/react'

export default function DrawerPanel({
    placement = 'right',
    width,
    isOpen,
    children,
    onClose,
    btnRef,
    title = 'DoDoFrens',
    footer,
}) {
    const p = 15
    return (
        <Flex w={width}>
            <Drawer isOpen={isOpen} placement={placement} onClose={onClose} finalFocusRef={btnRef}>
                <DrawerOverlay />
                <DrawerContent alignItems="center">
                    <DrawerCloseButton alignSelf="end" mx={p} my={p} />
                    <DrawerHeader my={p}>
                        <Text as="p" color="black">
                            {title}
                        </Text>
                    </DrawerHeader>
                    <DrawerBody color="black">{children}</DrawerBody>
                    <DrawerFooter>{footer}</DrawerFooter>
                </DrawerContent>
            </Drawer>
        </Flex>
    )
}
