import React from 'react'
import { Text, chakra, Tooltip } from '@chakra-ui/react'
import { dateToStr } from '../utils/index'

function TimeTipText({ prefix, dateStr, suffix }) {
    const op = Intl.DateTimeFormat().resolvedOptions()
    return (
        <Text>
            <chakra.span>{prefix}</chakra.span>{' '}
            <Tooltip closeOnClick={false} label={dateToStr(dateStr, op.locale, op.tz)} aria-label="Local Time">
                <span>
                    <chakra.span textDecor="underline" cursor="pointer">
                        {dateToStr(dateStr, 'en-US', 'America/New_York')}
                    </chakra.span>
                    <chakra.span>{suffix}</chakra.span>
                </span>
            </Tooltip>
        </Text>
    )
}

export default TimeTipText
