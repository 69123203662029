import React from 'react'
import swordGray from './swordGray.png'
import swordgif from '../../images/gifs/sword.gif'
import bg from './bg.png'
import { Center, Image, Box, useToast, Text, Flex, useMediaQuery } from '@chakra-ui/react'

const swordStyle = {
    transform: 'scale(1.1)',
    transition: 'transform 1s ease-in-out',
}

const ClaimSwordModal = ({ eligible = true, info, hint, action }) => {
    return (
        <Center
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            zIndex={1}
            pointerEvents="none"
            background="transparent"
        >
            <Center
                flexDirection="column"
                alignItems="center"
                padding="10%"
                w={['90%', '80%', '70%', '70%', '70%']}
                h={['85%']}
                backgroundImage={bg}
                backgroundSize="100% 100%"
                backgroundAttachment="contain"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                border="none"
                position="absolute"
                top={['15%']}
                zIndex={2}
                pointerEvents="auto"
            >
                <Center h={['25%', '22%', '20%']}>
                    <Image w="100%" h="100%" objectFit="contain" src={info} />
                </Center>
                <Center h={['45%', '50%', '60%']} pading={'10%'}>
                    <Image
                        w="100%"
                        h="100%"
                        objectFit="contain"
                        alt="Sword of Bravery"
                        src={eligible ? swordgif : swordGray}
                        _hover={swordStyle}
                    />
                </Center>
                <Center flexDirection="column" h={['25%', '22%', '20%']}>
                    {hint}
                    {action}
                </Center>
            </Center>
        </Center>
    )
}

export default ClaimSwordModal
