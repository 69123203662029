import {
    Box,
    Center,
    Flex,
    Heading,
    Img,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Spacer,
    Toast,
    useDisclosure,
    useMediaQuery,
    useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useAccount, useContractWrite, useWaitForTransaction } from 'wagmi'
import { MeditationMap } from '../../components/Backgrounds'
import { BtnConnect } from '../../components/BtnConnect'
import MeditationModal from './MeditationModal'
import StakingContract from '../../contracts/StakingContract'
import ImgButton from '../../components/ImgButton'
import TimeTipText from '../../components/TimeTipText'
import faqs from '../../components/data/faqs.json'
import MeditationImg from '../../images/buttons/staking/meditation.gif'
import { useNavigate } from 'react-router-dom'
import useSiteStage from '../hooks/SiteStage'
import CenterLayout from '../layouts/CenterLayout'
import { handleContractError } from '../../utils/ContractUtils'

const stakingContractConfig = {
    addressOrName: process.env.REACT_APP_STAKING_CONTRACT_ADDR,
    contractInterface: StakingContract.abi,
}

const Meditation = () => {
    const [isOnStakedTab, setOnStakedTab] = useState(false)

    async function setStaked(tab) {
        setOnStakedTab(tab)
        setRefresh(refresh + 1)
    }

    // Accont Connections
    const { address, isConnected } = useAccount({
        onConnect() {
            console.log('Connected:', address)
        },
        onDisconnect() {
            console.log('Disconnected:', address)
        },
    })

    // site stage
    const [stage] = useSiteStage({
        address: address,
        isConnected: isConnected,
    })

    // Write Contracts
    // Step 1. claim airdrop (wallet)
    const toast = useToast()
    const {
        data: stakeData,
        isError: isStakeError,
        error: stakeError,
        isLoading: isStakeLoading,
        isSuccess: isStakeStarted,
        status: stakeStatus,
        write: stakeDoDos,
    } = useContractWrite({
        ...stakingContractConfig,
        mode: 'recklesslyUnprepared',
        functionName: 'stakeDoDos',
        onError(error) {
            handleContractError('stake Write', error, toast)

            // if failed, refresh again
            setRefresh(refresh + 1)
        },
    })

    const {
        data: unstakeData,
        isError: isUnstakeError,
        error: unstakeError,
        isLoading: isUnstakeLoading,
        isSuccess: isUnstakeStarted,
        status: unstakeStatus,
        write: unstakeDoDos,
    } = useContractWrite({
        ...stakingContractConfig,
        mode: 'recklesslyUnprepared',
        functionName: 'unstakeDoDos',
        onError(error) {
            handleContractError('unstake Write', error, toast)

            // if failed, refresh again
            setRefresh(refresh + 1)
        },
    })

    const {
        data: claimFaithData,
        isError: isClaimFaithError,
        error: claimFaithError,
        isLoading: isClaimFaithLoading,
        isSuccess: isClaimFaithStarted,
        status: claimFaithStatus,
        write: claimFaith,
    } = useContractWrite({
        ...stakingContractConfig,
        mode: 'recklesslyUnprepared',
        functionName: 'claimFaith',
        onError(error) {
            handleContractError('claimFaith Write', error, toast)

            // if failed, refresh again
            setRefresh(refresh + 1)
        },
    })

    // Step 2. staking
    const {
        isLoading: isTxLoading,
        isSuccess: isTxSuccess,
        isError: isTxError,
    } = useWaitForTransaction({
        confirmations: 1,
        hash: stakeData?.hash || unstakeData?.hash,
        wait: stakeData?.wait || unstakeData?.wait,
        onSuccess(data, error) {
            console.log('TxSuccess:', data, error)
            toast({
                title: `[Success] Transaction Confirmed!`,
                status: 'success',
                isClosable: true,
            })
            if (toastIdRef.current) {
                toast.close(toastIdRef.current)
            }
        },
        onError(error) {
            console.log('TxError:', error)
            const msg = error.internal?.message || error.reason
            const [type, reason] = msg.split(': ')
            toast({
                title: type
                    ? `Error: ${type}${reason ? '(' + reason.trim() + ')' : ''}`
                    : `Rejected, please try again later.`,
                status: 'error',
                isClosable: true,
            })
        },
        onSettled(data, error) {
            console.log('TxSettle:', data, error)
            // refresh when complete
            setRefresh(refresh + 1)
        },
    })

    // Step 3. claiming
    const {
        isLoading: isFaithTxLoading,
        isSuccess: isFaithTxSuccess,
        isError: isFaithTxError,
    } = useWaitForTransaction({
        confirmations: 1,
        hash: claimFaithData?.hash,
        wait: claimFaithData?.wait,
        onSuccess(data, error) {
            console.log('FaithTxSuccess:', data, error)
            toast({
                title: `[Success] Transaction Confirmed!`,
                status: 'success',
                isClosable: true,
            })
            if (toastIdRef.current) {
                toast.close(toastIdRef.current)
            }
        },
        onError(error) {
            console.log('FaithTxError:', error)
            const msg = error.internal?.message || error.reason
            const [type, reason] = msg.split(': ')
            toast({
                title: type
                    ? `Error: ${type}${reason ? '(' + reason.trim() + ')' : ''}`
                    : `Rejected, please try again later.`,
                status: 'error',
                isClosable: true,
            })
        },
        onSettled(data, error) {
            console.log('FaithTxSettle:', data, error)
            // refresh when complete
            setRefresh(refresh + 1)
        },
    })

    const toastIdRef = React.useRef()

    useEffect(() => {
        if (isTxLoading || isFaithTxLoading) {
            toastIdRef.current = toast({
                title: `Processing transaction, please wait for a while...`,
                status: 'success',
                isClosable: true,
                duration: 15000,
            })
        }
    }, [isTxLoading, isFaithTxLoading])

    const [refresh, setRefresh] = useState(0)

    // 1. stake
    // 2. unstake
    // 3. claim faith
    function stakeNow(selected) {
        console.log('!!! Stake Now!', selected)
        if (selected && selected.length > 0) {
            stakeDoDos({
                recklesslySetUnpreparedArgs: [selected],
            })
        } else {
            toast({
                title: `Please select at least one DoDo`,
                status: 'warning',
                isClosable: true,
            })
        }
    }

    function unstakeNow(selected) {
        console.log('!!! Unstake Now!', selected)
        if (selected && selected.length > 0) {
            unstakeDoDos({
                recklesslySetUnpreparedArgs: [selected],
            })
        } else {
            toast({
                title: `Please select at least one DoDo`,
                status: 'warning',
                isClosable: true,
            })
        }
    }

    function claimFaithNow(allStakedIds, totalFaith) {
        console.log('!!! Claim Faith Now!', totalFaith, allStakedIds)
        if (allStakedIds && allStakedIds.length > 0 && totalFaith > 0) {
            claimFaith({
                recklesslySetUnpreparedArgs: [allStakedIds],
            })
        } else {
            toast({
                title: `No Faith to claim yet.`,
                status: 'warning',
                isClosable: true,
            })
        }
    }

    const navigate = useNavigate()
    function useFaithNow() {
        console.log('!!! Use Faith Now!')
        stage > 9 ? navigate('/forge') : navigate('/faqs')
    }

    const [isMobile] = useMediaQuery('(max-width: 900px)')

    if (stage < 9) {
        return (
            <>
                <CenterLayout>
                    <Popover placement="top">
                        <PopoverTrigger>
                            <Img w={['270px', '400px']} src={MeditationImg} alt="meditation" cursor="pointer" />
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader />
                            <PopoverBody>
                                <>
                                    <TimeTipText
                                        prefix="Meditation event of Chapter One will start at"
                                        dateStr={faqs.meditationDate}
                                        suffix=""
                                    ></TimeTipText>
                                </>
                            </PopoverBody>
                            <PopoverFooter />
                        </PopoverContent>
                    </Popover>
                </CenterLayout>
                <MeditationMap id="background" />
            </>
        )
    }

    if (!isConnected) {
        return (
            <>
                <CenterLayout>
                    <BtnConnect variant="meditation" />
                </CenterLayout>
                <MeditationMap id="background" />
            </>
        )
    }

    return (
        <>
            <MeditationModal
                address={address}
                isOnStakedTab={isOnStakedTab}
                setStaked={setStaked}
                stakeNow={stakeNow}
                unstakeNow={unstakeNow}
                claimFaithNow={claimFaithNow}
                useFaithNow={useFaithNow}
                isMobile={isMobile}
                refresh={refresh}
                stage={stage}
            ></MeditationModal>
            <MeditationMap id="backgrounds" />
        </>
    )
}

export default Meditation
