import React from 'react'
import { Center, useToast, Image, Text } from '@chakra-ui/react'
import ImgButton from '../../../components/ImgButton'
import { FadeInOut, GrowShrink } from '../../../components/Animations'
import { WakumbaBlessMap } from '../../../components/Backgrounds'
import { BtnConnect } from '../../../components/BtnConnect'
import { useAccount, useContractRead, useContractWrite, useWaitForTransaction, useContractEvent } from 'wagmi'
import ClaimSwordModal from '../../../components/ClaimSwordModal/ClaimSwordModal'
import AirdropNFT from '../../../contracts/AirDropNFT'
import congrats from './congratulations.png'
import sorry from './claimSwordIneligible.png'
import processing from './claimSwordProcessing.png'
import success from './claimSuccessful.png'
import { handleContractError } from '../../../utils/ContractUtils'

const debug = process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production'

const contractConfig = {
    addressOrName: process.env.REACT_APP_AIRDROP_CONTRACT_ADDR,
    contractInterface: AirdropNFT.abi,
}

const StageClaimSword = () => {
    const [claimStatus, setClaimStatus] = React.useState('CLAIM_SWORD_START')

    // Accont Connections
    const { address, isConnected } = useAccount({
        onConnect() {
            console.log('Connected:', address)
            setClaimStatus('CLAIM_SWORD_START')
        },
        onDisconnect() {
            console.log('Disconnected:', address)
            setClaimStatus('CLAIM_SWORD_START')
        },
    })
    React.useEffect(() => {
        address && setClaimStatus('CLAIM_SWORD_START')
    }, [address])

    // Get QTY already claimed (from chain)
    const { data: claimedQty } = useContractRead({
        functionName: 'claimed',
        watch: true,
        ...contractConfig,
        args: [address],
        onSuccess(qty) {
            console.log('Get claimedQty:', address, qty)
        },
    })

    // Get Claim Limit (from API)
    const toast = useToast()
    const [claimAmountLimit, setClaimAmountLimit] = React.useState(-1)
    const checkEligibility = (_claimedQty) => {
        function validateLimit(maxLimit, __claimedQty) {
            if (maxLimit <= 0) {
                setClaimStatus('CLAIM_SWORD_INELIGIBLE')
            } else {
                if (maxLimit <= __claimedQty) {
                    setClaimStatus('CLAIM_SWORD_SUCCESSFUL')
                } else {
                    setClaimStatus('CLAIM_SWORD_CONFIRM')
                }
            }
            setClaimAmountLimit(maxLimit)
        }

        address &&
            fetch(`/api/airdrop/${address}`, {
                method: 'POST',
                accepts: 'application/json',
            })
                .then((_msg) => _msg.json())
                .then((_data) => {
                    if (debug) {
                        console.log('Get /api/airdrop/' + address, _data)
                    }
                    if (_data && _data.addr && _data.addr.toLowerCase() === address.toLowerCase()) {
                        validateLimit(_data.max, _claimedQty)
                    } else {
                        toast({
                            title: `Incorrect response data, please wait for a while and try again.`,
                            status: 'error',
                            isClosable: true,
                        })
                    }
                })
                .catch((err) => {
                    console.log('[Server Error]', err)
                    toast({
                        title: `Incorrect response data, please wait for a while and try again.`,
                        status: 'error',
                        isClosable: true,
                    })
                })
    }

    // Update QTY to claim (calculation)
    const [claimAmount, setClaimAmount] = React.useState(-1)
    React.useEffect(() => {
        setClaimAmount(claimAmountLimit - claimedQty)
    }, [claimedQty, claimAmountLimit])

    // Step 1. claim airdrop (wallet)
    const {
        data,
        isIdle,
        isError: isMintError,
        error: mintError,
        isLoading: isMintLoading,
        isSuccess: isMintStarted,
        status: mintStatus,
        write,
    } = useContractWrite({
        ...contractConfig,
        mode: 'recklesslyUnprepared',
        functionName: 'airdrop',
        onError(error) {
            handleContractError('airdrop Write', error, toast)
            // if failed, check eligibility again
            checkEligibility(claimedQty)
        },
    })

    // Step 2. minting
    const {
        isLoading: isTxLoading,
        isSuccess: isTxSuccess,
        isError: isTxError,
    } = useWaitForTransaction({
        confirmations: 1,
        hash: data?.hash,
        wait: data?.wait,
        onSuccess(data, error) {
            console.log('TxSuccess:', data, error)
        },
        onError(data, error) {
            console.log('TxError:', data, error)
            // if failed, check eligibility again
            checkEligibility(claimedQty)
        },
        onSettled(data, error) {
            console.log('TxSettle:', data, error)
        },
    })

    // Step 3. after mint (Transfer complete)
    useContractEvent({
        ...contractConfig,
        eventName: 'TransferSingle',
        listener: (event) => {
            if (debug) {
                console.log('Received: TransferSingle Event', event)
                console.log(JSON.stringify(event), event)
                console.log('addr match?', event[0] === address)
            }
            if (event[0] === address) {
                const qty = event[4].toNumber()
                console.log('Transaction Completed, NFT Count: ', qty)
                toast({
                    title: `Successfully claimed ${qty} Sword${qty > 1 ? 's' : ''}!`,
                    status: 'success',
                    isClosable: true,
                })
            }
        },
    })

    // Step 4. contract processing effects
    React.useEffect(() => {
        if (isTxSuccess) {
            setClaimStatus('CLAIM_SWORD_SUCCESSFUL')
        } else if (isMintLoading || isTxLoading) {
            setClaimStatus('CLAIM_SWORD_PROCESSING')
            if (isTxLoading) {
                toast({
                    title: `Processing transaction, please wait for a while...`,
                    status: 'success',
                    isClosable: true,
                })
            }
        }
    }, [isTxLoading, isMintLoading, isTxSuccess, toast])

    const mintSword = () => {
        if (claimAmount <= 0) {
            toast({
                title: `Incorrect Claim Amount, please try again later.`,
                status: 'error',
                isClosable: true,
            })
        } else {
            address &&
                claimAmountLimit > 0 &&
                fetch(`/api/airdrop/proofs/${address}/${claimAmount}`, { method: 'POST' })
                    .then((_msg) => _msg.json())
                    .then((_data) => {
                        if (_data && _data.addr && _data.addr === address) {
                            const args = [address, _data.amt, _data.max, _data.proof]
                            if (debug) {
                                console.log('Claim Req Args: ', args)
                            }
                            write({
                                recklesslySetUnpreparedArgs: args,
                            })
                        } else {
                            toast({
                                title: `Incorrect response data, please wait for a while and try again.`,
                                status: 'error',
                                isClosable: true,
                            })
                        }
                    })
                    .catch((err) => {
                        console.log('[Server Error]', err)
                        toast({
                            title: `Incorrect response data, please wait for a while and try again.`,
                            status: 'error',
                            isClosable: true,
                        })
                    })
        }
    }

    const viewOnOpensea = () => {
        const baseUrl = !debug ? 'https://opensea.io/assets/ethereum' : 'https://testnets.opensea.io/assets/goerli'
        window.open(`${baseUrl}/${process.env.REACT_APP_AIRDROP_CONTRACT_ADDR}/0`, '_blank')
    }

    const HintText = ({ children }) => (
        <Text
            fontSize={['0.6rem', '1rem', '1.25rem']}
            lineHeight={['0.6rem', '1rem', '1.25rem']}
            textAlign="center"
            margin="0"
            width="auto"
        >
            {children}
        </Text>
    )

    const ViewBtn = ({ variant, onClick }) => {
        return (
            <ImgButton
                h={['2rem', '3rem', '3rem']}
                variant={variant}
                onClick={onClick}
                _hover={{ animation: GrowShrink }}
            />
        )
    }

    const btnViewOnOpensea = <ViewBtn variant="viewOnOpensea" onClick={viewOnOpensea} />

    const statusDisplayMap = {
        CLAIM_SWORD_START: (
            <Center>
                <ImgButton
                    w="md"
                    variant="claimSwordStart"
                    onClick={() => checkEligibility(claimedQty)}
                    _hover={{ animation: GrowShrink }}
                />
                <WakumbaBlessMap zIndex="-1" animation={FadeInOut} />
            </Center>
        ),

        CLAIM_SWORD_INELIGIBLE: <ClaimSwordModal eligible={false} info={sorry} action={btnViewOnOpensea} />,
        CLAIM_SWORD_CONFIRM: (
            <ClaimSwordModal
                info={congrats}
                hint={<HintText> Total Claimable: {claimAmountLimit - claimedQty}</HintText>}
                action={<ViewBtn variant="claimSwordConfirm" onClick={mintSword} />}
            />
        ),
        CLAIM_SWORD_PROCESSING: (
            <ClaimSwordModal info={processing} hint={<HintText> Total Amount: {claimAmount}</HintText>} />
        ),
        CLAIM_SWORD_SUCCESSFUL: (
            <ClaimSwordModal
                info={success}
                hint={<HintText> Claimed Amount: {claimedQty || claimAmount}</HintText>}
                action={btnViewOnOpensea}
            />
        ),
    }

    return (
        <>
            {isConnected ? (
                statusDisplayMap[claimStatus]
            ) : (
                <Center>
                    <BtnConnect />
                </Center>
            )}
        </>
    )
}

export default StageClaimSword
