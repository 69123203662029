import { Box, Flex, Spacer, useToast, VisuallyHidden } from '@chakra-ui/react'
import { React, useEffect, useState } from 'react'
import { WakumbaMap } from '../components/Backgrounds'
import StageClaimSword from './stages/StageClaimSword/StageClaimSword'
import StageWakumbaPray from './stages/StageWakumbaPray'
import StageWakumbaNext from './stages/StageWakumbaNext'
import StagePreChapterOne from './stages/StagePreChapterOne'
import CenterLayout from './layouts/CenterLayout'
import Share from './Share/Share'
import { CURRENT_STAGE_DFAULT } from './hooks/SiteStage'

function Wakumba() {
    const toast = useToast()

    // site stage
    const [stage, setStage] = useState(0)
    useEffect(() => {
        fetch('/api/stages/current')
            .then((_msg) => _msg.json())
            .then((_data) => setStage(_data.stage))
            .catch((err) => {
                console.log('Fetch Stage Failed: ', err)
                setStage(CURRENT_STAGE_DFAULT)
                toast({
                    title: `Unstable Network, new requests may fail`,
                    status: 'error',
                    isClosable: true,
                })
            })
    }, [])

    if (stage === 11 || stage === 12) {
        return <Share />
    }

    return (
        <>
            <CenterLayout w={['90%', '80%', '70%', '60%']}>
                {stage === 0 && <></>}

                {stage > 0 && stage <= 5 && <StageWakumbaPray stage={stage} />}

                {(stage === 6 || stage === 13) && <StageWakumbaNext />}

                {stage === 7 && <StagePreChapterOne />}

                {(stage === 8 || stage === 9 || stage === 10) && <StageClaimSword />}
            </CenterLayout>

            <VisuallyHidden id="stage">{stage}</VisuallyHidden>

            <WakumbaMap />
        </>
    )
}

export default Wakumba
