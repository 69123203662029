import { Box, chakra, Image, Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { BeatLoader } from 'react-spinners'

function ImgButton({
    noMargin = false,
    disabled: forceDisabled = false,
    isLoading = false,
    isActive = false,
    onClick = () => {},
    clickTimeout = 0,
    variant,
    reverse = false,
    ...props
}) {
    const { btnRef, onMouseOver, onMouseOut, ...rest } = props
    const [hovered, setHovered] = useState(false)
    const [loading, setLoading] = useState(isLoading)

    const variantMap = {
        na: { img: 'na.png', imgHover: 'na.png', alt: 'N/A' },
        home: { img: 'home.png', imgHover: 'home1.png', alt: 'Home' },
        story: { img: 'story.png', imgHover: 'story1.png', alt: 'Story' },
        wakumba: { img: 'wakumba.png', imgHover: 'wakumba1.png', alt: 'Wakumba (WL)' },
        freeMint: { img: 'freeMint.png', imgHover: 'freeMint1.png', alt: 'Free Mint' },
        faq: { img: 'faq.png', imgHover: 'faq1.png', alt: 'FAQ' },
        pray: { img: 'pray.png', imgHover: 'pray1.png', alt: 'Pray' },
        gallery: { img: 'gallery.png', imgHover: 'gallery1.png', alt: 'Gallery' },
        backtop: { img: 'backtop.png', imgHover: 'backtop1.png', alt: 'Back To Top' },
        connectWallet: { img: 'connectWallet.png', imgHover: 'connectWallet1.png', alt: 'Connect Wallet' },
        dodoGo: { img: 'dodoGo.png', imgHover: 'dodoGo1.png', alt: 'DoDo Go!' },
        twitter: { img: 'twitter.png', imgHover: 'twitter1.png', alt: 'Twitter' },
        share: { img: 'share.png', imgHover: 'share1.png', alt: 'Share On Twitter' },
        blessFail: { img: 'bless-fail.png', imgHover: 'bless-fail.png', alt: 'Bless Failed' },
        blessOk1: { img: 'bless-ok1.png', imgHover: 'bless-ok1.png', alt: 'Bless OK' },
        blessOk2: { img: 'bless-ok2.png', imgHover: 'bless-ok2.png', alt: 'Bless OK' },
        wakumbaAirdrop: { img: 'WakumbaAirdrop.png', imgHover: 'WakumbaAirdrop1.png', alt: 'Wakumba Airdrop' },
        airdrop: { img: 'airdrop.png', imgHover: 'airdrop1.png', alt: 'Airdrop' },
        claimSword: { img: 'claimSword.png', imgHover: 'claimSword1.png', alt: 'Claim Sword' },
        claimSwordStart: { img: 'claimSwordStart.png', imgHover: 'claimSwordStartHover.png' },
        claimSwordIneligible: { img: 'claimSwordIneligible.png', imgHover: 'claimSwordIneligible.png' },
        claimSwordConfirm: { img: 'claimSwordConfirmHover.png', imgHover: 'claimSwordConfirmBlue.png' },
        claimSwordProcessing: { img: 'claimSwordProcessing.png', imgHover: 'claimSwordProcessing.png' },
        claimSwordSuccessful: { img: 'claimSwordSuccessful.png', imgHover: 'claimSwordSuccessful.png' },
        viewOnOpensea: { img: 'viewOnOpensea1.png', imgHover: 'viewOnOpensea.png' },
        add: { img: '+2.png', imgHover: '+1.png' },
        addGray: { img: '+.png', imgHover: '+.png' },
        minus: { img: '-2.png', imgHover: '-1.png' },
        minusGray: { img: '-.png', imgHover: '-.png' },
        //mediation page
        useTop: { img: 'top-use1.png', imgHover: 'top-use.png' },
        stakeTop: { img: 'top-stake1.png', imgHover: 'top-stake.png' },
        claimTop: { img: 'top-claim1.png', imgHover: 'top-claim.png' },
        unstaked: { img: 'staking/tab-unstaked.png', imgHover: 'staking/tab-unstaked1.png' },
        staked: { img: 'staking/tab-staked.png', imgHover: 'staking/tab-staked1.png' },
        navPrev: { img: 'staking/navi-prev.png', imgHover: 'staking/navi-prev.png' },
        navNext: { img: 'staking/navi-next.png', imgHover: 'staking/navi-next.png' },
        unstake: { img: 'staking/btn-unstake.png', imgHover: 'staking/btn-unstake1.png' },
        stake: { img: 'staking/btn-stake.png', imgHover: 'staking/btn-stake1.png' },
        // meditation icons
        meditation: { img: 'staking/meditation.gif', imgHover: 'staking/meditation.gif' },
        // enchance icons
        enhance: { img: 'forge/enhance.png', imgHover: 'forge/enhance1.png' },
        enhance_ani: { img: 'forge/enhance.gif', imgHover: 'forge/enhance.gif' },
        apply: {
            img: 'forge/popup-applystar/btn-apply.png',
            imgHover: 'forge/popup-applystar/btn-apply1.png',
            alt: 'Apply',
        },
        apply_star: {
            img: 'forge/popup-base/btn-applystar.png',
            imgHover: 'forge/popup-base/btn-applystar1.png',
            alt: 'Apply Star',
        },
        star: { img: 'forge/popup-buynow/star.png', imgHover: 'forge/popup-buynow/star.png' },
        faith: { img: 'forge/popup-buynow/faith.png', imgHover: 'forge/popup-buynow/faith.png' },
        faithFull: { img: 'forge/popup-buynow/faith-fullname.png' },
        sword_white: { img: 'forge/swords/white.png', imgHover: 'forge/swords/white1.png' },
        sword_green: { img: 'forge/swords/green.png', imgHover: 'forge/swords/green1.png' },
        sword_blue: { img: 'forge/swords/blue.png', imgHover: 'forge/swords/blue1.png' },
        sword_purple: { img: 'forge/swords/purple.png', imgHover: 'forge/swords/purple1.png' },
        sword_orange: { img: 'forge/swords/orange.png', imgHover: 'forge/swords/orange1.gif' },
        buyStar: { img: 'forge/popup-base/btn-buynow.png', imgHover: 'forge/popup-base/btn-buynow1.png' },
        ok: { img: 'forge/popup-buynow/btn-ok.png', imgHover: 'forge/popup-buynow/btn-ok1.png' },
        exp_white: { img: 'forge/popup-base/explains/white.png', imgHover: 'forge/popup-base/explains/white1.png' },
        exp_green: { img: 'forge/popup-base/explains/green.png', imgHover: 'forge/popup-base/explains/green1.png' },
        exp_blue: { img: 'forge/popup-base/explains/blue.png', imgHover: 'forge/popup-base/explains/blue1.png' },
        exp_purple: { img: 'forge/popup-base/explains/purple.png', imgHover: 'forge/popup-base/explains/purple1.png' },
        exp_orange: {
            img: 'forge/popup-base/explains/orange.png',
            imgHover: 'forge/popup-base/explains/orange1.png',
        },
        //mountain
        mountain: {
            img: 'mountain/mountain.png',
            imgHover: 'mountain/mountain1.png',
        },
        mountainNav: {
            img: 'mountain/mountainNav.gif',
            imgHover: 'mountain/mountainNav.gif',
        },
        mountainConnect: {
            img: 'mountain/mountainConnect.png',
            imgHover: 'mountain/mountainConnect1.png',
        },
        summon: {
            img: 'mountain/summon.png',
            imgHover: 'mountain/summon1.png',
        },
        mountain_sword_white: {
            img: 'mountain/whiteVertical.png',
            imgHover: 'mountain/whiteVertical1.png',
        },
        mountain_sword_green: {
            img: 'mountain/greenVertical.png',
            imgHover: 'mountain/greenVertical1.png',
        },
        mountain_sword_blue: {
            img: 'mountain/blueVertical.png',
            imgHover: 'mountain/blueVertical1.png',
        },

        mountain_sword_purple: {
            img: 'mountain/purpleVertical.png',
            imgHover: 'mountain/purpleVertical1.png',
        },

        mountain_sword_orange: {
            img: 'mountain/orangeVertical.png',
            imgHover: 'mountain/orangeVertical1.png',
        },
        mountain_public_mint: {
            img: 'mountain/mint.png',
            imgHover: 'mountain/mint1.png',
        },
        mountain_free_mint_a: {
            img: 'mountain/freemint-a.png',
            imgHover: 'mountain/freemint-a1.png',
        },
        mountain_free_mint: {
            img: 'mountain/freemint.png',
            imgHover: 'mountain/freemint1.png',
        },
        mountain_rewards: {
            img: 'mountain/rewards.png',
            imgHover: 'mountain/rewards1.png',
        },
        amt1btn: {
            img: 'mountain/amt1btn.png',
            imgHover: 'mountain/amt1btn1.png',
        },
        amt2btn: {
            img: 'mountain/amt2btn.png',
            imgHover: 'mountain/amt2btn1.png',
        },
        amt3btn: {
            img: 'mountain/amt3btn.png',
            imgHover: 'mountain/amt3btn1.png',
        },
        //shared
        wrong: { img: 'wrong.png', imgHover: 'wrong.png' },
        right: { img: 'right.png', imgHover: 'right.png' },
        cancel: { img: 'cancel.png', imgHover: 'cancel1.png' },
        btnMint: { img: 'share/btn-mint.png', imgHover: 'share/btn-mint.png', alt: 'MINT' },
        btnMintDisabled: { img: 'share/btn-mint1.png', imgHover: 'share/btn-mint1.png', alt: 'MINT' },
        btnClaim: { img: 'share/btn-claim.png', imgHover: 'share/btn-claim.png', alt: 'CLAIM' },
        btnOK: { img: 'share/btn-ok.png', imgHover: 'share/btn-ok.png', alt: 'OK' },
        btnTwitter: { img: 'share/btn-tw.png', imgHover: 'share/btn-tw1.png', alt: 'Share Twitter' },
        markTick: { img: 'share/mark-tick.png', imgHover: 'share/mark-tick.png', alt: 'Success' },
        markQuestion: { img: 'share/mark-question.png', imgHover: 'share/mark-question.png', alt: 'Q&A' },
        markAwait: { img: 'share/mark-await.png', imgHover: 'share/mark-await.png', alt: 'Waiting' },
    }

    const type = variantMap[variant] ? variantMap[variant] : variantMap.na
    const isSameImg = type.img === type.imgHover
    let img = require(`../images/buttons/${reverse ? type.imgHover : type.img}`)
    let imgHover =
        !isSameImg && type.imgHover ? require(`../images/buttons/${reverse ? type.img : type.imgHover}`) : undefined

    function timeoutClick() {
        if (!clickTimeout || clickTimeout <= 0) {
            onClick()
            return
        }
        setLoading(true)
        setTimeout(() => {
            onClick()
            setTimeout(() => {
                setLoading(false)
            }, clickTimeout / 3)
        }, (clickTimeout / 3) * 2)
    }

    return (
        <Box
            pointerEvents={forceDisabled || loading ? 'none' : 'initial'}
            position="relative"
            // as="button"
            title={type.alt}
            onMouseOver={() => {
                if (forceDisabled || loading) return
                setHovered(true)
                onMouseOver?.()
            }}
            onMouseOut={() => {
                if (forceDisabled || loading) return
                setHovered(false)
                onMouseOut?.()
            }}
            cursor={loading ? 'not-allowed' : 'pointer'}
            ref={btnRef}
            m={noMargin ? '' : '0.5em'}
            _hover={{ filter: 'brightness(110%)', transform: 'scale(1.05)' }}
            {...rest}
        >
            <Image
                onClick={forceDisabled || loading ? function () {} : timeoutClick}
                filter={loading ? 'opacity(0.4)' : forceDisabled ? 'grayscale(100%)' : ''}
                display="inherit"
                draggable={false}
                userSelect="none"
                h="100%"
                w="auto"
                src={imgHover === undefined ? img : isActive || hovered ? imgHover : img}
                alt={type.alt}
                objectFit="contain"
            />
            <Image src={imgHover} className="preload" />
            {loading && (
                <Flex
                    align="center"
                    justify="center"
                    w="100%"
                    h="100%"
                    pos="absolute"
                    top="0"
                    left="0"
                    draggable={false}
                    userSelect="none"
                >
                    <BeatLoader color="white" size={8} />
                </Flex>
            )}
        </Box>
    )
}
export default ImgButton
