import { React } from 'react'
import { Box, Center, Flex, chakra, Heading, Img, Image } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import FrensTogetherImg from '../../images/backgrounds/story-1-3-together.jpg'
import ProtectDoDoImg from '../../images/backgrounds/story-1-3-protect.jpg'
import { StoryMap } from '../../components/Backgrounds'
import StoryMobImg from '../../images/backgrounds/story-mob.jpg'

function Text({ children, ...props }) {
    return (
        <chakra.p marginTop="1em" marginBottom="1em" {...props}>
            {children}
        </chakra.p>
    )
}

function ChapterOneThreeTitle() {
    return (
        <Box id="chapter-one-Three-title" textAlign="center" textShadow="0 0 1px" mb="2em">
            <Heading fontSize={['48px', '51px', '54px', '58px', '65px']}>
                <div>Chapter Ⅰ</div>
                <div>The Courageous Child of the Eternal Land </div>
            </Heading>
        </Box>
    )
}

function ChapterOneThreeArticle({ lang = 'en_US', style }) {
    const navigate = useNavigate()

    return (
        <Box as="article" style={style}>
            <Center>
                <Heading fontSize={['25px', '28px', '32px', '35px', '38px']}>Section III</Heading>
            </Center>

            <Text>“DoDo, wake up. We are safe now. Let's have a rest here.” </Text>
            <Text>
                DoDo opened his eyes and looked around, finding himself in the rainforest. Since it was the first time
                DoDo came to the rainforest, he climbed up and down on Mountain's shoulders, looking around excitedly.{' '}
            </Text>
            <Text>“It’s my first time coming to the rainforest! Everything is green! So green! Haha!” </Text>
            <Text>Mountain put DoDo down on the ground and said, “Let's get something to eat and have a rest.” </Text>
            <Text>Then Mountain and DoDo began to look for food separately. </Text>
            <Text>
                After a while, DoDo and Mountain sat together to eat what they had found. DoDo caught a few insects,
                while Mountain collected some fruits.{' '}
            </Text>

            <Center py="0.5em">
                <Img
                    src={FrensTogetherImg}
                    alt="Frens Together"
                    w={['90%', '80%', '70%', '60%', '50%']}
                    objectFit="contain"
                />
            </Center>

            <Text>
                “Mountain, why were you on the edge of the savannah? Aren't silverback gorillas supposed to live in the
                rainforests? And how did you step into the trap here and be sieged by those spotted hyenas?”{' '}
            </Text>
            <Text>“My chief sent me here, humph.” The gorilla apparently had the answer.</Text>
            <Text>DoDo could tell that Mountain didn’t want to say anymore, so he stopped asking. </Text>
            <Text>
                Mountain felt a bit embarrassed. Though he was untalkative, he started to talk, “DoDo, where are you
                going to take an adventure?”{' '}
            </Text>
            <Text>“I'm going north. The great Ancestor will show me the way!” </Text>
            <Text>“Going north is also my way home. DoDo, how did you destroy the trap just now?” </Text>
            <Text>
                “I broke it with a sword, which I think might come from Wakumba, the blessing of our great Ancestral
                Totem. If it hadn’t shown up at the last minute, we might have become the feces of those spotted hyenas.
                Those villains must have gastrointestinal problems as they eat almost anything.”
            </Text>
            <Text>“...”</Text>
            <Text>“DoDo, you were so brave just now. Just as brave as me!” </Text>
            <Text>“Of course! I’m the bravest meerkat in my tribe!” </Text>
            <Text>
                After finishing eating, DoDo and Mountain had a rest. Then Mountain stood up, lowered his head, and
                said, "DoDo, I should go back to my tribe now. Though I would love to bring you home, it may be
                dangerous for you to go because of my chief... I have to go back and figure it out. Maybe we shall part
                here.”{' '}
            </Text>
            <Text>
                “...” DoDo stared at Mountain. Although he didn't want to part with his new friend, he could tell the
                concern and difficulty from Mountain's eyes. “Alright. Take care. I will pray to Wakumba for you.”{' '}
            </Text>
            <Text>
                After gazing at DoDo for a while, Mountain pointed at him, beat himself on the chest, turned, and walked
                deep into the forest. His footsteps sounded as heavy as his heart.{' '}
            </Text>
            <Text>
                “The big guy seems to be in a bad mood. Maybe I should follow him to check if he is okay as he is my
                first friend.”{' '}
            </Text>
            <Text>DoDo followed Mountain secretly toward where he left. </Text>
            <Text>
                Along the way, the forest became denser. Luckily, Mountain’s footprints were big and deep enough for
                DoDo to follow to prevent being lost.{' '}
            </Text>
            <Text>
                After walking for a long time, DoDo found Mountain surrounded by several silverback gorillas smaller
                than him in the front.{' '}
            </Text>
            <Text>“How... How could you come back alive?” </Text>
            <Text>Mountain didn’t reply a word. </Text>
            <Text>
                “Hurry up. Go back and inform the Chief. Tell him that Mountain... is back.” Then one of the silverback
                gorillas ran backward, and the rest gathered around Mountain in a defense posture.
            </Text>
            <Text>Mountain looked around them, sat down on the ground, and simply closed his eyes. </Text>
            <Text>
                The silverback gorillas surrounded looked at each other in speechless despair, dare not to approach him.{' '}
            </Text>
            <Text>
                After a while, a silverback gorilla of the same height but older age as Mountain came, followed by his
                attendants.{' '}
            </Text>
            <Text>
                The Chief approached Mountain slowly, looked him up and down on the wounds on his hind legs and arms,
                and whispered:
            </Text>
            <Text>“Mountain, I didn't expect that you could come back alive.” </Text>
            <Text>Mountain opened his eyes and stood up, “Chief, why did you do this?” </Text>
            <Text>
                “Why? Well, you are the most powerful warrior in the tribe. You are as strong as the mountains, and you
                are still growing stronger every day while I'm getting old and cannot compete against you, but I want to
                keep my position as the Chief!”{' '}
            </Text>
            <Text>“Chief, I never thought about taking your place.” </Text>
            <Text>
                “But who knows what's on your mind! As the Chief, how could I sit still while your popularity in my
                tribe keeps growing? Those spotted hyenas are so unreliable that even though I told them your schedule
                and offered a big trap, they still let you go alive! Humph, a bunch of rubbish.”{' '}
            </Text>
            <Text>
                Those coming here today are my people. Now that you are back, I don't mind killing you in the
                rainforest.” Then the Chief raised his voice, “Mountain colluded with the spotted hyenas to attack the
                tribe! In the name of the Chief, I command you to put him to death immediately!” Said the chief with
                affectation.{' '}
            </Text>
            <Text>Looking around, Mountain could not help feeling angry. </Text>
            <Text>
                “By confusing truth and falsehood and slaughtering your fellow, don't you fear the wrath of God?”{' '}
            </Text>
            <Text>
                The attendants of the Chief remained unmoved, who could not bear the pride and strength of Mountain. In
                one-to-one battle, those cowards wouldn't dare to fight against him, but this time, facing the injured
                Mountain together, they seemed to have unlimited courage.{' '}
            </Text>
            <Text>“The High Priest will never forgive you. May the God of the Rainforest be with me.” </Text>
            <Text>
                Mountain prayed silently and clenched his fists. Though getting injured, he was still the proudest
                warrior and the highest mountain in the tribe!{' '}
            </Text>
            <Text>
                Mountain wrestled with a group of silverback gorillas, growling furiously. However, as he was injured
                and under siege, he got more and more exhausted and gradually became at a disadvantage with more and
                more wounds on his body.{' '}
            </Text>
            <Text>“Mountain!” </Text>
            <Text>Seeing this scene, DoDo rushed to the dying Mountain without hesitation. </Text>
            <Text>
                DoDo stood in front of Mountain, holding the sword tightly in his hands and raising it across his chest.
                He looked around, making the most fierce look.{' '}
            </Text>
            <Text>“Isn't Mountain your fellow or your bravest warrior? Why are you doing this to him?” </Text>
            <Text>The surrounding silverback gorillas paused for a while when seeing such a little guy. </Text>
            <Text>“Why? Well, it is because he is too strong that he must die!” Said the Chief with a sneer. </Text>
            <Text>“Mountain is not only my friend but my first friend. I won't let you kill him!” </Text>
            <Text>“You? Haha!” </Text>
            <Text>
                One of the gorillas slapped DoDo into the sky while the other Three pinned Mountain on the ground, who
                was struggling. DoDo rushed to him again after falling heavily to the ground.{' '}
            </Text>
            <Text>“Bang!” DoDo was slapped away again. </Text>
            <Text>He kept rushing to Mountain and was slapped away again and again. </Text>
            <Text>
                This time, it took a long time for DoDo to get up. He picked up the sword that had fallen beside him and
                walked with a waddle to Mountain step by step. “Mountain... Mountain is my first friend. I... I will not
                allow you to kill him!”{' '}
            </Text>
            <Text>The attendants laughed aloud. They looked at the little DoDo as if he was a big joke. </Text>
            <Text>
                “Ouch!” DoDo thrust his sword into an attendant's leg in front of him, who jumped up due to the pain,
                covering his feet.{' '}
            </Text>
            <Text>
                He grabbed DoDo, threw him to the ground, stamped on him, and said, “You want to save him, right? Then
                you die with him! I will tread on you to death slowly and show you that meerkats can't meddle with
                gorillas!”{' '}
            </Text>
            <Text as="span">
                Seeing DoDo being trampled by the gorilla, struggling at first and slowly going quiet,{' '}
                <span id="mTag">Mountain's eyes turned red.</span> He burst out of control with a roar, beat away the
                gorilla that stamped on DoDo, scooped up DoDo, put him in the weeds on the edge of the clearing, and
                growled, “DoDo! Run! I will protect you! I will always be your friend!”{' '}
            </Text>

            <Center py="0.5em" onClick={() => navigate('/faq')}>
                <Img src={ProtectDoDoImg} alt="Protect!" w={['90%', '80%', '70%', '60%', '50%']} objectFit="contain" />
            </Center>

            <Text>
                DoDo lay in the weeds, feeling unable to open his eyes. He faintly saw Mountain open his arms while the
                attendants hung on his body, tearing and gnawing.{' '}
            </Text>
            <Text>“DoDo... DoDo is a friend! DoDo is my friend!” </Text>
            <Text>The last scene DoDo saw was the big body of Mountain falling in front of him. </Text>
            <Text>When DoDo woke up again, it was night. A dark figure came into his eyes. </Text>
            <Text>“Mountain!” DoDo shouted. </Text>
            <Text>“My child, you woke up.”</Text>
            <Text>
                As the view ahead became clear, DoDo saw an old silverback gorilla in tribal garb, leaning on a wooden
                cane made of a branch.{' '}
            </Text>
            <Text>“Who are you? Where is Mountain?”</Text>
            <Text>“I’m the High Priest of the silverback tribe. As to Mountain, that child, he has...” </Text>
            <Text>
                The High Priest pointed to behind, where the strong Mountain lay in the middle of the clearing with no
                signs of life.{' '}
            </Text>
            <Text>
                “By the time I arrived with my people, it was too late. The Chief's desire for power has turned him into
                a monster, and I will make him pay.”{' '}
            </Text>
            <Text>
                DoDo came to Mountain and looked at his body and face, recalling when Mountain poked him with his finger
                and beat himself on the chest, when he slept on Mountain's shoulders, and when they ate and chatted
                together. Although Mountain was untalkative all the time, his eyes always looked determined. “My...
                friend...” DoDo thought he had no expression as brave meerkats would not feel sad, but he wasn't aware
                that his face was full of tears.{' '}
            </Text>
            <Text>At this moment, the sword lit up and slowly floated between DoDo and Mountain. </Text>
            <Text>
                “You met through courage. You knew each other through courage. You saved each other through courage, and
                you will move on together through courage. This sword is named Courage, and he will reborn with its
                power.”{' '}
            </Text>
            <Text>The voice of Wakumba slowly arose.</Text>
            <Text>
                The sword floated slowly to Mountain's chest and blended in, which began to turn ups and downs.{' '}
            </Text>
            <Text>
                Mountain slowly opened his eyes to find DoDo standing in front of him, smiling with his face full of
                tears.{' '}
            </Text>
            <Text>“DoDo?” </Text>
            <Text>“Mountain!” </Text>
            <Text>“I remember I died, didn’t I?” </Text>
            <Text>“Nonsense, you were just having a nightmare!” </Text>
            <Text>“Oh, I dreamed about... I had a nightmare.” </Text>
            <Text>“Exactly. It was just a nightmare!” </Text>
            <Text>Mountain stood up and saw the High Priest behind DoDo. He said with his body bent, </Text>
            <Text>“The High Priest, it's good to see you.”</Text>
            <Text>“I was late, but it seemed to be okay now.” </Text>
            <Text>“The Chief...” </Text>
            <Text>“I'll settle with him about it. You don’t have to go back to the tribe for the time being.” </Text>
            <Text>Mountain glanced at DoDo and replied, “Alright. I won’t go back.” </Text>
            <Text>“DoDo, where are you going? I want to be with you.” Mountain turned to DoDo and asked. </Text>
            <Text>“I told you, didn’t I? I’m going north. I’m going all the way north. Haha!” </Text>
            <Text>“Then I’m going all the way north as well!” </Text>
            <Text>
                Mountain scooped up DoDo. “Ouch! Watch out! You are hurting me!” DoDo shouted. Mountain gently put DoDo
                on his shoulders, looked around, and walked toward the forest.{' '}
            </Text>
            <Text>
                “Wakumba, my old friend, how long has it been since we contact? 100 or 200 years? People will get
                forgetful when getting old. This little meerkat is really something. Haha.”{' '}
            </Text>
            <Text>The High Priest talked to himself.</Text>

            <Image
                display={{ base: 'none', xl: 'block' }}
                src={StoryMobImg}
                position="absolute"
                objectFit="initial"
                left="0"
                top={{ base: '0px', xl: '-500px', '2xl': '-480' }}
                h="115%"
                w="100%"
                zIndex="-2"
            ></Image>

            <StoryMap display={{ base: 'block', xl: 'none' }} />
        </Box>
    )
}

export { ChapterOneThreeTitle, ChapterOneThreeArticle }
