import React from 'react'
import { Box, Text, Button, Center, Img, Image, useToast, Flex, VStack } from '@chakra-ui/react'
import mountain_public_mint_card_bg from './assets/mint/mountain_public_mint_card_bg.png'
import success_loading_bg from './assets/mint/success_loading_bg.png'
import ongoing_mountain from './assets/mint/ongoing_mountain.png'
import success_bottom from './assets/mint/success_bottom.png'
import view_on_opensea from './assets/mint/view_on_opensea.png'
import { useEnv } from '../../utils/Environment'
import { validateAmountRange } from '../../utils/validate'
import ImgButton from '../../components/ImgButton'
import { usePublicMint } from './MountainContracts'
import { Circles } from 'react-loader-spinner'
import { ethers } from 'ethers'
import { useAccount } from 'wagmi'
import { errorToString, handleContractError } from '../../utils/ContractUtils'

const PublicSummonCard = () => {
    const styleSuccessBottom = {
        backgroundAttachment: 'local',
        backgroundPosition: 'center',
        backgroundImage: `url(${success_bottom})`,
        backgroundSize: '101% 101%',
    }
    const startBgStyle = {
        aspectRatio: '1142 / 1600',
        width: '300px',
        height: '450px',
        flexDirection: 'column',
        backgroundImage: `url(${mountain_public_mint_card_bg})`,
        backgroundAttachment: 'local',
        backgroundPosition: 'center',
        backgroundSize: '100% 100%',
    }

    const ongoingStyle = {
        ...startBgStyle,
        '@keyframes fga': {
            from: { transform: 'rotateY(0deg)' },
            to: { transform: 'rotateY(360deg)' },
        },
        animation: 'fga 6s infinite ease-in-out',
    }

    const [upgradeStatus, setUpgradeStatus] = React.useState('START')
    const [summonAmount, setSummonAmount] = React.useState(1)
    const toast = useToast()
    const { isDev, network, contracts } = useEnv()
    // debug only
    const [id, setId] = React.useState(0)
    const [mountainSrc, setMountainSrc] = React.useState('')
    const [receivedMountainId, setReceivedMountainId] = React.useState(1)
    const [disableBtn, setDisableBtn] = React.useState(false)
    const prices = { 1: undefined, 2: '0.003', 3: '0.006' }
    const [price, setPrice] = React.useState(undefined)

    // ============== Contract: Enhance Sword ==============
    const { address } = useAccount()
    React.useEffect(() => {
        if (address) {
            setAmount(1)
            setUpgradeStatus('START')
        }
    }, [address])

    function setAmount(n) {
        toast.closeAll()
        setDisableBtn(false)
        setSummonAmount(n)
        setPrice(prices[n])
    }

    const summon = usePublicMint({
        args: [summonAmount],
        overrides:
            price === undefined
                ? undefined
                : {
                      from: address,
                      value: ethers.utils.parseEther(price),
                  },
        onLoadError: (error) => {
            const errStr = errorToString(error)
            if (errStr.indexOf('Cannot exceed max mint per wallet') !== -1) {
                console.log('Max Limit is 3!')
                if (!toast.isActive('rewards-claimed'))
                    toast({
                        id: 'rewards-claimed',
                        title: `Mountain(s) already claimed!`,
                        status: 'info',
                        isClosable: true,
                    })
                if (!disableBtn) setDisableBtn(true)
            } else if (errStr.indexOf('insufficient funds for intrinsic transaction cost') !== -1) {
                console.log('Insufficient founds!')
                if (!toast.isActive('insufficient'))
                    toast({
                        id: 'insufficient',
                        title: `Insufficient ETH to purchase more Mountain`,
                        status: 'info',
                        isClosable: true,
                    })
            } else if (errStr.indexOf('Not enough eth for mint') !== -1) {
                console.log('Not Enough ETH!', price)
                if (price === prices[1]) {
                    setPrice(prices[2])
                } else if (price === prices[2]) {
                    setPrice(prices[3])
                } else if (price === prices[3]) {
                    setPrice(prices[3])
                }
            } else {
                handleContractError('Prepare', error, toast)
            }
        },
        onLoading: () => {
            if (toast.isActive('confirmSummonOnWallet')) {
                toast.close('confirmSummonOnWallet')
            }
            toast({
                id: 'confirmSummonOnWallet',
                title: `Please confirm in your wallet to mint ${
                    summonAmount > 1 ? summonAmount + ' Mountains' : summonAmount + ' Mountain'
                }`,
                description: 'Total Price: ' + (price === undefined ? 'FREE' : `${price} ETH`),
                status: 'info',
                isClosable: true,
                duration: 10_000,
            })
        },
        onTxStarted: () => {
            if (toast.isActive('confirmSummonOnWallet')) {
                toast.close('confirmSummonOnWallet')
            }
            setUpgradeStatus('ONGOING')
        },
        onTxCancelled: () => {
            if (toast.isActive('confirmSummonOnWallet')) {
                toast.close('confirmSummonOnWallet')
            }
        },
        onMintSuccess: (mountainId) => {
            setReceivedMountainId(mountainId)
            setUpgradeStatus('SUCCESS')
        },
    })

    const onMint = () => {
        const { isValid, message } = validateAmountRange(summonAmount, 1, 3)
        if (!isValid) {
            const id = 'validationError'
            !toast.isActive(id) &&
                toast({
                    id: id,
                    status: 'warning',
                    duration: 1000,
                    title: message,
                    isClosable: true,
                })
        } else {
            summon?.()
        }
    }

    function fetchMountainData(tokenId) {
        fetch(
            `https://api.opensea.io/api/v1/asset/0xf933f97d4d1f3a245401d0f70769ead59d34b047/${tokenId}/?force_update=true`
        )
            .then((msg) => {
                console.log('Got API UPDATE: ' + msg)
            })
            .catch((err) => {
                console.log('UPDATE API Failed: ', err)
            })

        console.log('Fetching MT image url ... ')

        setTimeout(() => {
            tokenId !== undefined &&
                fetch(`https://dodofrens.xyz/metadata/mtid/${tokenId}`)
                    .then((_msg) => _msg.json())
                    .then((_data) => {
                        const mountainSrc = `https://gateway.dodofrens.com/ipfs/QmYeSf6eLFJj6zGV3gvei1HtP8hw1EAuSJos6AAUiTBn2B/mt-images-prod/${_data.edition}.png`
                        console.log('Got MT Image ... ' + mountainSrc)
                        setMountainSrc(mountainSrc)
                    })
                    .catch((err) => {
                        console.log('Fetch RefCode Failed: ', err)
                        //ignore and set defaults
                        const mountainSrc = `https://gateway.dodofrens.com/ipfs/QmYeSf6eLFJj6zGV3gvei1HtP8hw1EAuSJos6AAUiTBn2B/mt-images-prod/0.png`
                        setMountainSrc(mountainSrc)
                    })
        }, 1500)
    }

    React.useEffect(() => {
        receivedMountainId && fetchMountainData(receivedMountainId)
    }, [receivedMountainId])

    return (
        <Flex pointerEvents="auto" w="100%" flexDirection="column" gap="5%">
            {isDev && (
                <Button
                    position="fixed"
                    left="10px"
                    top="10px"
                    onClick={() => {
                        setId(id + 1)
                        setUpgradeStatus(['START', 'ONGOING', 'SUCCESS'][(id + 1) % 3])
                    }}
                >
                    Debug: {['START', 'ONGOING', 'SUCCESS'][id % 3]} =&gt; Next:{' '}
                    {['START', 'ONGOING', 'SUCCESS'][(id + 1) % 3]}
                </Button>
            )}

            {
                {
                    START: (
                        <Flex
                            sx={startBgStyle}
                            flexDirection="column"
                            alignItems="center"
                            px="2rem"
                            justifyContent="flex-end"
                            w="60%"
                        >
                            <Flex
                                h="60px"
                                // bg="whiteAlpha.700"
                                w="100%"
                                gap="2px"
                                pl="5rem"
                                pb="0.3rem"
                                textAlign="center"
                            >
                                {/* <Text color="white"> Amount:</Text> */}
                                {[1, 2, 3].map((n) => (
                                    <ImgButton
                                        noMargin
                                        reverse
                                        variant={`amt${n}btn`}
                                        key={n}
                                        // _hover={{ background: '#e66326' }}
                                        // sx={n === summonAmount && { background: '#e66326' }}
                                        isActive={n === summonAmount}
                                        onClick={() => {
                                            setAmount(n)
                                        }}
                                    >
                                        {n}
                                    </ImgButton>
                                ))}
                            </Flex>

                            <Flex w="100%" alignItems="center" justifyContent="center" h="3.5rem">
                                <Text color="white" fontSize="1rem" ml="1rem" mb="1rem">
                                    Total Price:{' '}
                                    <Box as="span" fontWeight="bold">
                                        {disableBtn ? 'N/A' : price === undefined ? 'FREE' : `${price} ETH`}
                                    </Box>
                                </Text>
                            </Flex>

                            <Box w="100%" pl="5rem" mb="0.5rem">
                                {price === undefined ? (
                                    <ImgButton
                                        noMargin
                                        variant="mountain_free_mint_a"
                                        onClick={onMint}
                                        h="50px"
                                        disabled={disableBtn || !summon}
                                    />
                                ) : (
                                    <ImgButton
                                        noMargin
                                        variant="mountain_public_mint"
                                        onClick={onMint}
                                        h="50px"
                                        disabled={disableBtn || !summon}
                                    />
                                )}
                            </Box>
                        </Flex>
                    ),
                    ONGOING: (
                        <Center sx={ongoingStyle}>
                            <Img src={ongoing_mountain} />
                        </Center>
                    ),
                    SUCCESS: (
                        <VStack gap="0" justify="center" w="300px" h="450px">
                            <Box boxShadow=" 0 3px 6px rgba(0,0,0,0.4); " h="300px" w="300px">
                                <Image
                                    src={mountainSrc}
                                    fallback={
                                        <Center>
                                            <Image src={success_loading_bg} />
                                            <Box position="absolute">
                                                <Circles
                                                    height="80"
                                                    width="80"
                                                    color="white"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </Box>
                                        </Center>
                                    }
                                />
                            </Box>

                            <Center sx={styleSuccessBottom} position="relative" w="100%" h="150px" mt="0 !important">
                                <Img
                                    src={view_on_opensea}
                                    position="absolute"
                                    top="80px"
                                    w="200px"
                                    onClick={() =>
                                        window.open(
                                            `https://${isDev ? 'testnets.' : ''}opensea.io/assets/${network.name}/${
                                                contracts.mountain
                                            }/${receivedMountainId}`
                                        )
                                    }
                                    _hover={{ cursor: 'pointer' }}
                                />
                            </Center>
                        </VStack>
                    ),
                }[upgradeStatus]
            }
        </Flex>
    )
}

export default PublicSummonCard
