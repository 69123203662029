import {
    Box,
    Button,
    Center,
    Flex,
    HStack,
    Img,
    Text,
    useMediaQuery,
    useToast,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    chakra,
    Link,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'
import { MintHoverMap, ShareMap, WakumbaBlessMap, WakumbaMap } from '../../components/Backgrounds'
import { BtnConnect } from '../../components/BtnConnect'
import CenterLayout from '../layouts/CenterLayout'
import SwordImg from '../../images/buttons/share/sword.png'
import SwordDisabledImg from '../../images/buttons/share/sword-disabled.png'
import StoneTabImg from '../../images/buttons/share/tab.png'
import { Circles } from 'react-loader-spinner'
import ImgButton from '../../components/ImgButton'
import ShareSteps from './ShareSteps'
import md5 from 'md5'
import { useGetClaimedFaithQty, useGetClaimedSwordQty, useMintFaith, useMintSword } from './ShareContracts'
import { breakpoints } from '../layouts/CustomTheme'
import { useNavigate } from 'react-router-dom'
import { useSwordBalances } from '../Forge/ForgeContracts'
import { FadeInOut } from '../../components/Animations'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useLinks } from '../../utils/Environment'

export const BtnPray = ({ onShareStateChanged, isMobile, ...props }) => {
    const toast = useToast()

    function shareTwitter(address, code) {
        const tags = [
            'NFT',
            'NFTs',
            'NFTs',
            'FreeMint',
            'FreeMint',
            'FreeMints',
            'FreeMints',
            'NFTCommunity',
            'NFTCommunity',
            'NFTCommunity',
            'NFTCommmunity',
            'NFTCommunitys',
            'NFTCommunitys',
            'NFTCommunityCryptoArt',
            'NFTGiveaway',
            'NFTGiveaways',
            'whitelist',
            'GiveawayAlert',
            'freemintNFT',
            'Ethereum',
            'NFTcollections',
            'nftcollector',
            'nftcollectors',
        ]
        const randTag = tags[Math.floor(Math.random() * tags.length)]
        const prefix = '%20%23'
        const hashTag = prefix + randTag
        const base = 'https://twitter.com/intent/tweet'
        // const text = `text=DoDoFrens%20%E2%9C%96%EF%B8%8F%20Mountain%20are%20coming%20%F0%9F%A6%8D%F0%9F%99%8A%F0%9F%A6%8D%0A%0AJoin%20me%3A%20dodofrens.xyz%2Fwakumba%3Fr%3D${code}%0A%0A%F0%9F%94%A5Code%F0%9F%94%A5%3A%20${code}%0A%0AFree%20mint%20%23DoDoFrens%20%40DoDoFrens${hashTag}`
        const text = `text=DoDo%20and%20Mountain%20are%20in%20danger%20%F0%9F%A6%8D%F0%9F%99%8A%F0%9F%A6%8D%0A%0AClaim%20your%20%F0%9F%8C%9FFaith%20%26%20%F0%9F%97%A1Sword%20to%20help%20them%21%0A%0A%20%20%F0%9F%94%A5Ref%20Code%F0%9F%94%A5%3A%20${code}%0A%0AUse%20my%20code%20and%20pass%20on%20Wakumba%27s%20blessings%20here%3A%20dodofrens.xyz%2Fwakumba%3Fr%3D${code}%0A%0AFree%20mint%20%23DoDoFrens%20%40DoDoFrens${hashTag}`
        const urlText = `url=dodofrens.xyz%2Fwakumba%3Fr%3D${code}`
        const url = base + '?' + urlText + '&' + text + '%0A%0A'
        address &&
            fetch(`/api/share/${address}`, { method: 'POST' })
                .then(async (_data) => {
                    if (_data.status === 200) {
                        const shareState = await _data.json()
                        console.log('Shared on Twitter, Get Sharing State: ', address, shareState)
                        onShareStateChanged(shareState)
                        toast.closeAll()
                        setTimeout(() => {
                            toast({
                                title: `Share Success, you've got ${shareState.rules.share} more Faith to claim`,
                                status: 'success',
                                duration: 60_000,
                                isClosable: true,
                            })
                        }, 1_500)
                    } else {
                        const errMsg = await _data.text()
                        toast.closeAll()
                        toast({
                            title: ` ${errMsg}`,
                            status: 'error',
                            duration: 60_000,
                            isClosable: true,
                        })
                    }
                    return _data
                })
                .catch((err) => {
                    console.log('[Server Error]', err)
                    toast({
                        title: `Incorrect server data, please wait for a while and try again.`,
                        status: 'error',
                        // description: `${err}, ${typeof err}, ${JSON.stringify(err)}, ${err.message}`,
                        isClosable: true,
                    })
                })

        window.open(url, 'noopener,noreferrer')
    }

    const { address, isConnected } = useAccount()

    const [refCode, setRefCode] = useState('')

    useEffect(() => {
        address &&
            fetch(`/api/ref/${address}`)
                .then((_msg) => _msg.json())
                .then((_data) => {
                    if (address === _data.addr) {
                        setRefCode(`${_data.code}`)
                    }
                })
                .catch((err) => {
                    console.log('Fetch RefCode Failed: ', err)
                    //ignore and set defaults
                    isConnected
                        ? setRefCode(`${md5(address).substring(6, 13)}`)
                        : (() => {
                              setRefCode('null')
                              toast({
                                  title: `Fail to generate Ref Code, please try again later!`,
                                  status: 'error',
                                  isClosable: true,
                              })
                          })()
                })
    }, [address, isConnected])

    const [loading, setLoading] = useState(false)

    if (isMobile) {
        return (
            <ImgButton
                w="50px"
                h="50px"
                noMargin
                variant="btnTwitter"
                onClick={() => {
                    shareTwitter(address, refCode)
                }}
                clickTimeout={500}
                {...props}
            ></ImgButton>
        )
    }

    return (
        <Center m="5px">
            {loading ? (
                <Circles height="100" width="100" color="#4299e1" ariaLabel="loading" />
            ) : (
                <ImgButton
                    noMargin
                    w="inherit"
                    variant="pray"
                    onClick={() => {
                        setLoading(true)
                        shareTwitter(address, refCode)
                        setTimeout(() => {
                            setLoading(false)
                        }, 1500)
                    }}
                ></ImgButton>
            )}
            {/* <Helmet>
                <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                <script src="./share.js" type="text/javascript" />
            </Helmet> */}
        </Center>
    )
}

const Share = () => {
    const { address, isConnected } = useAccount({
        onConnect() {
            console.log('Connected:', address)
        },
        onDisconnect() {
            console.log('Disconnected:', address)
            setFaithToClaim(0)
            setSwordToClaim(0)
        },
    })

    const toast = useToast()

    const [faithLimit, setFaithLimit] = useState(0)
    const [swordLimit, setSwordLimit] = useState(0)
    const [rules, setRules] = useState({ accept: 1000, share: 2000, used: 200, usageCountForSword: 3 })

    const [faithToClaim, setFaithToClaim] = useState(0)
    const [swordToClaim, setSwordToClaim] = useState(0)

    const [usageCount, setUsageCount] = useState(0)

    const { faithQty: faithClaimed } = useGetClaimedFaithQty({ address: address })
    const { swordQty: swordClaimed } = useGetClaimedSwordQty({ address: address })

    React.useEffect(() => {
        isConnected && address && reset('On address change!')

        const timeout = setInterval(() => {
            isConnected && address && reset('Times up!')
        }, 10 * 1000)

        return () => {
            clearTimeout(timeout)
        }
    }, [address, isConnected])

    function reset(reason = '') {
        console.log('On reload!!!' + reason)
        getStateInfo()
    }

    function getStateInfo() {
        address &&
            fetch(`/api/info/${address}`, { method: 'GET' })
                .then(async (_data) => {
                    if (_data.status === 200) {
                        const shareState = await _data.json()
                        console.log('!!!! Get Sharing State: ', address, shareState)

                        onShareStateChanged(shareState)
                    } else {
                        const errMsg = await _data.text()
                        toast({
                            title: ` ${errMsg}`,
                            status: 'error',
                            isClosable: true,
                        })
                    }
                    return _data
                })
                .catch((err) => {
                    console.log('[Server Error]', err)
                    toast({
                        title: `Incorrect proofsonse data, please wait for a while and try again.`,
                        status: 'error',
                        isClosable: true,
                    })
                })
    }

    const [completed, setCompleted] = useState(false)
    const [accepted, setAccepted] = useState(false)
    const [shared, setShared] = useState(false)
    const [usageTimes, setUsageTimes] = useState([])

    function onShareStateChanged(shareState) {
        console.log('Updating shareState...', shareState)
        // update statues
        setRules(shareState.rules)
        setFaithLimit(shareState.faithLimit)
        setSwordLimit(shareState.swordLimit)
        const usageCount = shareState.usages?.length ? shareState.usages?.length : 0
        setUsageCount(usageCount)
        const usageTimes = shareState.usages ? shareState.usages?.map((s) => s.useTime) : []
        setUsageTimes(usageTimes)
        setCompleted(usageCount >= shareState.rules.usageCountForSword)
        setAccepted(shareState?.accepted?.useTime ? true : false)
        setShared(shareState?.shared?.shareTime ? true : false)
    }

    useEffect(() => {
        console.log('setFaithToClaim:', faithLimit, '-', faithClaimed)

        if (!isNaN(faithClaimed) && !isNaN(faithLimit) && faithLimit - faithClaimed > 0) {
            setFaithToClaim(faithLimit - faithClaimed)
        } else {
            setFaithToClaim(0)
        }
    }, [faithClaimed, faithLimit])

    useEffect(() => {
        console.log('setSwordToClaim:', swordLimit, '-', swordClaimed)

        if (!isNaN(swordClaimed) && !isNaN(swordLimit) && swordLimit - swordClaimed > 0) {
            setSwordToClaim(swordLimit - swordClaimed)
        } else {
            setSwordToClaim(0)
        }
    }, [swordClaimed, swordLimit])

    /*
        {
        "address": "0xa7A39C77e653A4562e335B76259579Cd110AaDC4",
        "code": "78a8ae2",
        "shared": {
            "shareTime": "2022-09-27T15:32:24.621Z",
            "confirms": []
        },
        "accepted": {
            "refCode": "1fa9b69",
            "codeOwner": "0xe431AAfEA39821cA9Da7913ab0F16fE9dbF668fb",
            "useTime": "2022-09-27T15:32:45.010Z"
        },
        "usages": [
            {
            "usedBy": "0x5f73a38c67Bf0A7C3D91040551530d66482600aD",
            "useTime": "2022-09-27T12:30:39.569Z"
            },
            {
            "usedBy": "0x129D8a04F7eFeaC2a1001cF40a3A2B547C74EA06",
            "useTime": "2022-09-27T15:28:21.903Z"
            },
            {
            "usedBy": "0xe431AAfEA39821cA9Da7913ab0F16fE9dbF668fb",
            "useTime": "2022-09-27T15:31:30.809Z"
            },
            {
            "usedBy": "0x2849A3Cab3C674dD5d1c1939C763a6BFc4F06BC7",
            "useTime": "2022-09-27T15:32:01.288Z"
            }
        ],
        "rules": {
            "accept": 1000,
            "share": 2000,
            "used": 200,
            "usageCountForSword": 3
        },
        "faithLimit": 3800,
        "swordLimit": 1,
        "time": "2022-09-27T15:32:45.010Z"
        }
    */

    const [faithArgs, setFaithArgs] = useState([])

    useEffect(() => {
        console.log('faithToClaim changed, get claims!', faithToClaim, address)
        address &&
            faithToClaim > 0 &&
            fetch(`/api/claims/faith/${address}/${faithToClaim}`, { method: 'POST' })
                .then(async (_data) => {
                    if (_data.status === 200) {
                        const proofs = await _data.json()
                        // console.log('Proofs to Claim Faith: ', proofs)

                        setFaithArgs([proofs.addr, proofs.qty, proofs.max, proofs.nonce, proofs.proof])
                    } else {
                        const errMsg = await _data.text()
                        toast({
                            title: ` ${errMsg}`,
                            status: 'error',
                            isClosable: true,
                        })
                    }
                    return _data
                })
                .catch((err) => {
                    console.log('[Server Error]', err)
                    toast({
                        title: `Incorrect proofsonse data, please wait for a while and try again.`,
                        status: 'error',
                        isClosable: true,
                    })
                })
    }, [faithToClaim])

    const { mintFaith } = useMintFaith({
        address: address,
        args: faithArgs,
        onTxSuccess: () => {
            toast({
                title: `Claim Success! Click on the Faith icon to continue enhance your Sword.`,
                status: 'success',
                isClosable: true,
                duration: 15_000,
            })
        },
    })

    const [swordArgs, setSwordArgs] = useState([])

    useEffect(() => {
        console.log('swordToClaim changed, get claims!', swordToClaim, address)
        address &&
            swordToClaim > 0 &&
            fetch(`/api/claims/sword/${address}/${swordToClaim}`, { method: 'POST' })
                .then(async (_data) => {
                    if (_data.status === 200) {
                        const proofs = await _data.json()
                        // console.log('Proofs to Claim Sword: ', proofs)

                        setSwordArgs([proofs.addr, proofs.qty, proofs.max, proofs.nonce, proofs.proof])
                    } else {
                        const errMsg = await _data.text()
                        toast({
                            title: ` ${errMsg}`,
                            status: 'error',
                            isClosable: true,
                        })
                    }
                    return _data
                })
                .catch((err) => {
                    console.log('[Server Error]', err)
                    toast({
                        title: `Incorrect proofsonse data, please wait for a while and try again.`,
                        status: 'error',
                        isClosable: true,
                    })
                })
    }, [swordToClaim])

    const { mintSword } = useMintSword({
        address: address,
        args: swordArgs,
        onTxSuccess: () => {
            toast({
                title: `Sword Mint Success! Click on Enhance icon to continue`,
                status: 'success',
                isClosable: true,
                duration: 15_000,
            })
        },
    })

    const navigate = useNavigate()

    const [isMd] = useMediaQuery(`(max-width: ${breakpoints.md})`)

    const { balances: swordBalances } = useSwordBalances({ address: address, swordIds: [0, 1, 2, 3, 4] })

    // const [isMobSelected, setMobSelected] = useState(false)

    const { openSeaItemUrl } = useLinks()

    const alreadyClaimedSword = swordClaimed > 0 && swordClaimed >= swordLimit
    const hasSwordToClaim = swordLimit > 0 && swordToClaim > 0

    return !isConnected ? (
        <>
            <CenterLayout>
                <BtnConnect variant="connectWallet" />
            </CenterLayout>
            <WakumbaMap />
        </>
    ) : (
        <CenterLayout>
            <Flex
                className="pageWrapper"
                direction={{ base: 'column', md: 'row' }}
                gap="1"
                justify="center"
                align="center"
                w="100%"
                h="100%"
            >
                <Flex
                    className="leftModalWrapper"
                    justifyContent="center"
                    align="center"
                    direction="column"
                    w="500px"
                    minW="500px"
                    // onClick={() => {
                    //     setMobSelected(!isMobSelected)
                    // }}
                >
                    <Flex
                        // bg="green"
                        className="leftModal"
                        direction="column"
                        justify="space-between"
                        align="center"
                        transform={{ base: 'none', md: 'perspective(800px) rotateY(20deg)' }}
                        w={{ base: '100px', sm: '200px', md: '300px' }}
                        h={{ base: '150px', sm: '300px', md: '450px' }}
                        bgImg={(() => {
                            return !alreadyClaimedSword ? SwordImg : SwordDisabledImg
                        })()}
                        bgSize="100%"
                        backgroundAttachment="local"
                        backgroundPosition="contain"
                        backgroundRepeat="no-repeat"
                    >
                        <Box mt="20%" mr="30%">
                            <Popover placement="bottom" isLazy>
                                <PopoverTrigger>
                                    <Box>
                                        <ImgButton w="30px" h="30px" noMargin variant="markQuestion" />
                                    </Box>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverHeader>The Sword Of Bravery</PopoverHeader>
                                    <PopoverBody>
                                        <Text mb="10px">
                                            1. The owners of Sword of Bravery are whitelisted to mint the next NFT
                                            collection (
                                            <Link href="/story" textDecor="underline">
                                                Mountain
                                            </Link>
                                            )
                                        </Text>
                                        <Text mb="15px">2. Swords are distributed via campaigns or invitations</Text>{' '}
                                        <Text mb="15px">
                                            3. Swords are upgradable by using{' '}
                                            <Link href="/forge?from=faq" textDecor="underline">
                                                Faith and Stars
                                            </Link>
                                        </Text>{' '}
                                        <Text mb="0px">
                                            (Currently you own{' '}
                                            <Box
                                                display="inline"
                                                m="0px"
                                                onClick={() => navigate('/forge')}
                                                cursor="pointer"
                                                textDecoration="underline"
                                            >
                                                {swordBalances?.reduce((a, b) => a + b)} Swords
                                            </Box>
                                            )
                                        </Text>
                                    </PopoverBody>
                                    <PopoverFooter>
                                        <Link
                                            href={`${openSeaItemUrl}${process.env.REACT_APP_AIRDROP_CONTRACT_ADDR}/0`}
                                            isExternal
                                            onClick={() => {
                                                address &&
                                                    fetch(`/api/log/${address}/buyOnOpensea`)
                                                        .then((_msg) => _msg.json())
                                                        .catch((err) => {
                                                            console.log('Logging Failed: ', err)
                                                        })
                                            }}
                                        >
                                            View Swords on <ExternalLinkIcon />
                                            <Box as="span" textDecor="underline">
                                                Opensea
                                            </Box>
                                        </Link>
                                    </PopoverFooter>
                                </PopoverContent>
                            </Popover>
                        </Box>

                        <Flex
                            // bg="blackAlpha.500"
                            h={{ base: '70px', md: '100px' }}
                            p="20px"
                            ml={{ base: '0', sm: '50px' }}
                            mb="20px"
                        >
                            <ImgButton
                                // disabled={!mintSword}
                                noMargin
                                h="100%"
                                maxW="120px"
                                variant={(() => {
                                    if (alreadyClaimedSword) {
                                        return 'enhance'
                                    }
                                    return hasSwordToClaim ? 'btnMint' : 'btnMintDisabled'
                                })()}
                                onClick={() => {
                                    if (alreadyClaimedSword) {
                                        navigate('/forge')
                                        return
                                    }

                                    if (hasSwordToClaim) {
                                        mintSword?.()
                                    } else {
                                        toast.closeAll()
                                        toast({
                                            title: `You need at least ${rules.usageCountForSword} valid invitations to mint the sword`,
                                            status: 'info',
                                            isClosable: true,
                                        })
                                    }
                                }}
                                clickTimeout={1500}
                            />
                        </Flex>
                    </Flex>
                </Flex>

                {!isMd && (
                    <Flex minW="220px" maxW="300px" justifyContent="center" alignItems="center">
                        <BtnPray onShareStateChanged={onShareStateChanged} isMobile={false} />
                    </Flex>
                )}

                <Flex
                    className="rightModalWrapper"
                    // bg="blue"
                    justifyContent="center"
                    align="center"
                    direction="column"
                    w="500px"
                    minW="500px"
                    // onClick={() => {
                    //     setMobSelected(!isMobSelected)
                    // }}
                >
                    <Flex
                        className="rightModal"
                        direction="column"
                        justify="center"
                        align="center"
                        transform={{ base: 'none', md: 'perspective(800px) rotateY(-20deg)' }}
                        w={{ base: '350px', md: '500px' }}
                        h={{ base: '412px', md: '588px' }}
                        backgroundImage={StoneTabImg}
                        bgSize="100%"
                        backgroundAttachment="local"
                        backgroundPosition="contain"
                        backgroundRepeat="no-repeat"
                    >
                        <ShareSteps
                            rules={rules}
                            usageCount={usageCount}
                            usageTimes={usageTimes}
                            faithToClaim={faithToClaim}
                            onClaimFaith={mintFaith}
                            onShareStateChanged={onShareStateChanged}
                            shared={shared}
                            accepted={accepted}
                            completed={completed}
                        />
                    </Flex>
                </Flex>
            </Flex>

            {isMd ? (
                <chakra.div
                    id="overlayMap"
                    sx={{
                        width: '100%',
                        height: '100%',
                        top: '0',
                        left: '0',
                        position: 'absolute',
                    }}
                    m="0px !important"
                    zIndex="-1"
                    bg="blackAlpha.500"
                ></chakra.div>
            ) : (
                <WakumbaBlessMap id="shineMap" zIndex="-1" animation={FadeInOut} />
            )}
            <ShareMap id="bgMap" />
        </CenterLayout>
    )
}

export default Share
