import { Box, Center } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HomeMap } from '../components/Backgrounds'
import ImgButton from '../components/ImgButton'

function Home() {
    const navigate = useNavigate()
    return (
        <>
            <Center
                id="hover-box"
                position="absolute"
                top={['49%']}
                left={['15%', '20%', '28%', '25%', '15%', '5%']}
                w={{ base: '250px', '2xl': '800px' }}
                h="250px"
                // backgroundColor="whiteAlpha.500"
                // cursor="pointer"
                // onClick={() => navigate('/story')}
            >
                <Box bg="blackAlpha.500" p="0.5rem" borderRadius="md">
                    <ImgButton
                        variant="freeMint"
                        noMargin
                        h="2rem"
                        onClick={() => navigate('/mountain')}
                        zIndex="10"
                        isActive={true}
                    ></ImgButton>
                </Box>
            </Center>

            <HomeMap right={['-640px', '-750px', '-750px', '-550', '0']} />
        </>
    )
}

export default Home
