import React, { useState } from 'react'
import {
    Center,
    Box,
    Text,
    Image,
    Button,
    Flex,
    Spinner,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    useToast,
    Img,
} from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons'
import ImgButton from '../../components/ImgButton'
import faithIcon from '../../images/buttons/forge/popup-base/faith.png'

const debug = process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production'

function GalleryBox({ data, isMobile, stakeNow, unstakeNow, isOnStakedTab, faithToClaim }) {
    const [selected, setSelected] = React.useState([])
    const sharedStyle = { cursor: 'pointer', position: 'relative' }
    const horizontalStyle = { ...sharedStyle, width: '110px', height: '110px', margin: '4px' }

    const verticalStyle = { ...sharedStyle, width: '110px', height: '110px', margin: '4px' }

    const galleryStyle = isMobile ? verticalStyle : horizontalStyle

    const hintTextStyle = {
        height: '1rem',
        position: 'absolute',
        fontSize: '0.5rem',
        top: '4px',
        left: '4px',
        background: 'transparent',
        color: 'white',
    }
    const checkedBtnStyle = {
        height: '1rem',
        position: 'absolute',
        fontSize: '0.5rem',
        top: '4px',
        right: '4px',
    }
    const dodoListSharedStyle = { position: 'absolute', flexWrap: 'wrap', overflowY: 'scroll' }
    const dodoListStyle = isMobile
        ? { ...dodoListSharedStyle, top: '38%', bottom: '15%', width: '75%' }
        : { ...dodoListSharedStyle, top: '220px', width: '600px', height: '280px' }

    const onSelectItem = (id) => {
        const updatedSelectItems = selected.includes(id) ? [...selected.filter((s) => s !== id)] : [...selected, id]
        setSelected(updatedSelectItems)
    }

    if (debug) {
        isOnStakedTab ? console.log('Staked Selection:', selected) : console.log('Unstaked Selection:', selected)
    }

    React.useEffect(() => {
        console.log('Reset Selection!')
        setSelected([])
    }, [isOnStakedTab, setSelected])

    const isAllSelected = data.length === selected.length

    const onSelectAll = () => {
        setSelected(data.map((item) => item.id))
    }

    const onUnselectAll = () => {
        setSelected([])
    }

    const toast = useToast()
    const { isOpen, onOpen, onClose: closeIt } = useDisclosure()
    const cancelRef = React.useRef()
    function onClose() {
        console.log('Close!', cancelRef.current)
        closeIt()
    }
    function rejectUnstakeNow(selected) {
        console.log('!!! Reject Unstake Now!', selected)
        if (selected && selected.length > 0) {
            onOpen()
        } else {
            toast({
                title: `Please select at least one DoDo`,
                status: 'warning',
                isClosable: true,
            })
        }
    }
    const [isLoading, setLoading] = useState(false)
    function confirmUnstakeNow(selected) {
        console.log('!!! Confirm Unstake Now!', selected)
        unstakeNow(selected)
        setLoading(true)
        setTimeout(() => {
            closeIt()
            setLoading(false)
        }, 2000)
    }

    return (
        <Center>
            <Flex
                justifyContent="space-around"
                width="70%"
                className="dodo-controls"
                position="absolute"
                top={isMobile ? '31%' : '29%'}
            >
                <Button
                    w="5.8rem"
                    h="2rem"
                    onClick={isAllSelected ? onUnselectAll : onSelectAll}
                    margin="0"
                    padding="0"
                    border=".1rem solid #b0491b"
                >
                    {isAllSelected ? 'Deselect All' : 'Select All'}
                </Button>

                <Box h="2rem" w="5rem" fontWeight="boulder" textAlign="center">
                    <Text fontSize="1.5rem" lineHeight="1.5rem" margin="0px">
                        {selected.length}/{data.length}
                    </Text>
                    <Text fontSize="1rem" lineHeight="1rem" margin="0px">
                        selected
                    </Text>
                </Box>

                <ImgButton
                    h="2rem"
                    margin="0"
                    variant={isOnStakedTab ? 'unstake' : 'stake'}
                    onClick={
                        isOnStakedTab
                            ? () => {
                                  if (faithToClaim > 0) {
                                      rejectUnstakeNow(selected)
                                  } else {
                                      unstakeNow(selected)
                                  }
                              }
                            : () => {
                                  stakeNow(selected)
                              }
                    }
                />
            </Flex>
            <Center sx={dodoListStyle}>
                {data.map((item) => (
                    <Box key={item.id} sx={galleryStyle} onClick={() => onSelectItem(item.id)}>
                        <Text sx={hintTextStyle}>{`#${item.id}`}</Text>
                        <LoadingImage src={item.src} />
                        <CheckCircleIcon sx={checkedBtnStyle} color={selected.includes(item.id) ? 'orange' : 'white'} />
                    </Box>
                ))}
            </Center>

            <>
                <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered={true}>
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                Confirm Stop Meditation
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                <Text>
                                    You have {faithToClaim} unclaimed Faith
                                    <Img src={faithIcon} w="15px" display="inline-block" />.
                                </Text>
                                <Text>Are you sure to stop Meditation without claimming the Faith?</Text>
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                    Go Back
                                </Button>
                                <Button
                                    colorScheme="red"
                                    onClick={() => {
                                        confirmUnstakeNow(selected)
                                    }}
                                    ml={3}
                                    isLoading={isLoading}
                                    loadingText="loading"
                                >
                                    Continue w/o claim Faith
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </>
        </Center>
    )
}

const LoadingImage = ({ src }) => {
    const [loading, setLoading] = useState(true)

    return (
        <Box>
            <Flex
                justify="center"
                alignItems="center"
                h="110px"
                w="110px"
                border="1px dashed gray"
                display={loading ? 'flex' : 'none'}
            >
                <Spinner color="orange" />
            </Flex>
            <Image src={src} onLoad={() => setLoading(false)} display={loading ? 'none' : 'block'} />
        </Box>
    )
}

export default GalleryBox
