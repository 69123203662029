import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './pages/layouts/App'
import Home from './pages/Home'
import Story from './pages/Story'
import Wakumba from './pages/Wakumba'
import Meditation from './pages/Meditation/Meditation'
import Forge from './pages/Forge/Forge'
import Mountain from './pages/Mountain/Mountain'
import Share from './pages/Share/Share'
import FAQ from './pages/FAQ'
import HealthCheck from './pages/HealthCheck'
import Auth from './pages/Auth'
import ComingSoon from './pages/ComingSoon'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

// Rainbowkit Dependencies
import '@rainbow-me/rainbowkit/styles.css'
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

// Web3 connection providers
import { infuraProvider } from 'wagmi/providers/infura'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

const { chains, provider, webSocketProvider } = configureChains(
    // [chain.mainnet, chain.polygon, chain.optimism, chain.arbitrum],
    process.env.REACT_APP_CUSTOM_NODE_ENV === 'production' ? [chain.mainnet] : [chain.goerli, chain.localhost],
    [
        alchemyProvider({
            apiKey:
                process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'
                    ? process.env.REACT_APP_PROVIDER_ALCHEMY_ID_PRD
                    : process.env.REACT_APP_PROVIDER_ALCHEMY_ID_RIN,
        }),
        infuraProvider({ apiKey: process.env.REACT_APP_PROVIDER_INFURA_ID }),
        publicProvider(),
    ]
)

const { connectors } = getDefaultWallets({
    appName: 'DoDoFrens',
    chains,
})

export const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
    webSocketProvider,
})

const root = ReactDOM.createRoot(document.getElementById('root'))

console.log('Starting env...', process.env.REACT_APP_CUSTOM_NODE_ENV)

window.Buffer = window.Buffer || require('buffer').Buffer

root.render(
    <React.StrictMode>
        <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={chains}>
                {process.env.REACT_APP_CUSTOM_NODE_ENV === 'comingsoon' ? (
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<ComingSoon />}></Route>
                        </Routes>
                    </BrowserRouter>
                ) : (
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<App />}>
                                <Route index element={<Home />} />
                                <Route path="/story" element={<Story />} />
                                <Route path="/wakumba" element={<Wakumba />} />
                                <Route path="/meditation" element={<Meditation />} />
                                <Route path="/forge" element={<Forge />} />
                                <Route path="/mountain" element={<Mountain />} />
                                <Route path="/share" element={<Share />} />
                                <Route path="/faq" element={<FAQ />} />
                                <Route path="/faqs" element={<FAQ />} />
                                {/* <Route path="/mint" element={<Mint />} /> */}
                            </Route>
                            <Route path="/cld" element={<Auth />} />
                            <Route path="/health" element={<HealthCheck />} />
                        </Routes>
                    </BrowserRouter>
                )}
            </RainbowKitProvider>
        </WagmiConfig>
    </React.StrictMode>
)
