import { Box, Center, Container, useToast, chakra, Link } from '@chakra-ui/react'
import { React, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAccount } from 'wagmi'
import { WakumbaBlessMap } from '../../components/Backgrounds'
import { BtnWLChecker } from '../../components/BtnConnect'
import ImgButton from '../../components/ImgButton'
import { ShareTwitterBtn } from '../../components/ShareTwitter'
import { FadeInOut, GrowShrink } from '../../components/Animations'

export default function StageWakumbaPray({ stage }) {
    const toast = useToast()

    // whitelist
    const [whitelist, setWhitelist] = useState()
    const { address } = useAccount({
        onConnect() {
            console.log('Connected:', address)
            setWhitelist({})
        },
        onDisconnect() {
            console.log('Disconnected:', address)
            setWhitelist({})
        },
    })

    const search = (_addr, setLoading) => {
        setLoading(true)
        if (stage === 2) {
            // wl collecting
            fetch(`/api/register/${_addr}`, { method: 'POST' })
                .then((_msg) => _msg.json())
                .then((_data) => setWhitelist(_data))
                .then(() => setLoading(false))
                .catch((err) => {
                    console.log('Register WL Failed: ', err)
                    setWhitelist({})
                    setLoading(false)
                    toast({
                        title: `Network Error, please try again later`,
                        status: 'error',
                        isClosable: true,
                    })
                })
        } else {
            // wl checking
            fetch(`/api/whitelisted/${_addr}`, { method: 'POST' })
                .then((_msg) => _msg.json())
                .then((_data) => setWhitelist(_data))
                .then(() => setLoading(false))
                .catch((err) => {
                    console.log('Check WL Failed: ', err)
                    setWhitelist({})
                    setLoading(false)
                    toast({
                        title: `Network Error, please try again later`,
                        status: 'error',
                        isClosable: true,
                    })
                })
        }
    }

    const okBackgrounds = ['blessOk1', 'blessOk2']
    const okBg = okBackgrounds[Math.floor(Math.random() * okBackgrounds.length)]

    const navigate = useNavigate()

    return (
        <Center>
            <BtnWLChecker
                whitelist={whitelist}
                search={search}
                onValid={
                    <Container>
                        <Center>
                            <ImgButton
                                _hover={{
                                    animation: GrowShrink,
                                }}
                                onClick={() => navigate('/mint')}
                                variant={okBg}
                            />
                        </Center>

                        {whitelist && whitelist.code && (
                            <Center>
                                <ShareTwitterBtn w="300px" refCode={whitelist.code} />
                            </Center>
                        )}

                        <Center>
                            <Link pr="10px" fontSize="2xl" size="xl" color="white" as={NavLink} to="/faq">
                                FAQ
                            </Link>
                        </Center>

                        <WakumbaBlessMap zIndex="-1" animation={FadeInOut} />
                    </Container>
                }
                onInvalid={
                    <Container>
                        <Center>
                            <ImgButton
                                _hover={{
                                    animation: GrowShrink,
                                }}
                                onClick={() => navigate('/mint')}
                                variant="blessFail"
                                cursour="auto"
                            />
                        </Center>

                        {whitelist && whitelist.code && (
                            <Container>
                                <Center>
                                    <Box bg="blackAlpha.600" color="white" fontSize="xl" px="0.5em" pt="1em">
                                        <Center>
                                            🔥
                                            <chakra.span textDecor="underline">Code: {whitelist.code}</chakra.span>
                                            🔥{' '}
                                        </Center>
                                        <ShareTwitterBtn w={['200px', '300px']} m="0" refCode={whitelist.code} />
                                    </Box>
                                </Center>
                            </Container>
                        )}
                    </Container>
                }
            />
        </Center>
    )
}
