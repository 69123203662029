import React, { useState } from 'react'
import {
    Box,
    Text,
    Center,
    Flex,
    Spinner,
    Link,
    Heading,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    Button,
    useDisclosure,
    Img,
} from '@chakra-ui/react'
import ImgButton from '../../components/ImgButton'
import bgModal from '../../images/backgrounds/bg-meditation-modal.png'
import bgModalMob from '../../images/backgrounds/bg-meditation-modal-mobile.png'
import { useBalance, useContractRead, useNetwork } from 'wagmi'
import StakingContract from '../../contracts/StakingContract'
import GalleryBox from './GalleryBox'
import { Alchemy, Network } from 'alchemy-sdk'
import labels from '../../components/data/labels.json'
import faithIcon from '../../images/buttons/forge/popup-base/faith.png'

// constrants
const debug = process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production'
const alchemyKey = debug ? process.env.REACT_APP_PROVIDER_ALCHEMY_ID_RIN : process.env.REACT_APP_PROVIDER_ALCHEMY_ID_PRD
const stakingContractConfig = {
    addressOrName: process.env.REACT_APP_STAKING_CONTRACT_ADDR,
    contractInterface: StakingContract.abi,
    mode: 'recklesslyUnprepared',
}
const doDoFrensTokenAddress = process.env.REACT_APP_MINT_CONTRACT_ADDR
const faithTokenAddress = process.env.REACT_APP_FAITH_TOKEN_CONTRACT_ADDR

const TopItem = ({ variant, isMobile, children, action, desc }) => {
    return (
        <Center
            w="100%"
            flexDirection="column"
            display={'inline'}
            overflow="hidden"
            // whiteSpace="nowrap"
            alignItems="center"
            justifyContent="center"
        >
            <Popover placement="bottom" trigger="hover">
                <PopoverTrigger>
                    <Text
                        fontSize={isMobile ? '0.75rem' : '1.25rem'}
                        textAlign="center"
                        color="white"
                        cursor="pointer"
                        w="100%"
                    >
                        <span>{children}</span>
                    </Text>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader />
                    <PopoverBody>
                        <Text>{desc}</Text>
                    </PopoverBody>
                    <PopoverFooter />
                </PopoverContent>
            </Popover>

            <Center>
                <ImgButton margin="0" h={isMobile ? '1.4rem' : '1.8rem'} variant={variant} onClick={action} />
            </Center>
        </Center>
    )
}

function reduceBigNumber(number) {
    if (number) {
        const qtyStr = number.toString()
        if (typeof number === 'number') {
            return qtyStr
        }
        const result = qtyStr.substring(0, qtyStr.length - 18)
        return result.length === 0 ? '0' : result
    }
    return '0'
}

const TimeoutSpinner = ({ color, toogleStaked, isOnStakedTab }) => {
    return (
        <Flex position="absolute" justify="center" alignItems="center" flexDirection="column">
            <Heading m="1rem">
                <Link href={labels.links.opensea} isExternal={true}>
                    DoDo
                </Link>{' '}
                is wandering around ...
            </Heading>
            <Center>
                <Button onClick={toogleStaked}>
                    {!isOnStakedTab ? 'Check Meditating DoDos' : 'Check Not Meditated DoDos'}
                </Button>
            </Center>
        </Flex>
    )
}

const MeditationModal = (props) => {
    const { address, isOnStakedTab, setStaked, isMobile, claimFaithNow, useFaithNow, refresh, stage } = props

    // staked data
    const [stakedData, setStakedData] = useState([])

    // get staked Ids from staking contract
    const { refetch: fetchStakedData } = useContractRead({
        functionName: 'getStakedIdsByAddress',
        // watch: true,
        ...stakingContractConfig,
        args: [address],
        // cacheOnBlock: true, // block change
        cacheTime: 3_000, // enable cache
        onSuccess(stakedIds) {
            console.log('>>> Got NFTs in Contract:', stakedIds)
            if (stakedIds) {
                const _data = stakedIds
                    .map((s) => {
                        const id = s.toNumber()
                        return {
                            id: parseInt(id),
                            selected: false,
                            name: `DoDoFrens #${id}`,
                            src: `https://gateway.dodofrens.com/ipfs/QmQJzcSnQxyBkXH4vgQ34kP9RKV4FSnEsDf9fdNBpQxWpf/${id}.png`,
                        }
                    })
                    .reverse()
                setStakedData(_data)
                if (debug) {
                    console.log('StakedData:', _data)
                }
            }
        },
    })

    // unstaked data
    const [unstakedData, setUnstakedData] = useState([])

    function fetchUnstakedData(_address, _chain) {
        const network = debug ? Network.ETH_GOERLI : Network.ETH_MAINNET
        console.log('Using network: ', network)
        const alchemy = new Alchemy({
            apiKey: alchemyKey,
            network: network,
        })
        alchemy.nft
            .getNftsForOwner(address, {
                contractAddresses: [doDoFrensTokenAddress],
            })
            .then((msg) => {
                console.log('>>> Got NFTs in Wallet:', msg)
                const nfts = msg.ownedNfts.map((nft) => {
                    const id = nft.tokenId
                    return {
                        id: parseInt(id),
                        selected: false,
                        name: `DoDoFrens #${id}`,
                        src: `https://gateway.dodofrens.com/ipfs/QmQJzcSnQxyBkXH4vgQ34kP9RKV4FSnEsDf9fdNBpQxWpf/${id}.png`,
                    }
                })
                setUnstakedData(nfts)
                setBalanceOfDoDoFrens(msg.totalCount)

                if (debug) {
                    console.log('UnstakedData:', nfts)
                    console.log('balanceOfDoDoFrens:', balanceOfDoDoFrens)
                }
            })
    }

    const { chain } = useNetwork()
    React.useEffect(() => {
        if (address) {
            fetchUnstakedData(address, chain)
            fetchStakedData()
            console.log('Reloading data ...')
        }
    }, [address, chain, refresh])

    // Read Contract
    // 1. Get Balance of Faith
    const { data: balanceOfFaith } = useBalance({
        addressOrName: address,
        token: faithTokenAddress,
        watch: true,
        onSuccess(msg) {
            console.log('balanceOfFaith:', balanceOfFaith, msg)
        },
    })

    // 2. Get Faith# To Claim
    const { data: faithToClaim } = useContractRead({
        functionName: 'getTotalClaimableFaith',
        ...stakingContractConfig,
        args: [address],
        watch: true,
        onSuccess(qty) {
            console.log('faithToClaim:', address, qty)
        },
    })

    // 3. Get Balance of DoDos in Wallet
    const [balanceOfDoDoFrens, setBalanceOfDoDoFrens] = useState(0)

    const wrapperSharedStyle = {
        position: 'relative',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        margin: '0',
        pointerEvents: 'auto',
    }
    const wrapperStyle = isMobile
        ? {
              ...wrapperSharedStyle,
              width: '100%',
              height: '80vh',
              backgroundImage: `url(${bgModalMob})`,
              backgroundAttachment: 'local',
              backgroundSize: '100% 100%',
          }
        : {
              ...wrapperSharedStyle,
              width: '900px',
              height: '600px',
              backgroundImage: `url(${bgModal})`,
              backgroundAttachment: 'fixed',
              backgroundSize: '900px 600px',
          }

    return (
        <Center
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                pointerEvents: 'none',
            }}
        >
            <Center className="modalWrapper" sx={wrapperStyle}>
                <Center>
                    <Flex
                        position="absolute"
                        top={isMobile ? '11%' : '40px'}
                        justifyContent="space-between"
                        w={isMobile ? '65%' : '512px'}
                        ml={isMobile ? '10px' : '0px'}
                        mr={isMobile ? '10px' : '30px'}
                        align="baseline"
                    >
                        <TopItem
                            variant="stakeTop"
                            isMobile={isMobile}
                            action={() => setStaked(false)}
                            desc={'The DoDoFrens: Meditating/Total'}
                        >
                            {reduceBigNumber(stakedData.length)}/
                            {reduceBigNumber(stakedData.length + balanceOfDoDoFrens)} DoDo
                        </TopItem>

                        <TopItem
                            variant="claimTop"
                            isMobile={isMobile}
                            action={() => {
                                claimFaithNow(
                                    stakedData.map((s) => s.id),
                                    parseInt(reduceBigNumber(faithToClaim))
                                )
                            }}
                            desc={
                                stage >= 11 ? (
                                    <span>
                                        DoDo can gain 1 Faith{' '}
                                        <Box as="span" textDecor="line-through">
                                            every 5 mins
                                        </Box>{' '}
                                        every half an hour when he is doing Meditation
                                    </span>
                                ) : (
                                    <span>DoDo can gain 1 Faith every 5 mins when he is doing Meditation</span>
                                )
                            }
                        >
                            {reduceBigNumber(faithToClaim)} Faith
                            <Img src={faithIcon} w="15px" display="inline-block" />
                        </TopItem>

                        <TopItem
                            variant="useTop"
                            isMobile={isMobile}
                            action={useFaithNow}
                            desc={'The balance of Faith in your wallet'}
                        >
                            {reduceBigNumber(balanceOfFaith?.value)} Faith
                            <Img src={faithIcon} w="15px" display="inline-block" />
                        </TopItem>
                    </Flex>
                </Center>

                <ImgButton
                    name="btnUnstaked"
                    h={isMobile ? '3rem' : isOnStakedTab ? '4.3rem' : '5.2rem'}
                    position="absolute"
                    left={isMobile ? '10%' : '-35px'}
                    top={isMobile ? '20%' : '130px'}
                    variant="unstaked"
                    onClick={() => setStaked(false)}
                    isActive={!isOnStakedTab}
                />
                <ImgButton
                    name="btnStaked"
                    h={isMobile ? '3.5rem' : !isOnStakedTab ? '4.3rem' : '4.8rem'}
                    position="absolute"
                    left={!isMobile && '-25px'}
                    right={isMobile && '10%'}
                    top={isMobile ? '19%' : '210px'}
                    variant="staked"
                    onClick={() => setStaked(true)}
                    isActive={isOnStakedTab}
                />

                {isOnStakedTab ? (
                    stakedData.length > 0 ? (
                        <GalleryBox
                            isMobile={isMobile}
                            stakeNow={props.stakeNow}
                            unstakeNow={props.unstakeNow}
                            isOnStakedTab={isOnStakedTab}
                            data={stakedData}
                            faithToClaim={parseInt(reduceBigNumber(faithToClaim))}
                        ></GalleryBox>
                    ) : (
                        <TimeoutSpinner
                            color="red.500"
                            toogleStaked={() => setStaked(!isOnStakedTab)}
                            isOnStakedTab={isOnStakedTab}
                        />
                    )
                ) : unstakedData.length > 0 ? (
                    <GalleryBox
                        isMobile={isMobile}
                        stakeNow={props.stakeNow}
                        unstakeNow={props.unstakeNow}
                        isOnStakedTab={isOnStakedTab}
                        data={unstakedData}
                        faithToClaim={parseInt(reduceBigNumber(faithToClaim))}
                    ></GalleryBox>
                ) : (
                    <TimeoutSpinner
                        color="orange"
                        toogleStaked={() => setStaked(!isOnStakedTab)}
                        isOnStakedTab={isOnStakedTab}
                    />
                )}

                {!isMobile && (
                    <>
                        <ImgButton
                            h="6rem"
                            position="absolute"
                            bottom="0px"
                            left="0px"
                            variant="navPrev"
                            onClick={() => setStaked(!isOnStakedTab)}
                        />
                        <ImgButton
                            h="6rem"
                            position="absolute"
                            bottom="0px"
                            right="0px"
                            variant="navNext"
                            onClick={() => setStaked(!isOnStakedTab)}
                        />
                    </>
                )}
            </Center>
        </Center>
    )
}

export default MeditationModal
