import React from 'react'
import { Center, Box, Flex, Heading, Button, Text } from '@chakra-ui/react'
import { useSwordRates } from './ForgeContracts'
const SwordInfoButton = ({ rate, kind, color, isSelected, ...props }) => {
    return (
        <Flex
            w={{ base: '130px', sm: '150px' }}
            align="space-around"
            textAlign="center"
            verticalAlign="center"
            whiteSpace="nowrap"
            cursor="pointer"
            draggable="false"
            userSelect="none"
            bgColor={isSelected ? 'whiteAlpha.500' : 'blackAlpha.100'}
            borderRadius="5px"
            py="0.05em"
            {...props}
        >
            <Center w="28.5%" mx="5%" fontSize="0.75rem" fontWeight="bolder">
                {rate}
            </Center>
            <Center w="71.5%" ml="3%" style={{ backgroundColor: 'transparent', color: color }}>
                <Heading fontSize="1rem" letterSpacing="0.1rem" textShadow="1px 1px 0 #1c1a1a">
                    {kind}
                </Heading>
            </Center>
        </Flex>
    )
}

const SwordsInfo = ({ swords, selectedSword, onSelectSword }) => {
    const [clickedSword, setClickedSword] = React.useState(null)

    // Get Sword Rates (from chain)
    const { rates } = useSwordRates()

    const tab = React.useRef()
    React.useEffect(() => {
        tab.current.scrollTop = -1000 // scroll to top
    }, [clickedSword, swords])

    return (
        <Flex
            id="art"
            ref={tab}
            as="article"
            className="swordsInfo"
            mt={{ base: '1rem', md: '2rem' }}
            justifyContent="space-between"
            align="start"
            gap="2"
            w="88%"
            h="30%"
            direction={{ base: 'column-reverse', md: 'row' }}
            overflowX="hidden"
            overflowY="auto"
        >
            <Text
                as="p"
                width={{ base: '100%', md: '67%' }}
                overflow="display"
                fontSize="0.75rem"
                p="0.5rem"
                mr={{ base: '5px', md: '1rem' }}
                fontWeight="bold"
            >
                Star of bravery has the chance to enhance the sword to higher quality (uncommon, rare, epic, legendary),
                and the quality of the sword will lock the rarity of Mountain you claim at the claiming stage. Each
                enhancement will be an independent event, when you apply multiple stars to a sword, it will generate the
                highest quality you get among all the enhancements. Enhancement will NEVER downgrade the quality of your
                sword.
            </Text>

            <Flex
                as="aside"
                width={{ base: '100%', md: '33%' }}
                overflow="display"
                flexDirection={{ base: 'row', md: 'column' }}
                alignItems="flex-start"
                justifyContent="space-around"
                flexWrap={{ base: 'wrap', md: 'nowrap' }}
                gap={{ base: '1', md: '2.5' }}
                textAlign="center"
                mt="0.5rem"
                mr={{ base: '5px', md: '0px' }}
            >
                {swords
                    .filter((s) => s.quality !== 'COMMON')
                    .map((sword, index) => (
                        <SwordInfoButton
                            isSelected={sword.id === selectedSword?.id ? true : false}
                            kind={sword.quality}
                            color={sword.color}
                            rate={rates[sword.id] ? rates[sword.id] : 0}
                            key={index}
                            className={`exp_${sword.color}`}
                            variant={`exp_${sword.color}`}
                            style={{ color: sword.color }}
                            onMouseOver={() => onSelectSword(sword)}
                            onMouseOut={() => {
                                clickedSword !== sword.color && onSelectSword(null)
                            }}
                            onClick={() => {
                                setClickedSword(sword.color)
                                onSelectSword(sword)
                            }}
                        />
                    ))}
            </Flex>
        </Flex>
    )
}

export default SwordsInfo
