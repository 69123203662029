import { extendTheme } from '@chakra-ui/react'

// Themes
const colors = {
    blue: {
        101: '#b2d5e9',
    },
}

const styles = {
    global: {
        'html, body': {
            fontSize: 'lg',
            color: '#190602',
            // textShadow: '0 0 2px #ed93e8',
            lineHeight: 'tall',
        },
        body: {
            bg: 'gray.900',
            lineHeight: 'base',
        },
        '*::placeholder': {
            color: 'whiteAlpha.400',
        },
        '*, *::before, &::after': {
            borderColor: 'whiteAlpha.300',
            wordWrap: 'break-word',
        },
        '.app': {
            height: '100%',
            textAlign: 'center',
        },
        '.overlay': {
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            position: 'fixed',
            zIndex: '10',
        },
        '.wrapper': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            position: 'fixed',
        },
        '#btn-wallet button': {
            color: '#25292e',
            bg: 'whiteAlpha.800',
        },
        '.preload': {
            display: 'none',
            width: '0px',
            height: '0px',
        },
    },
}

const fonts = { heading: `'Amatic SC', cursive;`, body: `'Josefin Sans', sans-serif` }

export const breakpoints = {
    sm: '30rem',
    md: '48rem',
    lg: '62rem',
    xl: '80rem',
    '2xl': '96em',
}

const customTheme = extendTheme({ styles, fonts, colors, breakpoints })

export default customTheme
