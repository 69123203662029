import { Image, Flex, HStack, chakra, Link, Circle, VisuallyHidden, Box, Tooltip, Spacer } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Logo from '../images/logo.png'
import React from 'react'
import MobileDrawer from './MobileDrawer'
import labels from './data/labels.json'
import { Grow } from '../components/Animations'
import { ReactComponent as OpenSeaIcon } from '../images/svgs/opensea.svg'
import { ReactComponent as TwitterIcon } from '../images/svgs/twitter.svg'
import ImgButton from './ImgButton'
import { useNavis } from '../utils/Environment'

function SocialMedia() {
    return (
        <HStack ml="8px">
            <Tooltip label="DoDoFrens.xyz">
                <Link href={labels.links.opensea} isExternal={true}>
                    <Circle size="40px" _hover={{ animation: Grow }}>
                        <OpenSeaIcon />
                    </Circle>
                    <VisuallyHidden>OpenSea</VisuallyHidden>
                </Link>
            </Tooltip>
            <Tooltip label="@DoDoFrens">
                <Link href={labels.links.twitter} size="40px" isExternal={true}>
                    <Circle size="40px" _hover={{ animation: Grow }}>
                        <TwitterIcon />
                    </Circle>
                    <VisuallyHidden>Twitter</VisuallyHidden>
                </Link>
            </Tooltip>
        </HStack>
    )
}

function HeadingLogo() {
    const navigate = useNavigate()

    return (
        <Flex minWidth="max-content" alignItems="center" gap="2" cursor="pointer" objectFit="cover" m="0.5em">
            <Box onClick={() => navigate('/')}>
                <Image
                    w={['180px', '240px', '200px', '280px', '320px']}
                    src={Logo}
                    draggable={false}
                    userSelect="none"
                />
            </Box>

            <SocialMedia />
        </Flex>
    )
}

function SmallLogo() {
    const navigate = useNavigate()
    return (
        <Flex minWidth="max-content" alignItems="left" cursor="pointer" objectFit="cover">
            <Box onClick={() => navigate('/')} mr="1rem">
                <Image h="45px" src={Logo} draggable={false} userSelect="none" />
            </Box>
        </Flex>
    )
}

export default function Header({ activePath, shortWindowSize, ...props }) {
    const navigate = useNavigate()
    const navis = useNavis()

    const menuNavis = [navis.home, navis.story, navis.faq, navis.wakumba]

    return (
        <chakra.header
            id="header"
            fontFamily=""
            color="green.100"
            // bgGradient="linear(to-r, gray.300, yellow.400, pink.200)"
            {...props}
            w="100%"
            h="0px"
        >
            {shortWindowSize ? (
                <Flex justify="space-between" overflow="visible" m="1rem" h="0px" zIndex={2}>
                    <SmallLogo />
                    <MobileDrawer activePath={activePath} />
                </Flex>
            ) : (
                <Flex
                    w="100%"
                    px="6px"
                    pt={{ base: '2.5rem', xl: '3.5rem' }}
                    align="center"
                    justify="space-evenly"
                    overflow="visible"
                    zIndex="2"
                    h="0px"
                >
                    {/* // Logo */}
                    <HeadingLogo />

                    <Spacer />

                    {/* // Nav Items */}
                    <HStack as="nav" spacing="4" display={{ base: 'none', md: 'flex' }}>
                        {menuNavis.map((item, i) => (
                            <ImgButton
                                key={i}
                                onClick={() => navigate(item.url)}
                                variant={item.variant}
                                h={['', '', '55px', '45px', '60px']}
                                isActive={activePath === item.url}
                            />
                        ))}
                    </HStack>

                    <Spacer />

                    {/* // Call to action items */}
                    <HStack>
                        <ImgButton
                            display={{ base: 'none', md: 'flex' }}
                            h={['', '', '65px', '65px', '65px']}
                            onClick={() => {
                                navigate('/mountain')
                            }}
                            variant="mountainNav"
                            h={['85px']}
                            isActive={activePath === '/mountain'}
                        />
                        <ImgButton
                            display={{ base: 'none', md: 'flex' }}
                            onClick={() => {
                                navigate('/meditation')
                            }}
                            variant="meditation"
                            h={['', '', '65px', '65px', '70px']}
                            isActive={activePath === '/meditation'}
                        />
                        <ImgButton
                            display={{ base: 'none', md: 'flex' }}
                            onClick={() => {
                                navigate('/forge')
                            }}
                            variant="enhance_ani"
                            h={['', '', '100px', '100px', '100px']}
                            paddingBottom="0.5rem"
                            pr={{ base: '0', lg: '3rem', xl: '5rem' }}
                            isActive={activePath === '/forge'}
                        />

                        <MobileDrawer activePath={activePath} display={{ base: 'flex', md: 'none' }} />
                    </HStack>
                </Flex>
            )}
        </chakra.header>
    )
}
