import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Link, Text, useToast } from '@chakra-ui/react'
import { BigNumber, ethers } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { m } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import {
    useBalance,
    useContractEvent,
    useContractRead,
    useContractWrite,
    usePrepareContractWrite,
    useWaitForTransaction,
} from 'wagmi'
import WakumbaBlessContract from '../../contracts/WakumbaBlessContract'
import { handleContractError } from '../../utils/ContractUtils'
import { useEnv } from '../../utils/Environment'

const wakumbaBlessConfig = {
    addressOrName: process.env.REACT_APP_WAKUMBA_BLESS_CONTRACT_ADDR,
    contractInterface: WakumbaBlessContract.abi,
}

/**
 * function airdropFaith(
        address to,
        uint32 qty,
        uint32 maxLimit,
        bytes32 nonce,
        bytes calldata signature
    ) 
 * **/
export const useMintFaith = (props) => {
    const mintFaith = useAirdrop({ method: 'airdropFaith', ...props })
    return { mintFaith }
}

/**
 * function airdropSword(
        address to,
        uint16 qty,
        uint16 maxLimit,
        bytes32 nonce,
        bytes calldata signature
    )
 * **/
export const useMintSword = (props) => {
    const mintSword = useAirdrop({ method: 'airdropSword', ...props })
    return { mintSword }
}

const useAirdrop = ({ method, address, args, onTxSuccess }) => {
    // console.log('Preparing: ', method, args)
    const toast = useToast()
    const waitForTxToastRef = useRef()

    const { config: faithConfig } = usePrepareContractWrite({
        ...wakumbaBlessConfig,
        functionName: method,
        args: args,
    })
    const {
        data: txWriteData,
        isError: isError,
        error: Error,
        isLoading: isLoading,
        isSuccess: isStarted,
        status: Status,
        write,
    } = useContractWrite({
        ...faithConfig,
        onError(error) {
            handleContractError('Wakumbabless Write', error, toast)
        },
    })

    const { isDev, network } = useEnv()

    const {
        isLoading: isTxLoading,
        isSuccess: isTxSuccess,
        isError: isTxError,
    } = useWaitForTransaction({
        confirmations: 1,
        hash: txWriteData?.hash,
        wait: txWriteData?.wait,
        onSuccess(data) {
            console.log('TxSuccess:', data)
            console.log('TxSuccess Logs:', data.logs)
            if (!onTxSuccess) {
                toast({
                    title: `Transaction Success!`,
                    status: 'success',
                    duration: 15_000,
                    isClosable: true,
                })
            } else {
                onTxSuccess()
            }
        },
        onError(error) {
            console.log('TxError:', error)
            const msg = error?.internal?.message || error?.reason
            const [type, reason] = msg.split(': ')
            toast({
                title: type
                    ? `Error: ${type}${reason ? '(' + reason.trim() + ')' : ''}`
                    : `Rejected, please try again later.`,
                status: 'error',
                isClosable: true,
            })
        },
        onSettled(data, error) {
            console.log('TxSettle:', data, error)
            toast({
                title: `Transaction Confirmed!`,
                description: (
                    <Link
                        href={`https://${isDev ? network.chain + '.' : ''}etherscan.io/tx/${txWriteData?.hash}`}
                        isExternal={true}
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        float="left"
                        width="320px"
                    >
                        <ExternalLinkIcon />
                        Tx# {txWriteData?.hash}
                    </Link>
                ),
                position: 'top-right',
                status: 'info',
                isClosable: true,
            })

            if (waitForTxToastRef.current) {
                toast.close(waitForTxToastRef.current)
            }
        },
    })

    useEffect(() => {
        if (isTxLoading) {
            waitForTxToastRef.current = toast({
                title: `Processing transaction, please wait for a while...`,
                status: 'success',
                isClosable: true,
                duration: 15 * 1000,
            })
        }
    }, [isTxLoading])

    // console.log('The write:', write)
    return write
}

export const useGetClaimedFaithQty = ({ address, ...props }) => {
    const faithQty = useReadQtyContract({ method: 'getClaimedFaithQty', address: address, ...props })
    console.log('Get Faith claimed: ', faithQty)
    return { faithQty }
}

export const useGetClaimedSwordQty = ({ address, ...props }) => {
    const swordQty = useReadQtyContract({ method: 'getClaimedSwordQty', address: address, ...props })
    console.log('Get Sword claimed: ', swordQty)
    return { swordQty }
}

const useReadQtyContract = ({ method, address }) => {
    const { data: theQty } = useContractRead({
        ...wakumbaBlessConfig,
        functionName: method,
        watch: true,
        args: [address],
        onSuccess(_data) {
            console.log(`Call ${method}:`, _data)
        },
    })
    return theQty && !isNaN(parseInt('' + theQty)) ? parseInt('' + theQty) : 0
}
