import { chakra } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

export default function InternalLink(props) {
    const { url } = props

    const underline = props.underline === 'false' ? {} : { textDecoration: 'underline' }    

    const navigate = useNavigate()

    return (
        <chakra.span {...underline} cursor="pointer" onClick={() => navigate(url)} {...props}>
            {props.children}
        </chakra.span>
    )
}
