import { React } from 'react'
import { Box, Center, Flex, chakra, Heading, Img, Image } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import DoDoPrayImg from '../../images/backgrounds/dodo-pray.jpg'
import { StoryMap } from '../../components/Backgrounds'
import StoryMobImg from '../../images/backgrounds/story-mob.jpg'

function Text(props) {
    return (
        <chakra.p marginTop="1em" marginBottom="1em">
            {props.children}
        </chakra.p>
    )
}

function ChapterOneOneTitle() {
    return (
        <Box id="chapter-one-one-title" textAlign="center" textShadow="0 0 1px" mb="2em">
            <Heading fontSize={['48px', '51px', '54px', '58px', '65px']}>
                <div>Chapter Ⅰ</div>
                <div>The Courageous Child of the Eternal Land </div>
            </Heading>
        </Box>
    )
}

function ChapterOneOneArticle({ lang = 'en_US', style }) {
    const navigate = useNavigate()

    return (
        <Box as="article" style={style}>
            <Center>
                <Heading fontSize={['25px', '28px', '32px', '35px', '38px']}>Section I</Heading>
            </Center>

            <Text>
                The night before departure, a meerkat bounced and trotted deep into the crypt, and he stood upright on
                his hind legs in front of the Ancestral Totem. This meerkat is called DoDo. He stared at the Ancestral
                Totem without moving. No one knew what he was thinking. Suddenly, the crystal in the middle of the
                Ancestral Totem began to glow with glitter. Then a sonorous voice slowly arose in DoDo's mind.{' '}
            </Text>

            <Center py="0.5em">
                <Img
                    src={DoDoPrayImg}
                    alt="dodo praying..."
                    w={['90%', '80%', '70%', '60%', '50%']}
                    objectFit="contain"
                />
            </Center>

            <Text> “My child, I felt the hesitation in your heart. Tell me, what are you wavering for?” </Text>
            <Text>
                {' '}
                DoDo was taken aback by the sudden voice in his mind, “The… the great Wakumba, I… I didn’t waver. As you
                know, I’m always brave! I’m just…”{' '}
            </Text>
            <Text>
                {' '}
                “Admitting your fear is also a kind of courage, my child.” The Ancestral Totem read the mind of the
                little meerkat.{' '}
            </Text>
            <Text>
                {' '}
                “Well, I… I do get a bit afraid, but I’m not afraid of taking an adventure, as it is what I want. I want
                to explore the outside world! I feel nervous because I have never left the tribe. I don’t know where I
                should go, nor what I will meet, whether friends or enemies. I’m just a bit… at a loss.”{' '}
            </Text>
            <Text>
                {' '}
                “Fear of the unknown is the greatest fear of all,” The Ancestral Totem said, “but you already have the
                best weapon against your fear of the unknown, my child.”
            </Text>
            <Text> “Already have? What’s that?” </Text>
            <Text> “The desire for the unknown.”</Text>
            <Text> It seemed that DoDo didn't fully understand. </Text>
            <Text> “Open the map I gave you before, my child.” DoDo opened the map as he was told.</Text>
            <Text>
                {' '}
                “As you see, my child, what is shown on the map is our world. The land we are on has existed since
                before I had consciousness. Though it is not the largest land, it is the oldest one. Countless creatures
                have appeared and disappeared on it. Even after the forests became deserts and the flat grounds turned
                into high mountains, the land has always existed without any change. In our hearts, it's like a mother,
                and it is named Eternal.”{' '}
            </Text>
            <Text>
                {' '}
                DoDo gazed at the map with his ears moving back and forth, trying to memorize what the Ancestral Totem
                said.{' '}
            </Text>
            <Text>
                {' '}
                “My child, you are passionate, brave, kind, smart, agile, and strong. While you may feel lost about the
                unknown, the desire for adventure and the curiosity about the outside world can take you through the
                fog. So, as far as I can see, you are well prepared.”{' '}
            </Text>
            <Text>
                {' '}
                Although DoDo seemed to have not understood yet, he looked more determined with less confusion.{' '}
            </Text>
            <Text>
                Suddenly, a red dotted line appeared on the map, extending north in a zigzag. At last, it marked a red
                cross in the middle of the Eternal Land with a snap. DoDo was shocked!{' '}
            </Text>
            <Text> “Look, the Ancestor has shown you the way.” </Text>
            <Text>
                {' '}
                DoDo read the map carefully and then closed it. When he was about to leave after bidding farewell to the
                Ancestral Totem, Wakumba’s voice arose again in his mind, “There is something that the Ancestor said you
                may need in the journey ahead.”{' '}
            </Text>
            <Text>
                {' '}
                After that, a few light spots came out from the crystal of the Ancestral Totem, and they floated and
                circled in front of DoDo. Through the light, the outline of that thing was faintly visible, but DoDo
                couldn’t see it. Then the light spots flew to DoDo one by one into his chest.
            </Text>
            <Text>
                {' '}
                DoDo patted himself hard on the chest. However, nothing abnormal happened except a dull ache due to
                overexerting.
            </Text>
            <Text>
                {' '}
                “When the fates meet, the Ancestor will tell you what they are used for. If you get trapped in confusion
                or difficulty in the journey, pray to me, and the wind from the Eternal Mother will bring your prayers
                and take the guidance of the Ancestor back to you. Go ahead, my child.”{' '}
            </Text>
            <Text>
                {' '}
                DoDo took leave of the Ancestral Totem and came out of the crypt. Above the ground, the night was fading
                while the day would come. DoDo packed up his bag and went out of the tribe. The dim light of the early
                glowing sun reflected a little figure and gave him a gold outline.{' '}
            </Text>
            {/* <Center>
                <Flex mt="1.6em" justifyContent="space-evenly" flexWrap="wrap" gap="2">
                    <ImgButton
                        _hover={{ animation: GrowShrink }}
                        onClick={() => navigate('/wakumba')}
                        variant="wakumbaAirdrop"
                        h="70px"
                        isActive={true}
                    />
                </Flex>
            </Center> */}

            <Image
                display={{ base: 'none', md: 'block' }}
                src={StoryMobImg}
                position="absolute"
                objectFit="initial"
                left="0"
                top={['', '', '-170px', '-120px', '-80px']}
                h="105%"
                w="100%"
                zIndex="-2"
            ></Image>

            <StoryMap display={{ base: 'block', md: 'none' }} />
        </Box>
    )
}

export { ChapterOneOneTitle, ChapterOneOneArticle }
