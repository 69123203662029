import {
    Center,
    Img,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
} from '@chakra-ui/react'
import { React } from 'react'
import BtnClaimSwordDisabled from '../../images/buttons/claimSword.png'
import faqs from '../../components/data/faqs.json'
import TimeTipText from '../../components/TimeTipText'

export default function StagePreChapterOne() {
    return (
        <Center>
            <Popover placement="top">
                <PopoverTrigger>
                    <Img
                        w={['270px', '400px']}
                        src={BtnClaimSwordDisabled}
                        alt="BtnClaimSwordDisabled"
                        cursor="pointer"
                    />
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader />
                    <PopoverBody>
                        <>
                            <TimeTipText
                                prefix="Airdrop event of Chapter One will start at"
                                dateStr={faqs.airdropDate}
                                suffix=", after the snapshot was taken."
                            ></TimeTipText>
                        </>
                    </PopoverBody>
                    <PopoverBody>
                        <TimeTipText prefix="Snapshot time is" dateStr={faqs.snapshotDate} suffix=""></TimeTipText>
                    </PopoverBody>
                    <PopoverFooter />
                </PopoverContent>
            </Popover>
        </Center>
    )
}
