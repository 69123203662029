import { React } from 'react'
import { Box, Center, Flex, chakra, Heading, Img, Image } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import DesertImg from '../../images/backgrounds/story-1-2-desert.jpg'
import MyFrenImg from '../../images/backgrounds/story-1-2-myfren.jpg'

import { StoryMap } from '../../components/Backgrounds'
import StoryMobImg from '../../images/backgrounds/story-mob.jpg'

function Text(props) {
    return (
        <chakra.p marginTop="1em" marginBottom="1em">
            {props.children}
        </chakra.p>
    )
}

function ChapterOneTwoTitle() {
    return (
        <Box id="chapter-one-two-title" textAlign="center" textShadow="0 0 1px" mb="2em">
            <Heading fontSize={['48px', '51px', '54px', '58px', '65px']}>
                <div>Chapter Ⅰ</div>
                <div>The Courageous Child of the Eternal Land </div>
            </Heading>
        </Box>
    )
}

function ChapterOneTwoArticle({ lang = 'en_US', style }) {
    const navigate = useNavigate()

    return (
        <Box as="article" style={style}>
            <Center>
                <Heading fontSize={['25px', '28px', '32px', '35px', '38px']}>Section II</Heading>
            </Center>

            <Text>
                After leaving the tribe, DoDo went all the way north with the surrounding sparse vegetation gradually
                disappearing and the hard ground transforming into fine sand. Finally, he arrived at the endless desert.
                According to the elders in the tribe, this vast desert was called the Kalahari Desert, which meant a
                waterless place.
            </Text>
            <Text>
                On the edge of the desert, DoDo stood up on his hind legs and looked around. Then he put down his front
                legs and trotted into the desert.{' '}
            </Text>

            <Text>
                The desert seemed calm, but it was full of danger as there were not only scary creatures that could pose
                threats to DoDo's safety, like all kinds of vipers and medium-sized lizards but also inconspicuous
                quicksand and sandstorms that would arrive suddenly. Moreover, even the burning sun and high temperature
                in the daytime could be challenging for DoDo. The desert seemed barren, but it nurtured countless lives,
                most of which were edible for DoDo, such as gerbils, scorpions, and small lizards.{' '}
            </Text>
            <Text>
                “It seems that I have to wait until the night,” DoDo thought, “Wakumba asked me to look at the stars
                that align as a cross when feeling lost. If it points south, then I should go the opposite way.
                Furthermore, I can get out of the damn sun at night as I can't stand the scorching heat.”{' '}
            </Text>
            <Text>
                Thus, DoDo rested during the day and moved at night. Since he was a master hunter in the tribe, it was a
                cinch to catch some bugs and rats to feed himself. Besides, there was a kind of plant in the desert
                resistant to drought and heat. With its roots growing extremely deep, it could absorb nutrients from the
                deep moist sand, which was one of the main water sources in the desert for DoDo.
            </Text>

            <Center py="0.5em">
                <Img
                    src={DesertImg}
                    alt="dodo praying..."
                    w={['90%', '80%', '70%', '60%', '50%']}
                    objectFit="contain"
                />
            </Center>

            <Text>
                On the way to the north, DoDo had not only experienced the magnificent beauty of the desert but also
                encountered crises, such as a big thrilling sandstorm! Luckily, in the end, DoDo could always get
                through them with his intelligence and agility. After some time, DoDo found that the surrounding
                vegetation had gradually increased, and the desert became a grassland with sparse vegetation. “I think
                I'm approaching the edge of the desert.” DoDo thought.{' '}
            </Text>

            <Text>
                One day in the dusk, DoDo crawled out of the temporary burrow he dug. “Goo...” DoDo's stomach rumbled
                again! Just then, DoDo keenly discovered a fat gerbil! The gerbil was rummaging around in the nearby
                grass, with its back to DoDo, as if it were also looking for some food. “What a fat gerbil! I should be
                full if I get it!” After wiping up his saliva, DoDo crept up toward the gerbil, as hunting without
                making a sound was the essential skill for a great hunter! The gerbil didn't notice DoDo and kept
                looking for food for itself while DoDo followed it slowly forward. With the distance between them
                shortening to 20 and then 15 steps, DoDo was about to speed up. In the 10-step distance, DoDo had never
                missed!{' '}
            </Text>
            <Text>
                “Roar! You... vile... scums!” Suddenly, with a terrible growl from ahead, the gerbil got frightened and
                fled into a burrow in the nearby grass. DoDo was stunned by this accident, and then he sighed, “I almost
                got the gerbil.” DoDo thought, “Something must have happened ahead. Maybe someone needs help. I should
                go over and check! ”
            </Text>
            <Text>
                DoDo trotted toward where the growl came from. After passing through a clump of plants, DoDo found a
                clearing, where a silverback gorilla was surrounded by a pack of spotted hyenas. Among them, a hind leg
                of the gorilla was caught in a big wooden clamp with bleeding. The spotted hyenas were making a probing
                attack on the silverback gorilla, who could only try to drive away the approaching enemies by waving his
                fists and a branch he picked up temporarily.{' '}
            </Text>
            <Text>
                “Boss, how could an injured gorilla be so tough? Luckily we set a trap in advance!” One of the spotted
                hyenas said to the other one with a scar on his left eye. “Humph, if we hadn't been informed of his
                schedule from those in the northern forest, he would not have fallen into our trap so easily. It's hard
                to catch a silverback gorilla as they always live in the northern forest. I heard the silverback
                gorillas are great tastes with a perfect mixture of fat and lean. Look at his thigh. I can't stop
                drooling!” Said the spotted hyena with a scar, who seemed to be the leader, “Come on, bros! This
                silverback gorilla will be our dinner for tonight! He is so fat that will be enough to supply us for at
                least 2... No! 3 days!” Other spotted hyenas got excited and began to attack the gorilla more
                frequently, and two of them focused on his back, keeping an eye on his butt. It was well known that the
                anus was one of the most common attacking points for spotted hyenas, through which they could quickly
                immobilize the prey.
            </Text>
            <Text>
                “You vile scums, treacherous villains! I never thought the Chief would ally with you to kill me! Even if
                I have to die here today, I'll take you along!” The silverback gorilla growled and waved his fists and
                the branch more violently to fend off the approaching spotted hyenas. Because of anger, the gorilla
                moved more and more fiercely, but meanwhile, more and more of his weak points got exposed. A spotted
                hyena seized the moment, made a dive and a leap, and exerted himself to bite a piece of meat off the
                gorilla's left arm. The gorilla growled with the pain. At this time, another spotted hyena rushed to
                him, only to be hit in the stomach by the branch the gorilla waved in his right hand and fell off,
                knocking over the other two spotted hyenas, twitching on the ground with a whimper, and being unable to
                get up. Startled by the gorilla's strength, other spotted hyenas took a few steps back from him until he
                couldn't reach them.
            </Text>
            <Text>
                “It’s terrible. I have to help him!” In the dusk of the twilight, DoDo slipped through the gap between
                the spotted hyenas and rushed to the feet of the silverback gorilla as fast as he could. Frightened by
                the shadow that suddenly appeared at his feet, the gorilla was about to hit it with the branch, and then
                he heard, "Don't hit me! I'm here to help you!". After a closer look, the gorilla found a little meerkat
                at his feet, shielded by his thick hind legs and long hair.{' '}
            </Text>
            <Text>
                “Boss, I felt something passing through just now, did you?” One of the spotted hyenas asked. “I didn't
                notice. Forget it. It won’t be a problem.” Said the scar-eyed spotted hyena.{' '}
            </Text>
            <Text>
                “Help me? How?” The silver gorilla panted, “You'd better go now. They are after me. You don't have to
                risk your life.”{' '}
            </Text>
            <Text>
                DoDo acted as if he hadn't heard it by quickly running to the gorilla’s foot that was caught in the trap
                and observing it closely. “This should be what's keeping the big guy trapped, sort of like the traps
                used for hunting gerbils in our tribe.” DoDo thought. After walking around the trap for 2 circles, DoDo
                found something. “The trap seems to be linked by this thin stick. If I break it, the big guy should be
                free.” Then DoDo tried to break the stick, but no matter how much effort he took, whether by pulling or
                biting, there was no way he could break it.{' '}
            </Text>
            <Text>
                As the spotted hyenas were approaching again, there was little time left for DoDo. The gorilla looked at
                DoDo at his feet, who was still trying to destroy the trap, and he sighed, ready to wave his fists again
                for the final fight against the spotted hyenas.{' '}
            </Text>
            <Text>
                “For Wakumba's sake, break this stick!” DoDo felt more and more anxious. At this moment, a light spot
                came out of DoDo's chest, which circled and fell to DoDo's hand. DoDo felt there was something in his
                hand, and he found a small sword with a glimmer! Having no time to check it, DoDo cut the stick with the
                sword! The stick broke with a snap, and the trap split!{' '}
            </Text>
            <Text>
                “Big guy! You can move now! Beat them! Beat them up!” Since spotted hyenas were notorious on the
                savannah and had killed many of DoDo's people, DoDo surely didn't like them either.{' '}
            </Text>
            <Text>
                “Roar!” Finding himself free from the trap, the silverback gorilla beat his chest with his fists and
                growled, “You bastards, it's time to taste the big trunk!” Although it was just a thin branch on the
                gorilla's hand, which had nothing to do with the trunk, he brandished it with great momentum.{' '}
            </Text>
            <Text>
                The spotted hyenas surrounded had no time to react, immediately being stricken down by the gorilla with
                his fists and the branch.{' '}
            </Text>

            <Center py="0.5em">
                <Img
                    src={MyFrenImg}
                    alt="dodo praying..."
                    w={['90%', '80%', '70%', '60%', '50%']}
                    objectFit="contain"
                />
            </Center>

            <Text>
                “Bo....bo..bo..bo...BOSS, this guy can move now. Our trap is broken!” One of the spotted hyenas said to
                the scar-eyed boss.{' '}
            </Text>
            <Text>
                The scar-eye boss looked around, only to find just a few partners that could stand up, and he said: “It
                must be the little rat running through just now. Our gorilla feast has gone up the spout. Let's go now.
                Otherwise, we won't have the chance even for other food!”{' '}
            </Text>
            <Text>
                Then he took a few steps back before turning and running away, and the rest gave each other a sign,
                fleeing following him.{' '}
            </Text>
            <Text>
                The silverback gorilla kept his eyes on them until they disappeared, and he suddenly sat down on the
                ground, breathing heavily.{' '}
            </Text>
            <Text>“Little guy, what’s your name?”</Text>
            <Text>“My name is DoDo. I come from my tribe. I’m an adventurer!” </Text>
            <Text>
                “DoDo? It’s a weird name. My name is Mountain, which means I have the same courage and strength as
                mountains!”{' '}
            </Text>
            <Text>
                The gorilla poked DoDo's little chest with a finger, and then he beat his chest on where his heart was,
                looking at DoDo, exhausted but determined.{' '}
            </Text>
            <Text>
                Obviously, this gorilla was not good at expression, and it was hard for him to say thanks directly.
                However, he had expressed everything he wanted with this series of movements.{' '}
            </Text>
            <Text>
                DoDo nearly fell by the big finger of Mountain. Seeing his movements, DoDo stared at Mountain with a
                giggle.{' '}
            </Text>
            <Text>
                At this time, DoDo thought of something. He took out several herbs from his bag, chewed them in his
                mouth, spit them out, and applied them on Mountain's wounds.{' '}
            </Text>
            <Text>
                The gorilla shivered due to the pain. “Don't move. Why are you afraid of pain if you are such a big guy?
                These are the herbs our people used for stopping the bleeding. You will be better after applying it!”
                Hearing this, Mountain stopped moving after a snort.{' '}
            </Text>
            <Text>
                After applying the herbs, Mountain rested for a while. Then he scooped up DoDo, put him on his
                shoulders, and he said, “Let's get out of here and find a safer place to rest.”{' '}
            </Text>
            <Text>
                Under the moonlight, a silverback gorilla walked toward the rainforest with a little yellow meerkat on
                his shoulders.{' '}
            </Text>

            <Image
                display={{ base: 'none', xl: 'block' }}
                src={StoryMobImg}
                position="absolute"
                objectFit="initial"
                left="0"
                top={{ base: '', xl: '-290px', '2xl': '-250px' }}
                h="110%"
                w="100%"
                zIndex="-2"
            ></Image>

            <StoryMap display={{ base: 'block', xl: 'none' }} />
        </Box>
    )
}

export { ChapterOneTwoTitle, ChapterOneTwoArticle }
