import {
    Center,
    Flex,
    Image,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
} from '@chakra-ui/react'
import { useAccount } from 'wagmi'
import { MountainMap } from '../../components/Backgrounds'
import { BtnConnect } from '../../components/BtnConnect'
import MountainPage from './MountainPage'
import useSiteStage from '../hooks/SiteStage'
import faqs from '../../components/data/faqs.json'
import TimeTipText from '../../components/TimeTipText'
import CenterLayout from '../layouts/CenterLayout'
import React from 'react'
import ImgButton from '../../components/ImgButton'
import MountainFreeMintPage from './MountainFreeMintPage'

const Mountain = () => {
    const [open, setOpen] = React.useState(false)
    const { address, isConnected } = useAccount({
        onConnect() {
            console.log('Connected:', address)
        },
        onDisconnect() {
            console.log('Disconnected:', address)
        },
    })

    const [stage] = useSiteStage({
        address: address,
        isConnected: isConnected,
    })

    if (stage < 12) {
        return (
            <CenterLayout>
                <Popover placement="top">
                    <PopoverTrigger>
                        <Flex>
                            <ImgButton variant="mountainConnect" w={['250px', '290px', '320px', '350px']} />
                        </Flex>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader />
                        <PopoverBody>
                            <>
                                <TimeTipText
                                    prefix="Summon event of Mountain NFT will start at"
                                    dateStr={faqs.mountainDate}
                                    suffix=""
                                ></TimeTipText>
                            </>
                        </PopoverBody>
                        <PopoverFooter />
                    </PopoverContent>
                </Popover>
                <MountainMap id="background" />
            </CenterLayout>
        )
    } else if (!isConnected) {
        return (
            <CenterLayout>
                <BtnConnect w={['250px', '290px', '320px', '350px']} variant="mountainConnect" />
                <MountainMap id="background" />
            </CenterLayout>
        )
    } else if (stage === 12) {
        //user must click button to enter even wallet is connected
        return open ? (
            <MountainPage />
        ) : (
            <CenterLayout>
                <ImgButton
                    w={['250px', '290px', '320px', '350px']}
                    variant="mountainConnect"
                    onClick={() => setOpen(true)}
                />
                <MountainMap id="background" />
            </CenterLayout>
        )
    } else {
        return <MountainFreeMintPage />
    }
}

export default Mountain
