import React from 'react'
import { Img, Center } from '@chakra-ui/react'
import ongoing_sword_white from './assets/mint/ongoing_sword_white.png'
import ongoing_sword_green from './assets/mint/ongoing_sword_green.png'
import ongoing_sword_blue from './assets/mint/ongoing_sword_blue.png'
import ongoing_sword_purple from './assets/mint/ongoing_sword_purple.png'
import ongoing_sword_orange from './assets/mint/ongoing_sword_orange.png'
import ongoing_mountain from './assets/mint/ongoing_mountain.png'

const bgMaps = {
    ongoing_sword_white: ongoing_sword_white,
    ongoing_sword_green: ongoing_sword_green,
    ongoing_sword_blue: ongoing_sword_blue,
    ongoing_sword_purple: ongoing_sword_purple,
    ongoing_sword_orange: ongoing_sword_orange,
}
const SummonAnimation = ({ sword, ...props }) => {
    const styleWrapper1 = {
        '@keyframes fg11': {
            from: { transform: 'rotateY(0deg)' },
            to: { transform: 'rotateY(180deg)' },
        },
        '@keyframes fg12': {
            '0%': { opacity: 1 },
            '50%': { opacity: 0 },
            '100%': { opacity: 1 },
        },
        animation: 'fg11 3s infinite cubic-bezier(.8,.5,.2,.5),fg12 6s infinite step-end',
    }

    const styleWrapper2 = {
        '@keyframes fg21': {
            from: { transform: 'rotateY(180deg)' },
            to: { transform: 'rotateY(0deg)' },
        },
        '@keyframes fg22': {
            '0%': { opacity: 0 },
            '50%': { opacity: 1 },
            '100%': { opacity: 0 },
        },
        animation: 'fg21 3s infinite cubic-bezier(.8,.5,.2,.5),fg22 6s infinite step-end',
    }

    const styleA = {
        '@keyframes fga': {
            '0%': { opacity: 0 },
            '25%': { opacity: 1 },
            '50%': { opacity: 1 },
            '75%': { opacity: 0 },
            '100%': { opacity: 0 },
        },
        animation: 'fga 6s infinite step-end',
    }

    const styleB = {
        position: 'absolute',
        '@keyframes fgb': {
            '0%': { opacity: 1 },
            '25%': { opacity: 0 },
            '50%': { opacity: 0 },
            '75%': { opacity: 1 },
            '100%': { opacity: 1 },
        },
        animation: 'fgb 6s infinite step-end',
    }

    return (
        <Center>
            <Center sx={styleWrapper1} {...props} position="absolute">
                <Img src={ongoing_mountain} sx={styleA} position="absolute" />
                <Img src={bgMaps[`ongoing_sword_${sword.color ? sword.color : 'purple'}`]} sx={styleB} />
            </Center>
            <Center sx={styleWrapper2} {...props} position="relative">
                <Img src={ongoing_mountain} sx={styleA} position="absolute" />
                <Img src={bgMaps[`ongoing_sword_${sword.color ? sword.color : 'purple'}`]} sx={styleB} />
            </Center>
        </Center>
    )
}

export default SummonAnimation
