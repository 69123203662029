import React from 'react'
import {
    Box,
    Flex,
    Img,
    Link,
    Text,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
} from '@chakra-ui/react'
import { Link as ReactLink, useNavigate } from 'react-router-dom'
import ImgButton from '../../components/ImgButton'
import bgModal from '../../images/buttons/forge/popup-base/bg.png'
import SwordsInfo from './SwordsInfo'
import star from './assets/star.png'
import { useDodoStoreContext } from '../../contexts/DodoStoreProvider'
import { useEnv, useLinks } from '../../utils/Environment'
import { ArrowLeftIcon, ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons'
import { useFaithBalance } from './ForgeContracts'
import { useAccount } from 'wagmi'

const Workbench = ({ swords, starAmount, onBuyStar, onApplyStar, selectedSword, setSelectedSword }) => {
    const { isMobile, stage } = useDodoStoreContext()

    // event handlers
    const onSelectSword = (swordItem) => {
        setSelectedSword(swordItem)
    }

    const { isDev, network, contracts } = useEnv()
    const { address } = useAccount()
    const { balance: faithBalance } = useFaithBalance({ address: address })
    const navigate = useNavigate()
    const { openSeaItemUrl } = useLinks()

    return (
        <>
            <Flex
                className="modal"
                direction="column"
                justify="flex-start"
                align="center"
                w={{ base: '100%%', md: '900px' }}
                h={{ base: '500px', md: '600px' }}
                backgroundImage={bgModal}
                backgroundAttachment="local"
                backgroundPosition="center"
                backgroundSize={{ base: '100% 500px', md: '106%' }}
                backgroundRepeat="no-repeat"
                padding={'1rem'}
            >
                <Flex
                    className="actionTop"
                    align="center"
                    justify="space-between"
                    w="100%"
                    marginTop={{ base: '30px', md: '25px' }}
                    pr={{ base: '5px', sm: '5%', md: '30px' }}
                >
                    <Flex align="center" pl={{ base: '20px', sm: '3%', md: '5%' }}>
                        <Box
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            <ArrowLeftIcon mr="10px" cursor="pointer" />
                        </Box>

                        <Popover
                            placement="bottom"
                            autoFocus
                            trigger="hover"
                            defaultIsOpen={(() => {
                                const fromPage = window.location.href.split('from=')?.[1]
                                return 'wakumba' === fromPage
                            })()}
                        >
                            <PopoverTrigger>
                                <Box>
                                    <ImgButton
                                        cursor="pointer"
                                        noMargin
                                        variant={'faithFull'}
                                        h={{ base: '1.5rem', md: '2.2rem' }}
                                        title="Faith In Wallet"
                                        // onClick={() => navigate('/meditation')}
                                    ></ImgButton>
                                </Box>
                            </PopoverTrigger>

                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>The Faith Token</PopoverHeader>
                                <PopoverBody>
                                    <Box m="0.5rem">
                                        1. The Faith Token is an essential resource for DoDo and his Frens to interact
                                        with{' '}
                                        <Link href="/story" textDecor="underline">
                                            {' '}
                                            the Storyline
                                        </Link>
                                        <br />
                                        2. Faith Token majorly comes from{' '}
                                        <Link href="/meditation" textDecor="underline">
                                            {' '}
                                            DoDo's Meditation activity
                                        </Link>{' '}
                                        and has a fixed releasing rate
                                        <br />
                                        3. You can also gain Faith Token through{' '}
                                        <Link href="/wakumba" textDecor="underline">
                                            {' '}
                                            Campaigns or Invitations
                                        </Link>
                                        <br />
                                    </Box>
                                </PopoverBody>
                                <PopoverFooter>
                                    <Link
                                        href={`${openSeaItemUrl}${contracts.dodo}/0`}
                                        isExternal
                                        onClick={() => {
                                            address &&
                                                fetch(`/api/log/${address}/buyDoDoOnOpensea`)
                                                    .then((_msg) => _msg.json())
                                                    .catch((err) => {
                                                        console.log('Logging Failed: ', err)
                                                    })
                                        }}
                                    >
                                        View DoDoFrens on <ExternalLinkIcon></ExternalLinkIcon>
                                        <Box as="span" textDecor="underline">
                                            Opensea
                                        </Box>
                                    </Link>
                                </PopoverFooter>
                            </PopoverContent>
                        </Popover>

                        <Text
                            _hover={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            }}
                            onClick={() => navigate('/meditation')}
                            fontSize={{ base: '1rem', md: '1.5rem' }}
                        >
                            x{faithBalance}
                        </Text>

                        {stage === 11 && faithBalance < 2000 && (
                            <Flex
                                ml="0.3rem"
                                w={{ base: '6rem', md: '7rem' }}
                                _hover={{ color: 'white', background: '#3182ce', cursor: 'pointer' }}
                                onClick={() => navigate('/wakumba')}
                            >
                                <Text fontSize={{ base: '0.5rem', md: '0.7rem' }}>
                                    <InfoIcon fontSize="0.75rem" color="#3182ce" mr="0.5em" />
                                    Need more Faith?
                                </Text>
                            </Flex>
                        )}
                    </Flex>

                    <Flex align="center">
                        <Flex align="center">
                            <Popover
                                placement="bottom"
                                autoFocus
                                trigger="hover"
                                defaultIsOpen={(() => {
                                    const fromPage = window.location.href.split('from=')?.[1]
                                    return 'faq' === fromPage
                                })()}
                            >
                                <PopoverTrigger>
                                    <Flex>
                                        <Img
                                            src={star}
                                            h={{ base: '1.5rem', md: '2.2rem' }}
                                            title="StarOfBravery"
                                            cursor="pointer"
                                            // onClick={onBuyStar}
                                        ></Img>

                                        <Text
                                            _hover={{
                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                            }}
                                            // onClick={onBuyStar}
                                            fontSize={{ base: '1rem', md: '1.5rem' }}
                                        >
                                            x{starAmount}
                                        </Text>
                                    </Flex>
                                </PopoverTrigger>

                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverHeader>The Stars Of Bravery</PopoverHeader>
                                    <PopoverBody>
                                        <Box m="0.5rem">
                                            1. The Stars Of Bravery is used to enhance your Swords (click{' '}
                                            <Box as="span" textDecor="underline" onClick={onBuyStar}>
                                                buy-now
                                            </Box>{' '}
                                            button to convert your Faith into Stars first)
                                            <br />
                                            2. All Swords have the same chances to upgrade to the next level
                                            <br />
                                            3. Your upgraded Swords can lock the rarity of the next NFT collection (
                                            <Link href="/story#mTag" textDecor="underline">
                                                Mountain
                                            </Link>
                                            ) in prior
                                            <br />
                                        </Box>
                                    </PopoverBody>
                                    <PopoverFooter>
                                        <Link
                                            href={`${openSeaItemUrl}${contracts.sword}/0`}
                                            isExternal
                                            onClick={() => {
                                                address &&
                                                    fetch(`/api/log/${address}/buyOnOpensea`)
                                                        .then((_msg) => _msg.json())
                                                        .catch((err) => {
                                                            console.log('Logging Failed: ', err)
                                                        })
                                            }}
                                        >
                                            View Swords on <ExternalLinkIcon />
                                            <Box as="span" textDecor="underline">
                                                Opensea
                                            </Box>
                                        </Link>
                                    </PopoverFooter>
                                </PopoverContent>
                            </Popover>
                        </Flex>
                        <ImgButton
                            disabled={stage >= 12}
                            h={{ base: '1.5rem', md: '1.8rem' }}
                            variant={'buyStar'}
                            onClick={onBuyStar}
                        />
                    </Flex>
                </Flex>

                <Flex
                    as="article"
                    direction="column"
                    justify="center"
                    align="center"
                    h={{ base: '200px', md: '255px' }}
                    w={{ base: '90%', md: '70%' }}
                    mt="0.5rem"
                >
                    <Flex className="swords-container" justify="space-evenly" align="" gap="1" w="100%" h="90%">
                        {swords.map((sword, index) => (
                            <Flex
                                className="sword-box"
                                direction="column"
                                justify="flex-start"
                                align="center"
                                key={index}
                            >
                                <Box h="90%">
                                    <ImgButton
                                        h="100%"
                                        noMargin
                                        variant={`sword_${sword.color}`}
                                        isActive={selectedSword && sword.id === selectedSword.id}
                                        onClick={() => onSelectSword(sword)}
                                    />
                                </Box>
                                <Flex
                                    className="count-box"
                                    h="10%"
                                    justify="center"
                                    align="center"
                                    color="white"
                                    cursor="pointer"
                                >
                                    <Box
                                        verticalAlign="top"
                                        title="View on OpenSea"
                                        _hover={{ textDecoration: 'underline' }}
                                        onClick={() =>
                                            window.open(
                                                `https://${isDev ? 'testnets.' : ''}opensea.io/assets/${network.name}/${
                                                    contracts.sword
                                                }/${sword.id}`
                                            )
                                        }
                                    >
                                        {sword.amount}
                                    </Box>
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>
                    <Flex
                        className="apply-star-box"
                        mt={{ base: '0.5rem', md: '1rem' }}
                        h="10%"
                        w="100%"
                        justifyContent="center"
                        align="center"
                        position="relative"
                    >
                        {stage >= 12 ? (
                            <>
                                {' '}
                                <ImgButton
                                    disabled={true}
                                    h={{ base: '1.5rem', md: '1.8rem' }}
                                    variant="apply_star"
                                    onClick={onApplyStar}
                                />
                                ||
                                <ImgButton
                                    h={{ base: '2rem', md: '2.2rem' }}
                                    variant="mountainConnect"
                                    onClick={() => navigate('/mountain')}
                                />{' '}
                            </>
                        ) : (
                            <ImgButton
                                h={{ base: '1.5rem', md: '1.8rem' }}
                                variant="apply_star"
                                onClick={onApplyStar}
                            />
                        )}
                    </Flex>
                </Flex>

                <SwordsInfo swords={swords} selectedSword={selectedSword} onSelectSword={onSelectSword} />
            </Flex>
        </>
    )
}

export default Workbench
