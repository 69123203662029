import { Center, Flex, Img, SliderFilledTrack, Text, SliderThumb, SliderTrack, Slider, Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'
import ImgButton from '../../components/ImgButton'
import bgDialogue from './assets/bgDialogue.png'
import { useFaithBalance, useFaithToStarContract } from './ForgeContracts'

import faithImg from '../../images/buttons/forge/popup-base/faith.png'
import arrowImg from './assets/arrowRight.png'
import { Link, useNavigate } from 'react-router-dom'
import { InfoIcon } from '@chakra-ui/icons'
import { useDodoStoreContext } from '../../contexts/DodoStoreProvider'

//variables
const FAITH_STAR_RATIO = 2000 // TODO use chain data

export default function ModalBuyStar({ debug, onOpen, onClose }) {
    const { address } = useAccount()
    const { stage } = useDodoStoreContext()
    // 3. Get Faith Balance
    const { balance: faithBalance } = useFaithBalance({ address: address })
    const faithAmount = debug ? 10000 : faithBalance

    const maxStarToBuy = debug ? 10 : faithAmount ? Math.floor(faithAmount / FAITH_STAR_RATIO) : 0
    const [starToBuyAmount, setStarToBuyAmount] = useState(maxStarToBuy)

    useEffect(() => {
        const _maxStarToBuy = faithAmount ? Math.floor(faithAmount / FAITH_STAR_RATIO) : 0
        setStarToBuyAmount(_maxStarToBuy)
    }, [faithAmount])

    //methods
    const onSlide = (value) => {
        setStarToBuyAmount(value)
    }

    // ============== Contract: Faith to StarOfBravery ==============
    const faithToStar = useFaithToStarContract({ address: address, args: [starToBuyAmount] })

    const onBuyStar = (starToBuyAmount) => {
        console.log('onBuyStar: ', starToBuyAmount)
        faithToStar?.()
    }

    const navigate = useNavigate()

    return (
        <Flex
            backgroundImage={bgDialogue}
            backgroundSize="100% 100%"
            backgroundAttachment="local"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            h="500px"
            w="600px"
            padding={['25%', '25%', '13%']}
            flexDirection="column"
            alignItems="center"
            justifyContent={{ base: 'space-around', md: 'flex-start' }}
            gap="3"
        >
            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                justifyContent={{ base: 'space-around', md: 'flex-end' }}
                // alignItems="center"
                align="flex-start"
                wrap="nowrap"
                h="30%"
                gap="3"
                position="relative"
            >
                <Flex align="center" justifyContent="flex-start" h="50px">
                    <ImgButton title="Faith" variant="faithFull" w="45px" onClick={() => navigate('/meditation')} />
                    <Text title="Faith" fontSize="2rem" onClick={() => navigate('/meditation')} cursor="pointer">
                        {faithAmount}
                    </Text>
                </Flex>

                <Flex align="center" justifyContent="flex-end" h="50px">
                    <Text fontSize="100%">{FAITH_STAR_RATIO}</Text>
                    <ImgButton w={['1rem', '1rem', '7%']} variant="faith" title="Faith" />
                    <Text fontSize="100%">=1</Text>
                    <ImgButton w={['1rem', '1rem', '7%']} variant="star" title="StarOfBravery" />
                </Flex>
            </Flex>

            <Flex h="20%" mt="3%" justify="space-evenly" alignItems="center" w="80%">
                <Text fontSize={{ base: '1.2rem', md: '1.5rem' }}>{starToBuyAmount * FAITH_STAR_RATIO}</Text>
                <ImgButton minW="35px" w="35px" variant="faith" title="Faith" />

                <Img src={arrowImg} w="2em" m="1em" />

                <Text fontSize={{ base: '1.2rem', md: '1.5rem' }}>{starToBuyAmount}</Text>
                <ImgButton minW="35px" w="35px" variant="star" title="StarOfBravery" />
            </Flex>

            <Flex className="slider" w="100%" px="10%" mt="8%">
                <Slider
                    defaultValue={maxStarToBuy}
                    max={maxStarToBuy}
                    min={0}
                    value={starToBuyAmount}
                    onChange={onSlide}
                >
                    <SliderTrack>
                        <SliderFilledTrack bg="linear-gradient(to right, #d55800,yellow)" />
                    </SliderTrack>

                    <SliderThumb
                        boxSize={6}
                        bg="transparent"
                        border="none"
                        style={{
                            boxShadow: 'none',
                            '&:focus': { boxShadow: 'none', transform: 'scale(1.2)' },
                        }}
                    >
                        <Img src={faithImg} />
                    </SliderThumb>
                </Slider>
            </Flex>

            <Flex justifyContent="space-around" h="10%" mt="0px">
                <ImgButton
                    h="100%"
                    my="0px"
                    fontSize="100%"
                    variant="ok"
                    onClick={() => onBuyStar(starToBuyAmount)}
                    disabled={!faithToStar}
                />
                <ImgButton h="100%" my="0px" fontSize="100%" variant="cancel" onClick={() => onClose()} />
            </Flex>
        </Flex>
    )
}
