import { useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

const CURRENT_STAGE_DFAULT = 12

export default function useSiteStage({ address, isConnected, onStageChanged = (a, b) => {} }) {
    const [stage, setStage] = useState(CURRENT_STAGE_DFAULT)

    const toast = useToast()

    function fetchStage() {
        fetch('/api/stages/current')
            .then((_msg) => _msg.json())
            .then((_data) => {
                const prevStage = stage
                setStage(_data.stage)
                localStorage.setItem('CURRENT_SERVER_STAGE', JSON.stringify({ stage: _data.stage }))
                console.log('Local Storage: ', JSON.parse(localStorage.getItem('CURRENT_SERVER_STAGE')))
                console.log('Get Stage: ', _data.stage)
                return prevStage
            })
            .then((prevStage) => {
                if (stage !== prevStage) {
                    onStageChanged(prevStage, stage)
                }
            })
            .catch((err) => {
                console.log('Fetch Stage Failed: ', err)
                setStage(CURRENT_STAGE_DFAULT)
                toast({
                    title: `Unstable Network, new requests may fail`,
                    status: 'error',
                    isClosable: true,
                })
            })
    }

    useEffect(() => {
        fetchStage()
    }, [])

    useEffect(() => {
        const intervalCall = setInterval(() => {
            if (isConnected) {
                fetchStage()
            }
        }, 30 * 1000)
        return () => {
            clearInterval(intervalCall)
        }
    }, [address, isConnected])

    return [stage, setStage]
}

export { CURRENT_STAGE_DFAULT }
