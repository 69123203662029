import { useEffect, useState } from 'react'
import { Flex, Heading, Button, Stack, chakra, Box, Avatar, ChakraProvider } from '@chakra-ui/react'
import { BtnConnect, BtnWallet } from '../components/BtnConnect'
import { useAccount, useNetwork, useSignMessage } from 'wagmi'
import { SiweMessage } from 'siwe'

const Auth = () => {
    const [nonce, setNonce] = useState('')
    const [error, setError] = useState({})
    const [login, setLogin] = useState({})
    const [loading, setLoading] = useState(false)

    const fetchNonce = async () => {
        try {
            const nonceRes = await fetch('/api/nonce', { credentials: 'include' })
            const nonce = await nonceRes.text()
            setNonce(nonce)
        } catch (error) {
            setError(error)
        }
    }

    useEffect(() => {
        fetchNonce()
    }, [])

    const { address } = useAccount()
    const { chain: activeChain } = useNetwork()
    const { signMessageAsync } = useSignMessage()

    const signIn = async () => {
        try {
            const chainId = activeChain?.id
            if (!address || !chainId) return

            setLoading(true)
            // Create SIWE message with pre-fetched nonce and sign with wallet
            const message = new SiweMessage({
                domain: window.location.host,
                address,
                statement: 'Sign in with Ethereum to the app.',
                uri: window.location.origin,
                version: '1',
                chainId,
                nonce: nonce,
            })
            const signature = await signMessageAsync({
                message: message.prepareMessage(),
            })

            // Verify signature
            const verifyRes = await fetch('/api/verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message, signature }),
                credentials: 'include',
            })

            if (!verifyRes.ok) throw new Error('Error verifying message')

            setLoading(false)
            setLogin({ address })
        } catch (error) {
            setLoading(false)
            setNonce(undefined)
            setError({ error })
            fetchNonce()
        }
    }

    async function getUserInfo() {
        const res = await fetch(`/api/userInfo`, {
            credentials: 'include',
        })
        console.log(await res.text())
    }

    console.log('UserINFO: ', getUserInfo())

    async function getNFTs() {
        try {
            let res = await fetch(makeOpenSeaURL())
            if (!res.ok) {
                throw new Error(res.statusText)
            }

            let body = await res.json()

            if (!body.assets || !Array.isArray(body.assets) || body.assets.length === 0) {
                return []
            }

            return body.assets.map((asset) => {
                let { name, asset_contract, token_id } = asset
                let { address } = asset_contract
                return { name, address, token_id }
            })
        } catch (err) {
            console.error(`Failed to resolve nfts: ${err.message}`)
            return []
        }
    }

    function makeOpenSeaURL() {
        return `https://api.opensea.io/api/v1/assets?owner=${address}`
    }

    async function myNfts() {
        const nfts = await getNFTs()
        return nfts
    }

    console.log('OPENSEA: ', myNfts())

    return (
        <ChakraProvider>
            <Flex
                flexDirection="column"
                width="100%"
                height="100vh"
                backgroundColor="gray.200"
                justifyContent="center"
                alignItems="center"
            >
                <Stack flexDir="column" mb="2" justifyContent="center" alignItems="center">
                    <Avatar bg="teal.500" />
                    <Heading color="teal.400">Welcome</Heading>
                    <Box minW={{ base: '90%', md: '468px' }}>
                        <form>
                            <Stack spacing={4} p="1rem" backgroundColor="whiteAlpha.900" boxShadow="md">
                                <Button
                                    loading={loading}
                                    borderRadius={0}
                                    type="submit"
                                    variant="solid"
                                    colorScheme="teal"
                                    width="full"
                                    disabled={!nonce || loading}
                                    onClick={signIn}
                                >
                                    {login ? `Login as: ${login.address}` : 'Sign In'}
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </Stack>

                <BtnConnect w={['250px', '290px', '320px', '350px']} position="absolute" />

                <Box position="fixed" float="left" bottom="10px" left="10px" maxH="200px" as="footer">
                    <BtnWallet />
                </Box>
            </Flex>
        </ChakraProvider>
    )
}

export default Auth
