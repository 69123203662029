import {
    useDisclosure,
    Flex,
    Box,
    Button,
    VStack,
    Icon,
    HStack,
    Link as ChakraLink,
    Divider,
    Spacer,
} from '@chakra-ui/react'
import DrawerPanel from './DrawerPanel'
import { IoMdMenu } from 'react-icons/io'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import React from 'react'
import ImgButton from './ImgButton'
import { useNavis } from '../utils/Environment'

export default function MobileDrawer({ activePath, display }) {
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const navis = useNavis()

    // console.log('navis: ', navis)

    function EntranceIcon({ nav, forceActive = false, h = '65px', ...props }) {
        return (
            <ImgButton
                onClick={() => {
                    onClose()
                    navigate(nav.url)
                }}
                variant={nav.variant}
                h={h}
                isActive={forceActive || activePath === nav.url}
                {...props}
            />
        )
    }

    return (
        <Flex display={display} color="black">
            {/* // Menu Button */}
            <Button ref={btnRef} onClick={onOpen}>
                <IoMdMenu size="25px" />
            </Button>

            {/* // Drawer Component */}
            <DrawerPanel isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef}>
                <VStack alignItems="center">
                    <EntranceIcon nav={navis.mountain} h="75px" />

                    <EntranceIcon nav={navis.wakumba} forceActive={true} h="65px" />

                    <EntranceIcon nav={navis.meditation} h="65px" />

                    <EntranceIcon nav={navis.forge} h="85px" />

                    {[navis.home, navis.story, navis.faq].map((item, i) => (
                        <EntranceIcon key={i} nav={item} h="45px" />
                    ))}

                    <Spacer />
                </VStack>
            </DrawerPanel>
        </Flex>
    )
}
