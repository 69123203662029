import React, { useEffect, useState } from 'react'
import CenterLayout from '../layouts/CenterLayout'
import { MountainMap } from '../../components/Backgrounds'
import bgModal from './assets/bgMountainModal.png'
import {
    Box,
    Center,
    Flex,
    HStack,
    Modal,
    ModalContent,
    ModalOverlay,
    Spacer,
    useDisclosure,
    useToast,
    VStack,
} from '@chakra-ui/react'
import ImgButton from '../../components/ImgButton'
import { useSwordBalances } from '../../pages/Forge/ForgeContracts'
import { useAccount } from 'wagmi'
import { mockSwords } from '../../pages/Forge/assets/mockData'
import { useEnv } from '../../utils/Environment'
import SummonCard from './SummonCard'
import RewardsSummonCard from './RewardsSummonCard'
import { swordConfig } from '../Forge/ForgePage'
const debug = false

function WarriorsRewards({ address }) {
    const [isVisible, setVisible] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()

    const [args, setArgs] = useState([])

    function fetchAirdrop() {
        address &&
            fetch(`/api/airdrops/mt/${address}`, { method: 'POST' })
                .then((_msg) => _msg.json())
                .then((_data) => {
                    console.log('Fetching airdrop data:', _data)
                    if (_data && _data.addr && _data.addr === address) {
                        // address addr,
                        // uint32[5] calldata allocations,
                        // uint256 nonce,
                        // bytes calldata signature,
                        // bool isGen0
                        if (_data.wl) {
                            const args = [address, _data.allocations, _data.nonce, _data.proof, _data.isGen0]
                            if (debug) {
                                console.log('Rewards Req Args: ', args)
                            }
                            setVisible(true)
                            setArgs(args)
                        } else {
                            console.log('Rewards addr Not in WL')
                            setVisible(false)
                            setArgs([])
                        }
                    } else {
                        console.error('Incoorect Rewarods data: ', _data)
                    }
                })
                .catch((err) => {
                    console.log('[Server Error]', err)
                    toast({
                        title: `Incorrect response data, please wait for a while and try again.`,
                        status: 'error',
                        isClosable: true,
                    })
                })
    }

    useEffect(() => {
        fetchAirdrop()
    }, [address])

    return isVisible ? (
        <Flex>
            <Box
                w={{
                    base: '160px',
                    md: '180px',
                }}
            >
                <ImgButton variant="mountain_rewards" onClick={onOpen}></ImgButton> : <></>
            </Box>

            <Modal id="rewardsModal" isOpen={isOpen} onClose={onClose} isCentered={true}>
                <ModalOverlay backdropBlur="10px" backdropFilter="auto" />
                <ModalContent maxH="420px" maxW="300px" backgroundColor="transparent">
                    <RewardsSummonCard args={args} />
                </ModalContent>
            </Modal>
        </Flex>
    ) : (
        <></>
    )
}

const MountainPage = () => {
    const [selectedSword, setSelectedSword] = React.useState()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const { address } = useAccount({
        onConnect() {
            console.log('Connected:', address)
        },
        onDisconnect() {
            console.log('Disconnected:', address)
        },
    })

    const { balances: swordBalances, refetch: refreshSwordBalance } = useSwordBalances({
        address: address,
        swordIds: [0, 1, 2, 3, 4],
    })
    const { isDev, network, contracts } = useEnv()

    const onSummon = (sword) => {
        console.log('SELECTED!', sword)
        const msgId1 = 'summonMsgPickSword'
        const msgId2 = 'summonMsgInsufficientSwords'
        if (!sword) {
            !toast.isActive(msgId1 + sword.id) &&
                toast({
                    id: msgId1 + sword.id,
                    status: 'warning',
                    duration: 1000,
                    title: 'Please pick a sword first!',
                    isClosable: true,
                })
        } else if (sword.amount <= 0) {
            !toast.isActive(msgId2 + sword.id) &&
                toast({
                    id: msgId2 + sword.id,
                    status: 'warning',
                    duration: 1000,
                    title: `Insufficient ${sword.quality} Swords!`,
                    isClosable: true,
                })
        } else {
            onOpen()
        }
    }

    const swords = !debug
        ? swordBalances.map((amt, idx) => {
              const swordInstance = {
                  id: idx,
                  src: '',
                  amount: amt,
                  color: swordConfig[idx].color,
                  quality: swordConfig[idx].quality,
              }
              if (amt > 0 && selectedSword === undefined) {
                  setSelectedSword(swordInstance)
              }
              return swordInstance
          })
        : mockSwords

    useEffect(() => {
        if (address) {
            setSelectedSword(undefined)
            onClose()
            refreshSwordBalance()
        }
    }, [address])

    return (
        <CenterLayout className="modalWrapper" backgroundColor="blackAlpha.600">
            <MountainMap id="backgrounds" />
            <VStack
                justify="center"
                className="modal"
                position="absolute"
                top="12%"
                bottom={{ base: '5%', md: undefined }}
                w={{ base: '100%', md: '800px' }}
                h={{ base: '60%', sm: '700px' }}
                backgroundImage={bgModal}
                backgroundAttachment="local"
                backgroundPosition="center"
                backgroundSize="200% 120%"
                backgroundRepeat="no-repeat"
                padding={'1rem'}
            >
                <WarriorsRewards address={address} />

                <Flex className="swords-container" justifyContent="space-evenly" w="80%">
                    {swords.map((sword, index) => (
                        <Flex className="sword-box" direction="column" justify="flex-start" align="center" key={index}>
                            <Box>
                                <ImgButton
                                    w={['3rem', '4rem', '5rem']}
                                    noMargin
                                    isActive={sword.amount > 0 || (selectedSword && selectedSword.id === sword.id)}
                                    variant={`mountain_sword_${sword.color}`}
                                    onClick={() => {
                                        setSelectedSword(sword)
                                        onSummon(sword)
                                    }}
                                    // disabled={sword.amount <= 0}
                                />
                            </Box>
                            <Flex
                                className="count-box"
                                h="10%"
                                justify="center"
                                align="center"
                                color="white"
                                cursor="pointer"
                            >
                                <Box
                                    mt=".5rem"
                                    fontSize={{ base: '1rem', sm: '1.5rem' }}
                                    color="black"
                                    boxShadow="0 7px 20px 5px #00,000088"
                                    verticalAlign="top"
                                    title="View on OpenSea"
                                    _hover={{ textDecoration: 'underline' }}
                                    onClick={() =>
                                        window.open(
                                            `https://${isDev ? 'testnets.' : ''}opensea.io/assets/${network.name}/${
                                                contracts.sword
                                            }/${sword.id}`
                                        )
                                    }
                                >
                                    x{sword.amount}
                                </Box>
                            </Flex>
                        </Flex>
                    ))}
                </Flex>

                <Center position="absolute" bottom="5%" className="summon-box" h="10%" w="100%">
                    <ImgButton
                        variant="mountain"
                        w={['8rem', '9rem', '12rem']}
                        opacity="0.85"
                        onClick={() => {
                            toast({
                                status: 'info',
                                duration: 3000,
                                title: 'Please pick a sword to summon Mountain.',
                                isClosable: true,
                            })
                        }}
                    />
                </Center>

                <Box h="10%"></Box>
            </VStack>

            <Modal id="summonModal" isOpen={isOpen} onClose={onClose} isCentered={true}>
                <ModalOverlay backdropBlur="10px" backdropFilter="auto" />
                <ModalContent maxH="420px" maxW="300px" backgroundColor="transparent">
                    <SummonCard sword={selectedSword} debug={debug} address={address} />
                </ModalContent>
            </Modal>
        </CenterLayout>
    )
}

export default MountainPage
