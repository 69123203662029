function errorToString(error) {
    return `${error ? (typeof error === 'object' ? JSON.stringify(error) : `${error}`) : ''}`
}

function handleContractError(method, error, toast) {
    console.log(`Contract ${method} Error: `, JSON.stringify(error))

    const toastId = 'contract-error'
    const msg = error?.internal?.message || error?.reason || errorToString(error)
    if (msg && msg.indexOf(':') !== -1) {
        const [type, reason] = msg.split(': ')

        if (!toast.isActive(toastId))
            toast({
                id: toastId,
                title: type
                    ? `Error: ${type}${reason ? '(' + reason.trim() + ')' : ''}`
                    : `Rejected, please try again later.`,
                status: 'error',
                isClosable: true,
            })
    } else {
        if (!toast.isActive(toastId))
            toast({
                id: toastId,
                title: `Error: ${msg}`,
                status: 'error',
                isClosable: true,
            })
    }
}

export { handleContractError, errorToString }
