import { keyframes } from '@emotion/react'

const fadeinoutKeys = keyframes`
        0%,100% { opacity: 0; }
        50% { opacity: 1; }
    `
const FadeInOut = `${fadeinoutKeys} infinite 4s linear forwards`

const growShrinkKeys = keyframes`
    0% { transform: scale(1.01); }
    50% { transform: scale(1.03);} 
    100% { transform: scale(1.01); }
    `
const GrowShrink = `${growShrinkKeys} infinite 4s linear forwards`

const growKeys = keyframes`
    0% { transform: scale(1.01); }
    100% { transform: scale(1.03);} 
    `
const Grow = `${growKeys} 0.1s linear forwards`

export { FadeInOut, GrowShrink, Grow }
