import {
    Center,
    Flex,
    Image,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
} from '@chakra-ui/react'
import { useAccount } from 'wagmi'
import { ForgeMap } from '../../components/Backgrounds'
import { BtnConnect } from '../../components/BtnConnect'
import ForgePage from './ForgePage'
import useSiteStage from '../hooks/SiteStage'
import faqs from '../../components/data/faqs.json'
import TimeTipText from '../../components/TimeTipText'
import EnhanceImg from '../../images/buttons/forge/enhance.png'
import CenterLayout from '../layouts/CenterLayout'

export default function Forge() {
    const { address, isConnected } = useAccount({
        onConnect() {
            console.log('Connected:', address)
        },
        onDisconnect() {
            console.log('Disconnected:', address)
        },
    })

    const [stage] = useSiteStage({
        address: address,
        isConnected: isConnected,
    })

    if (stage < 10) {
        return (
            <CenterLayout>
                <Popover placement="top">
                    <PopoverTrigger>
                        <Image
                            w={['250px', '290px', '320px', '350px']}
                            cursor="pointer"
                            draggable="false"
                            h="inherit"
                            src={EnhanceImg}
                            alt="enhance"
                            objectFit="contain"
                        />
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader />
                        <PopoverBody>
                            <>
                                <TimeTipText
                                    prefix="Enhance event of Chapter One will start at"
                                    dateStr={faqs.enhanceDate}
                                    suffix=""
                                ></TimeTipText>
                            </>
                        </PopoverBody>
                        <PopoverFooter />
                    </PopoverContent>
                </Popover>
                <ForgeMap id="background" />
            </CenterLayout>
        )
    }

    if (!isConnected) {
        return (
            <CenterLayout>
                <Center>
                    <BtnConnect w={['250px', '290px', '320px', '350px']} variant="enhance" />
                </Center>
                <ForgeMap id="background" />
            </CenterLayout>
        )
    } else {
        return <ForgePage />
    }
}
