import { Box, Image } from '@chakra-ui/react'

export default function usePreLoader() {
    function PreLoader({ requiredImages = undefined, requiredVideos = undefined }) {
        function importAll(r) {
            let items = {}
            r?.keys().forEach((item, index) => {
                items[item.replace('./', '')] = r(item)
            })
            return items
        }
        const images = importAll(requiredImages)
        const videos = importAll(requiredVideos)
        // console.log('Preload Images & Videos:', images, videos)
        return (
            <>
                {Object.values(images).map((img, idx) => (
                    <Image key={idx} src={img} className="preload" />
                ))}
                {Object.values(videos).map((vid, idx) => (
                    <Box
                        as="video"
                        key={idx}
                        src={vid}
                        className="preload"
                        autoPlay={false}
                        playsInline
                        muted
                        loop={false}
                        controls={false}
                    />
                ))}
            </>
        )
    }
    return { PreLoader }
}
