import { React } from 'react'
import { Box, chakra, Heading } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { StoryMap } from '../../components/Backgrounds'

function Text(props) {
    return (
        <chakra.p marginTop="1em" marginBottom="1em">
            {props.children}
        </chakra.p>
    )
}

function LoreTitle() {
    return (
        <Heading
            mt="0.3em"
            textAlign="center"
            size="lg"
            fontSize={['78px', '100px', '115px', '130px', '150px']}
            textShadow="0 0 1px"
        >
            Dodo Frens Lore
        </Heading>
    )
}

function LoreArticle({ lang = 'en_US', style }) {
    const navigate = useNavigate()

    return lang === 'zh_CN' ? (
        <Box as="article" style={style}>
            <Text>
                多多厌倦了，多多厌倦了作为一只普通狐獴每天的无聊而又没有希望的生活！多多不喜欢每天能抓到最多的虫子却只能分到少少的一份！多多不喜欢只有族群头领才能交女朋友！而且想和母狐獴生小孩居然还要先打到她们屈服，多多不喜欢这种传统！多多很讲文明，多多不想打架，尤其是殴打母狐獴，虽然多多打架也是最厉害的！多多听说了外面的世界！有很多和多多想法一样的生物在外出冒险后变成了大赢家！其中最成功的是一群无聊的猴子，而且还有奇怪的哥布林！哈哈，你能想象吗，哥布林！多多只在部落的古老典籍里见过这种丑陋又奇怪的生物，既然他们能成为大赢家，那多多也能！多多想要吃不完的昆虫，想要很多母狐獴当女朋友，甚至还想试试能不能交到其他物种的女朋友！多多也要成为大赢家！
            </Text>
            <Text>
                多多要出去冒险！听族中长老说过，以前也有过一只狐獴勇敢地离开了部族踏上了冒险的旅途，听说他甚至和草原上最伟大的狮子王成为了铁哥们儿！那多多怎么也得找一只龙王成为铁哥们儿吧！什么？你说龙不存在？哥布林都在外面活蹦乱跳了，怎么可能没有龙呢！你说……是吧？
            </Text>
            <Text>
                多多其实有个大秘密！族里的先祖图腾已经祝福过多多了！先祖图腾是部落里最古老神秘的东西，如果不得到它的祝福多多可没有这么大的勇气踏上冒险的旅途，它对多多说了两句话，要勇敢和Hakuna
                Matata。还给了多多一张地图，多多虽然还不知道怎么使用，但多多肯定能很快学会！因为多多是最聪明的狐獴，就是这样！
            </Text>
            <Text>
                多多准备出发了！在这之前，多多会按照先祖图腾告诉多多的，等等看还有没有和多多一样厉害的狐獴得到先祖图腾的祝福和多多一起去冒险！虽然多多一个人也能成，但是……毕竟第一次出远门，多多还是希望有伴儿的！多多可不是害怕，多多只是深思熟虑！
            </Text>
            <Text>多多一定能够成为大赢家！</Text>
        </Box>
    ) : (
        <Box as="article" style={style}>
            <Text>
                Dodo is sick of this! Dodo is sick of this kind of boring and hopeless life as a normal meerkat! Dodo no
                like hunting the most insects but getting the least of them! Dodo no like only the chieftain of the
                tribe can get girlfriends! And even if you get girlfriend as a chieftain, you would have to beat them to
                have happy hour! Dodo means that Dodo is civilized, Dodo no like fighting, especially beating women, I
                mean…… female meerkat! Dodo hears about outside world! Lots of creatures which have same thoughts with
                Dodo are getting to be big winners after they having their adventures! The most successful among them is
                a bunch of boring apes, and recently are the stories of some strange goblins! Hehe, could you imagine?
                Goblins! About which Dodo only read in the ancient books in tirbe, ugly and strange creatures! Since
                they could be big winners, so could be Dodo! Dodo wants endless insects as food, wants lots of girl
                friends, not only meerkats, Dodo is talking about all races! Dodo wants to be a big winner as well!
            </Text>
            <Text>
                Dodo is off to adventure! The elder in tribe told Dodo, there was a brave member of meerkat started his
                adventure, heard of that he got to be the best buddy of the greatest lion king! Then Dodo should be the
                best buddy of a dragon king! What, you said there is no dragon in this world? Come on, the goblins are
                already out there, there must be some dragons existing! Am I right?! Am I…… right?
            </Text>
            <Text>
                Dodo knows a biiiiig secret! The totem of ancestors{' '}
                <chakra.span textDecor="underline" cursor="pointer" onClick={() => navigate('/wakumba')}>
                    Wakumba
                </chakra.span>{' '}
                has already blessed Dodo! It is the oldest and most mysterious thing in tribe! Dodo would not be this
                brave if it weren't blessing Dodo! “Be brave” and “Hakuna Matata” are the two messages it gives Dodo!
                Yes, there's also a map, although Dodo still doesn't know how to use it, but Dodo will figure it out
                soon! Cuz Dodo is the cleverest meerkat, so it is!
            </Text>
            <Text>
                Dodo is ready to go! But before it, Dodo will do as the totem told Dodo, to wait more brave meerkats as
                good as Dodo to join! Although Dodo could do this by himself, but…… Dodo could always use some
                companies, after all it's a long trip! What, Dodo is not afraid of that! Dodo is just being……
                thoughtful!
            </Text>
            <Text>Dodo sure would be the next big winner!</Text>

            <StoryMap />
        </Box>
    )
}

export { LoreTitle, LoreArticle }
