import { useEffect, useState } from 'react'
import { Box, Center, Flex, Input, useToast, chakra, Button, Link, VStack } from '@chakra-ui/react'
import { useAccount } from 'wagmi'

import ImgButton from '../../components/ImgButton'
import ForgingAnimation from './ForgingAnimation'
import bgInput from './assets/bgInput.png'

import FORGE_ONGOING_A from './assets/forge_stages/FORGE_ONGOING_A.mp4'

import FORGE_START_WHITE from './assets/forge_stages/FORGE_START_WHITE.jpg'
import FORGE_ONGOING_B_WHITE from './assets/forge_stages/FORGE_ONGOING_B_WHITE.mp4'
import FORGE_START_GREEN from './assets/forge_stages/FORGE_START_GREEN.jpg'
import FORGE_ONGOING_B_GREEN from './assets/forge_stages/FORGE_ONGOING_B_GREEN.mp4'
import FORGE_SUCCESS_GREEN from './assets/forge_stages/FORGE_SUCCESS_GREEN.mp4'
import FORGE_START_BLUE from './assets/forge_stages/FORGE_START_BLUE.jpg'
import FORGE_ONGOING_B_BLUE from './assets/forge_stages/FORGE_ONGOING_B_BLUE.mp4'
import FORGE_SUCCESS_BLUE from './assets/forge_stages/FORGE_SUCCESS_BLUE.mp4'
import FORGE_START_PURPLE from './assets/forge_stages/FORGE_START_PURPLE.jpg'
import FORGE_ONGOING_B_PURPLE from './assets/forge_stages/FORGE_ONGOING_B_PURPLE.mp4'
import FORGE_SUCCESS_PURPLE from './assets/forge_stages/FORGE_SUCCESS_PURPLE.mp4'
import FORGE_SUCCESS_ORANGE from './assets/forge_stages/FORGE_SUCCESS_ORANGE.mp4'

import FORGE_FAIL from './assets/forge_stages/FORGE_FAIL.jpg'

import { useEnhanceSwordContract } from './ForgeContracts'
import { useEnv } from '../../utils/Environment'
import usePreLoader from '../../utils/PreLoader'

const videoMaps = {
    FORGE_ONGOING_A: FORGE_ONGOING_A,
    FORGE_ONGOING_B_WHITE: FORGE_ONGOING_B_WHITE,
    FORGE_ONGOING_B_GREEN: FORGE_ONGOING_B_GREEN,
    FORGE_ONGOING_B_BLUE: FORGE_ONGOING_B_BLUE,
    FORGE_ONGOING_B_PURPLE: FORGE_ONGOING_B_PURPLE,

    FORGE_SUCCESS_WHITE: FORGE_FAIL,
    FORGE_SUCCESS_ORANGE: FORGE_SUCCESS_ORANGE,
    FORGE_SUCCESS_GREEN: FORGE_SUCCESS_GREEN,
    FORGE_SUCCESS_BLUE: FORGE_SUCCESS_BLUE,
    FORGE_SUCCESS_PURPLE: FORGE_SUCCESS_PURPLE,
}

const bgMaps = {
    FORGE_START_WHITE: FORGE_START_WHITE,
    FORGE_START_GREEN: FORGE_START_GREEN,
    FORGE_START_BLUE: FORGE_START_BLUE,
    FORGE_START_PURPLE: FORGE_START_PURPLE,
    FORGE_FAIL: FORGE_FAIL,
}

const UpgradeCard = ({
    setIsUpgradeOngoing,
    swordToUpgrade,
    starAmount,
    swordConfig,
    onOpen,
    onClose,
    onTxSettled,
}) => {
    const { address } = useAccount()
    const toast = useToast()

    //styles
    const startBgStyle = {
        aspectRatio: '1142 / 1600',
        flexDirection: 'column',
        backgroundImage: `url(${
            bgMaps[`FORGE_START_${swordToUpgrade ? swordToUpgrade?.color?.toUpperCase() : 'WHITE'}`]
        })`,
        backgroundAttachment: 'local',
        backgroundPosition: 'center',
        backgroundSize: '100% 100%',
    }

    const failBgStyle = {
        width: '100%',
        aspectRatio: '1142 / 1600',
        flexDirection: 'column',
        backgroundImage: `url(${bgMaps['FORGE_FAIL']})`,
        backgroundAttachment: 'local',
        backgroundPosition: 'center',
        backgroundSize: '100% 100%',
    }

    const [upgradeStatus, setUpgradeStatus] = useState('START')
    const [newSword, setNewSword] = useState(swordToUpgrade)
    const [starToApplyAmount, setStarToApplyAmount] = useState(starAmount)

    useEffect(() => {
        setStarToApplyAmount(starAmount)
    }, [starAmount])

    useEffect(() => {
        upgradeStatus === 'ONGOING' ? setIsUpgradeOngoing(true) : setIsUpgradeOngoing(false)
    }, [upgradeStatus, setIsUpgradeOngoing])

    // ============== Contract: Enhance Sword ==============
    const enhanceSword = useEnhanceSwordContract({
        address: address,
        args: [swordToUpgrade?.id, starToApplyAmount],
        swordConfig: swordConfig,
        onLoading: () => {
            toast({
                id: 'confirmWallet',
                title: `Confirm enhancing Sword #${swordConfig[swordToUpgrade?.id].quality} ...`,
                description: `StarOfBravery to spend: ${starToApplyAmount}x`,
                status: 'info',
                isClosable: true,
                duration: 10_000,
            })
        },
        onTxStarted: () => {
            toast.close('confirmWallet')
            setUpgradeStatus('ONGOING')
        },
        onTxCancelled: () => {
            toast.close('confirmWallet')
        },
        onSwordEnhanceSuccess: (newSwordId, prevSwordId, starsUsed) => {
            // set sword data
            setNewSword({
                id: newSwordId,
                color: swordConfig[newSwordId].color,
                quality: swordConfig[newSwordId].quality,
            })
            setUpgradeStatus('SUCCESS')
        },
        onSwordEnhanceFail: (swordId, starsUsed) => {
            setUpgradeStatus('FAIL')
        },
        onTxSettled: onTxSettled,
    })

    useEffect(() => {
        validateBeforeEnhance()
    }, [starToApplyAmount])

    const onApplyStar = () => {
        if (validateBeforeEnhance({ zeroCheck: true })) {
            enhanceSword?.()
        }
    }

    const validateBeforeEnhance = (options = {}) => {
        const isValid = validateStarToApplyAmount(starToApplyAmount, options.zeroCheck)
        console.log('isValid:', isValid)
        if (!isValid) {
            setStarToApplyAmount(starAmount)
            return false
        }
        setNewSword(swordToUpgrade)
        return true
    }

    const validateStarToApplyAmount = (amount, zeroCheck = false) => {
        const id = 'starToApplyAmountInvalid'

        if (isNaN(amount) || Number(amount) < (zeroCheck ? 1 : 0)) {
            !toast.isActive(id) &&
                toast({
                    id: id,
                    status: 'warning',
                    duration: 1000,
                    title: 'Please input a valid star amount!',
                    isClosable: true,
                })
            return false
        }

        if (Number(amount) > starAmount) {
            !toast.isActive(id) &&
                toast({
                    id: id,
                    status: 'warning',
                    duration: 1000,
                    title: 'Max star amount is: ' + starAmount,
                    isClosable: true,
                })
            return false
        }

        return true
    }

    const onCloseModal = () => {
        setUpgradeStatus('START')
        onClose()
    }

    const { isDev, network, contracts } = useEnv()

    // debug only
    const [id, setId] = useState(0)

    return (
        <Flex
            pointerEvents="auto"
            h="100vh"
            w="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
            gap="5%"
        >
            {isDev && (
                <Button
                    position="fixed"
                    left="10px"
                    top="10px"
                    onClick={() => {
                        setId(id + 1)
                        setUpgradeStatus(['START', 'ONGOING', 'SUCCESS', 'FAIL'][(id + 1) % 4])
                    }}
                >
                    Debug: {['START', 'ONGOING', 'SUCCESS', 'FAIL'][id % 4]} =&gt; Next:{' '}
                    {['START', 'ONGOING', 'SUCCESS', 'FAIL'][(id + 1) % 4]}
                </Button>
            )}

            {
                {
                    START: (
                        <VStack align="center" sx={startBgStyle} h="420px" w="300px">
                            <Box mt="271px" pl="3px">
                                <ImgButton
                                    variant="apply"
                                    h="38px"
                                    noMargin
                                    onClick={onApplyStar}
                                    disabled={!enhanceSword}
                                />
                            </Box>
                            <Box pt="10px" pl="60px">
                                <Input
                                    w="135px"
                                    h="50px"
                                    fontSize="180%"
                                    value={starToApplyAmount}
                                    onChange={(e) => {
                                        setStarToApplyAmount(isNaN(e.target.value) ? 0 : Number(e.target.value))
                                    }}
                                    onClick={(e) => {
                                        e.target.select()
                                    }}
                                    //onBlur={(e) => validateStarToApplyAmount(e.target.value)}
                                    backgroundImage={bgInput}
                                    backgroundPosition="center"
                                    backgroundSize="100% 100%"
                                    autoFocus={true}
                                />
                            </Box>
                        </VStack>
                    ),
                    ONGOING: <ForgingAnimation selectedSword={swordToUpgrade} />,
                    SUCCESS: (
                        <VStack align="center" h="420px" w="300px">
                            <Center>
                                <Link
                                    title="View on OpenSea"
                                    href={`https://${isDev ? 'testnets.' : ''}opensea.io/assets/${network.name}/${
                                        contracts.sword
                                    }/${newSword.id}`}
                                    isExternal={true}
                                >
                                    <chakra.div
                                        as="video"
                                        src={
                                            videoMaps[
                                                `FORGE_SUCCESS_${
                                                    newSword && newSword.id > 0
                                                        ? newSword?.color?.toUpperCase()
                                                        : 'GREEN'
                                                }`
                                            ]
                                        }
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        controls={false}
                                        objectPosition="center"
                                    />
                                </Link>
                            </Center>
                        </VStack>
                    ),
                    FAIL: <VStack align="center" h="420px" w="300px" sx={failBgStyle}></VStack>,
                }[upgradeStatus]
            }
            <Loader />
        </Flex>
    )
}

const videoAssetsForge = require.context('./assets', false, /\.(mp4|m4v)$/)
const videoAssetsStages = require.context('./assets/forge_stages', false, /\.(mp4|m4v)$/)

function Loader() {
    const { PreLoader } = usePreLoader()
    return (
        <>
            <PreLoader requiredVideos={videoAssetsForge} />
            <PreLoader requiredVideos={videoAssetsStages} />
        </>
    )
}

export { UpgradeCard, videoMaps }
