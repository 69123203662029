import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Center,
    Image,
    Text,
    Link,
    VisuallyHidden,
    Flex,
    VStack,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { FrensMap } from '../components/Backgrounds'
import InternalLink from '../components/InternalLink'
import faqs from '../components/data/faqs.json'
import labels from '../components/data/labels.json'
import FaqBtnImg from '../images/buttons/faq.png'
import TimeTipText from '../components/TimeTipText'
import CenterLayout from './layouts/CenterLayout'
import { useEnv, useLinks } from '../utils/Environment'
import { ExternalLinkIcon } from '@chakra-ui/icons'

function FaqItem({ title, content }) {
    return (
        <AccordionItem>
            <Box bg="blackAlpha.900">
                <AccordionButton>
                    <Box flex="1" textAlign="left" fontSize={['17', '18', '19', '20']} fontStyle="underscore">
                        {title}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </Box>
            <AccordionPanel pb={4} bg="blackAlpha.700">
                <Box px="3" py="1" textAlign="left" fontSize="sm" size="sm">
                    {content}
                </Box>
            </AccordionPanel>
        </AccordionItem>
    )
}

function FAQ() {
    const { openSeaItemUrl } = useLinks()
    const { contracts } = useEnv()

    return (
        <CenterLayout>
            <Box mt={{ base: '18%', md: '10%' }}>
                <Image src={FaqBtnImg} w={['110px', '140px', '170px', '200px']} />
            </Box>

            <Box h="100%" w={['xm', 'md', 'lg', 'xl', '2xl', '3xl']}>
                <Accordion color="white" w="100%" allowToggle={true} defaultIndex={[0]}>
                    <FaqItem
                        title={<Text>When will the public mint of Mountain (MT) start?</Text>}
                        content={
                            <Box fontFamily="sans-serif">
                                <TimeTipText
                                    prefix="The Sword Mint (Whitelist): "
                                    dateStr={faqs.mountainDate}
                                    suffix=""
                                ></TimeTipText>
                                <TimeTipText
                                    prefix="The Public Mint: "
                                    dateStr={faqs.mountainFreeMintDate}
                                    suffix=""
                                ></TimeTipText>
                                <Text pt="2">
                                    Please visit the {'  '}
                                    <InternalLink url="/mountain">Mountain Mint</InternalLink> page before the event
                                    starts, and check our {'  '}
                                    <Link href={labels.links.twitter} isExternal={true} textDecor="under line">
                                        Twitter
                                    </Link>{' '}
                                    for the latest updates.
                                </Text>
                            </Box>
                        }
                    />

                    <FaqItem
                        title={<Text>What does the Sword Of Bravery used for?</Text>}
                        content={<Box>The owners of Sword Of Bravery are whitelisted to mint Mountain NFT.</Box>}
                    />

                    <FaqItem
                        title={<Text>How to use Faith?</Text>}
                        content={
                            <Box>
                                1. The Faith Token is an essential resource for DoDo and his Frens to interact with{' '}
                                <Link href="/story" textDecor="underline">
                                    {' '}
                                    the Storyline
                                </Link>
                                <br />
                                2. Faith Token majorly comes from{' '}
                                <Link href="/meditation" textDecor="underline">
                                    {' '}
                                    DoDo's Meditation activity
                                </Link>{' '}
                                and has a fixed releasing rate (Wakumba may adjust it in the future)
                                <br />
                                3. You can also gain extra Faith Tokens and collect{' '}
                                <Link href="/wakumba" textDecor="underline">
                                    the Sword of Bravery
                                </Link>{' '}
                                through{' '}
                                <Link href="/wakumba" textDecor="underline">
                                    {' '}
                                    Campaigns or Invitations
                                </Link>
                                <br />
                                4. Currently, the Faith token can convert into{' '}
                                <Link href="/forge?from=faq" textDecor="underline">
                                    the Stars
                                </Link>
                                , which are used to enhance your collected Swords
                                <br />
                                5. All Swords have the same chance to upgrade to the next level. Check{' '}
                                <Link href="/forge" textDecor="underline">
                                    the Forge page
                                </Link>{' '}
                                for detail
                                <br />
                                6. Your upgraded Swords can lock the rarity of the next NFT collection (
                                <Link href="/story#mTag" textDecor="underline">
                                    Mountain
                                </Link>
                                ) in prior
                                <br />
                                7. All the owners of Sword Of Bravery are whitelisted to mint Mountain NFT.
                            </Box>
                        }
                    />

                    <VisuallyHidden>
                        <FaqItem
                            title={<Text>Wen airdrop?</Text>}
                            content={
                                <>
                                    <TimeTipText
                                        prefix="Airdrop event of Chapter One will start at"
                                        dateStr={faqs.airdropDate}
                                        suffix=", after the snapshot was taken."
                                    ></TimeTipText>
                                    <TimeTipText
                                        prefix="Snapshot time is"
                                        dateStr={faqs.snapshotDate}
                                        suffix=""
                                    ></TimeTipText>
                                </>
                            }
                        />
                    </VisuallyHidden>

                    <FaqItem
                        title={<Text>Where can I get DoDoFrens NFTs?</Text>}
                        content={
                            <Box>
                                <Box>
                                    <Link href={`${openSeaItemUrl}${contracts.dodo}/0`} isExternal>
                                        View DoDoFrens on <ExternalLinkIcon></ExternalLinkIcon>
                                        <Box as="span" textDecor="underline">
                                            Opensea
                                        </Box>
                                    </Link>
                                </Box>

                                <Box>
                                    <Link href={`${openSeaItemUrl}${contracts.sword}/0`} isExternal>
                                        View Sword Of Bravery on <ExternalLinkIcon></ExternalLinkIcon>
                                        <Box as="span" textDecor="underline">
                                            Opensea
                                        </Box>
                                    </Link>
                                </Box>

                                <Box>
                                    <Link href={`${openSeaItemUrl}${contracts.mountain}/0`} isExternal>
                                        View Mountain on <ExternalLinkIcon></ExternalLinkIcon>
                                        <Box as="span" textDecor="underline">
                                            Opensea
                                        </Box>
                                    </Link>
                                </Box>
                            </Box>
                        }
                    />

                    <FaqItem
                        title={<Text>How many MTs are there in the collection?</Text>}
                        content={<Box>{faqs.totalSupply}</Box>}
                    />

                    <FaqItem
                        title={<Text>How much is mint?</Text>}
                        content={
                            <Box>
                                Freemint for 1 MT. 0.003 ETH each for those who purchase more. Max 3 MTs per wallet.
                            </Box>
                        }
                    />

                    <VisuallyHidden>
                        <FaqItem
                            title={<Text>How many will we be able to mint?</Text>}
                            content={<Box>{faqs.mintPerWallet}</Box>}
                        />
                    </VisuallyHidden>

                    <FaqItem title={<Text>Which blockchain?</Text>} content={<Box>{faqs.blockchain}</Box>} />

                    <FaqItem title={<Text>When is reveal?</Text>} content={<Box>{faqs.reveal}</Box>} />

                    <VisuallyHidden>
                        <FaqItem
                            title={<Text>How many DoDos will the team reserve?</Text>}
                            content={<Box>{faqs.reserve}</Box>}
                        />
                    </VisuallyHidden>

                    <FaqItem title={<Text>Do you have a roadmap?</Text>} content={<Box>{faqs.roadmap}</Box>} />

                    <VisuallyHidden>
                        <FaqItem
                            title={<Text>How do I check if I am a Chosen One (whitelisted)?</Text>}
                            content={<Box>{faqs.whitelist}</Box>}
                        />
                    </VisuallyHidden>
                </Accordion>
            </Box>

            <Box fontSize="sm" pt="50px" color="white" pb="5%">
                DoDoFrens&#8482; 2022, All rights reserved.
            </Box>

            <FrensMap h="100%" />
        </CenterLayout>
    )
}

export default FAQ
