import React from 'react'
import { Center, chakra, VStack } from '@chakra-ui/react'
import { videoMaps } from './UpdateCard'

const ForgingAnimation = ({ selectedSword }) => {
    const forgingStyle = {
        '@keyframes fg': {
            from: { transform: 'rotateY(0deg)' },
            to: { transform: 'rotateY(360deg)' },
        },
        animation: 'fg 6s infinite linear',
    }
    const forgingBStyle = {
        position: 'absolute',
        '@keyframes fgb': {
            '0%': { opacity: 0 },
            '25%': { opacity: 1 },
            '50%': { opacity: 1 },
            '75%': { opacity: 0 },
            '100%': { opacity: 0 },
        },
        animation: 'fgb 6s infinite step-end',
    }

    const forgingAStyle = {
        '@keyframes fga': {
            '0%': { opacity: 1 },
            '25%': { opacity: 0 },
            '50%': { opacity: 0 },
            '75%': { opacity: 1 },
            '100%': { opacity: 1 },
        },
        animation: 'fga 6s infinite step-end',
    }

    return (
        <VStack align="center" h="420px" w="300px" sx={forgingStyle}>
            <chakra.div
                as="video"
                sx={forgingBStyle}
                src={videoMaps[`FORGE_ONGOING_B_${selectedSword ? selectedSword?.color?.toUpperCase() : 'WHITE'}`]}
                autoPlay
                loop
                muted
                playsInline
                controls={false}
                objectPosition="center"
            />
            <chakra.div
                as="video"
                src={videoMaps[`FORGE_ONGOING_A`]}
                sx={forgingAStyle}
                autoPlay
                loop
                muted
                playsInline
                controls={false}
                objectPosition="center"
            />
        </VStack>
    )
}

export default ForgingAnimation
