import React from 'react'
import { Modal, Center, ModalContent, ModalOverlay, useDisclosure, useToast } from '@chakra-ui/react'
import { useAccount } from 'wagmi'
import { ForgeMap } from '../../components/Backgrounds'
import { mockSwords } from './assets/mockData'
import { useStarBalance, useSwordBalances } from './ForgeContracts'
import ModalBuyStar from './ModalBuyStar'
import { UpgradeCard } from './UpdateCard'
import CenterLayout from '../layouts/CenterLayout'
import ForgeBench from './ForgeBench'
import usePreLoader from '../../utils/PreLoader'

const debug = false

export const swordConfig = [
    { id: 0, color: 'white', quality: 'COMMON' },
    { id: 1, color: 'green', quality: 'UNCOMMON' },
    { id: 2, color: 'blue', quality: 'RARE' },
    { id: 3, color: 'purple', quality: 'EPIC' },
    { id: 4, color: 'orange', quality: 'LEGENDARY' },
]

const ForgePage = () => {
    const [selectedSword, setSelectedSword] = React.useState({ id: 0, color: 'white' })
    const [isUpgradeOngoing, setIsUpgradeOngoing] = React.useState(false)
    //hooks
    const toast = useToast()

    const { address } = useAccount({
        onConnect() {
            console.log('Connected:', address)
        },
        onDisconnect() {
            console.log('Disconnected:', address)
        },
    })

    // 1. Get Sword Balances (from chain)
    const { balances: swordBalances, refetch: refreshSwordBalance } = useSwordBalances({
        address: address,
        swordIds: [0, 1, 2, 3, 4],
    })
    const swords = !debug
        ? swordBalances.map((amt, idx) => {
              return {
                  id: idx,
                  src: '',
                  amount: amt,
                  color: swordConfig[idx].color,
                  quality: swordConfig[idx].quality,
              }
          })
        : mockSwords

    // 2. Get Star Balance
    const { balance: starBalance } = useStarBalance({ address: address })
    const starAmount = debug ? 100 : starBalance

    const onOpenApplyStarModal = () => {
        const id = 'unselectedSword-toast'
        selectedSword
            ? (() => {
                  if (selectedSword.id === 4) {
                      !toast.isActive(id) &&
                          toast({
                              id,
                              status: 'warning',
                              duration: 1000,
                              title: 'Already reached the Legendary level',
                          })
                  } else if (selectedSword.amount <= 0) {
                      !toast.isActive(id) &&
                          toast({
                              id,
                              status: 'warning',
                              duration: 1000,
                              title: 'No sword found in wallet',
                          })
                  } else {
                      openApplyStarMoal()
                  }
              })()
            : !toast.isActive(id) &&
              toast({
                  id,
                  status: 'warning',
                  duration: 1000,
                  title: 'Please Select a Sword First',
              })
    }

    // modal controls
    const { isOpen: isOpenSwordModal, onOpen: openApplyStarMoal, onClose: closeApplyStarMoal } = useDisclosure()
    const { isOpen: isOpenBuyStarModal, onOpen: openBuyStarMoal, onClose: closeBuyStarMoal } = useDisclosure()

    return (
        <CenterLayout className="modalWrapper" backgroundColor="blackAlpha.600">
            <ForgeBench
                swords={swords}
                starAmount={starAmount}
                onBuyStar={openBuyStarMoal}
                onApplyStar={onOpenApplyStarModal}
                selectedSword={selectedSword}
                setSelectedSword={setSelectedSword}
            />

            <Modal
                closeOnOverlayClick={!isUpgradeOngoing}
                id="applyStarModal"
                isOpen={isOpenSwordModal}
                onClose={closeApplyStarMoal}
                isCentered={true}
            >
                <ModalOverlay backdropBlur="10px" backdropFilter="auto" />
                <ModalContent maxH="420px" maxW="300px" backgroundColor="transparent">
                    <UpgradeCard
                        swordToUpgrade={selectedSword}
                        starAmount={starAmount}
                        setIsUpgradeOngoing={setIsUpgradeOngoing}
                        swordConfig={swordConfig}
                        onOpen={openApplyStarMoal}
                        onClose={closeApplyStarMoal}
                        onTxSettled={() => {
                            refreshSwordBalance()
                        }}
                    />
                </ModalContent>
            </Modal>

            <Modal
                id="buyStarModal"
                isOpen={isOpenBuyStarModal}
                onClose={closeBuyStarMoal}
                isCentered={true}
                closeOnOverlayClick={true}
            >
                <ModalOverlay />
                <ModalContent maxH="500px" maxW="600px" backgroundColor="transparent">
                    <Center>
                        <ModalBuyStar debug={debug} onOpen={openBuyStarMoal} onClose={closeBuyStarMoal} />
                    </Center>
                </ModalContent>
            </Modal>

            <ForgeMap id="backgrounds" />

            <Loader />
        </CenterLayout>
    )
}

const imageAssetsForge = require.context('./assets', false, /\.(png|jpe?g|svg|gif)$/)
const imageAssetsStages = require.context('./assets/forge_stages', false, /\.(png|jpe?g|svg|gif)$/)

function Loader() {
    const { PreLoader } = usePreLoader()
    return (
        <>
            <PreLoader requiredImages={imageAssetsForge} />
            <PreLoader requiredImages={imageAssetsStages} />
        </>
    )
}

export default ForgePage
