import React from 'react'
import CenterLayout from '../layouts/CenterLayout'
import { MountainMap } from '../../components/Backgrounds'
import bgModal from './assets/bgMountainModal.png'
import { Box, Center, Flex, HStack, VStack } from '@chakra-ui/react'
import ImgButton from '../../components/ImgButton'
import PublicSummonCard from './PublicSummonCard'
import { useEnv } from '../../utils/Environment'

const MountainFreeMintPage = () => {
    const { collections } = useEnv()
    return (
        <CenterLayout className="modalWrapper" backgroundColor="blackAlpha.600">
            <VStack
                align="center"
                justify={{ base: 'center', sm: 'end' }}
                // bg="blue"
                className="modal"
                w={{ base: '100%', md: '800px' }}
                h={{ base: '60%', sm: '700px' }}
                backgroundImage={{ base: '', sm: bgModal }}
                backgroundAttachment="local"
                backgroundPosition="center"
                backgroundSize="200% 120%"
                backgroundRepeat="no-repeat"
                pb={{ base: '3%', lg: '2%' }}
            >
                <Flex pb="1rem">
                    <PublicSummonCard />
                </Flex>
                <Flex className="summon-box" pb="3%" h="4rem">
                    <ImgButton
                        variant="mountain"
                        h="100%"
                        opacity="0.85"
                        isActive={false}
                        onClick={() => window.open(collections.mountain, '_blank')}
                        title="View Project On Opensea"
                    />
                </Flex>
            </VStack>

            <MountainMap id="backgrounds" />
        </CenterLayout>
    )
}

export default MountainFreeMintPage
