import React from 'react'
import { Box, Flex, Button, Center, Img, Image, Input, useToast, VStack } from '@chakra-ui/react'
import start_sword_white from './assets/mint/start_sword_white.png'
import start_sword_green from './assets/mint/start_sword_green.png'
import start_sword_blue from './assets/mint/start_sword_blue.png'
import start_sword_purple from './assets/mint/start_sword_purple.png'
import start_sword_orange from './assets/mint/start_sword_orange.png'
import success_loading_bg from './assets/mint/success_loading_bg.png'
import success_bottom from './assets/mint/success_bottom.png'
import view_on_opensea from './assets/mint/view_on_opensea.png'
import { useEnv } from '../../utils/Environment'
import SummonAnimation from './SummonAnimation'
import { validateAmountRange } from '../../utils/validate'
import ImgButton from '../../components/ImgButton'
import { useSummonMountain } from './MountainContracts'
import { Circles } from 'react-loader-spinner'

const bgMaps = {
    start_sword_white: start_sword_white,
    start_sword_green: start_sword_green,
    start_sword_blue: start_sword_blue,
    start_sword_purple: start_sword_purple,
    start_sword_orange: start_sword_orange,
}

const SummonCard = ({ sword, address, debug }) => {
    const styleSuccessBottom = {
        backgroundAttachment: 'local',
        backgroundPosition: 'center',
        backgroundImage: `url(${success_bottom})`,
        backgroundSize: '100% 110%',
    }
    const startBgStyle = {
        aspectRatio: '1142 / 1600',
        width: '300px',
        height: '450px',
        flexDirection: 'column',
        backgroundImage: `url(${bgMaps[`start_sword_${sword.color}`]})`,
        backgroundAttachment: 'local',
        backgroundPosition: 'center',
        backgroundSize: '100% 100%',
    }

    const cancelBorderStyle = {
        border: 'none',
        _hover: {
            border: 'none',
        },
        _focusVisible: {
            border: 'none',
        },
    }

    const [upgradeStatus, setUpgradeStatus] = React.useState('START')
    const [summonAmount, setSummonAmount] = React.useState(sword.amount)
    const toast = useToast()
    const { isDev, network, contracts } = useEnv()
    // debug only
    const [id, setId] = React.useState(0)
    const [mountainSrc, setMountainSrc] = React.useState('')
    const [receivedMountainId, setReceivedMountainId] = React.useState(1)

    // ============== Contract: Enhance Sword ==============

    const summon = useSummonMountain({
        args: [sword.id, summonAmount],
        onLoading: () => {
            toast({
                id: 'confirmSummonOnWallet',
                title: `Please confirm in your wallet to summon ${summonAmount} mountains`,
                status: 'info',
                isClosable: true,
                duration: 10_000,
            })
        },
        onTxStarted: () => {
            toast.close('confirmSummonOnWallet')
            setUpgradeStatus('ONGOING')
        },
        onTxCancelled: () => {
            toast.close('confirmSummonOnWallet')
        },
        onMintSuccess: (mountainId) => {
            setReceivedMountainId(mountainId)
            setUpgradeStatus('SUCCESS')
        },
    })

    const onConfirmSummon = () => {
        const { isValid, message } = validateAmountRange(summonAmount, 1, sword.amount)
        if (!isValid) {
            const id = 'validationError'
            !toast.isActive(id) &&
                toast({
                    id: id,
                    status: 'warning',
                    duration: 1000,
                    title: message,
                    isClosable: true,
                })
            setSummonAmount(sword.amount)
        } else {
            summon?.()
        }
    }

    function fetchMountainData(tokenId) {
        fetch(
            `https://api.opensea.io/api/v1/asset/0xf933f97d4d1f3a245401d0f70769ead59d34b047/${tokenId}/?force_update=true`
        )
            .then((msg) => {
                console.log('Got API UPDATE: ' + msg)
            })
            .catch((err) => {
                console.log('UPDATE API Failed: ', err)
            })

        console.log('Fetching MT image url ... ')

        setTimeout(() => {
            tokenId !== undefined &&
                fetch(`https://dodofrens.xyz/metadata/mtid/${tokenId}`)
                    .then((_msg) => _msg.json())
                    .then((_data) => {
                        const mountainSrc = `https://gateway.dodofrens.com/ipfs/QmYeSf6eLFJj6zGV3gvei1HtP8hw1EAuSJos6AAUiTBn2B/mt-images-prod/${_data.edition}.png`
                        console.log('Got MT Image ... ' + mountainSrc)
                        setMountainSrc(mountainSrc)
                    })
                    .catch((err) => {
                        console.log('Fetch RefCode Failed: ', err)
                        //ignore and set defaults
                        const mountainSrc = `https://gateway.dodofrens.com/ipfs/QmYeSf6eLFJj6zGV3gvei1HtP8hw1EAuSJos6AAUiTBn2B/mt-images-prod/0.png`
                        setMountainSrc(mountainSrc)
                    })
        }, 1500)
    }

    React.useEffect(() => {
        receivedMountainId && fetchMountainData(receivedMountainId)
    }, [receivedMountainId])

    return (
        <Flex
            pointerEvents="auto"
            w="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
            gap="5%"
        >
            {isDev && (
                <Button
                    position="fixed"
                    left="10px"
                    top="10px"
                    onClick={() => {
                        setId(id + 1)
                        setUpgradeStatus(['START', 'ONGOING', 'SUCCESS'][(id + 1) % 3])
                    }}
                >
                    Debug: {['START', 'ONGOING', 'SUCCESS', 'FAIL'][id % 4]} =&gt; Next:{' '}
                    {['START', 'ONGOING', 'SUCCESS', 'FAIL'][(id + 1) % 4]}
                </Button>
            )}

            {
                {
                    START: (
                        <Box sx={startBgStyle} position="relative">
                            <Input
                                value={summonAmount}
                                onChange={(e) => setSummonAmount(e.target.value)}
                                w="6rem"
                                background="none"
                                position="absolute"
                                bottom="3rem "
                                left="4rem"
                                fontSize="2rem"
                                autoFocus={true}
                                {...cancelBorderStyle}
                            ></Input>
                            <ImgButton
                                variant="summon"
                                w="6rem"
                                position="absolute"
                                bottom="0rem"
                                left="3.5rem"
                                disabled={!summon}
                                onClick={onConfirmSummon}
                            />
                        </Box>
                    ),
                    ONGOING: <SummonAnimation sword={sword} w="300px" h="450px" />,
                    SUCCESS: (
                        <VStack gap="0" justify="center" w="300px" h="450px">
                            <Box boxShadow=" 0 3px 6px rgba(0,0,0,0.4); " h="300px" w="300px">
                                <Image
                                    src={mountainSrc}
                                    fallback={
                                        <Center>
                                            <Image src={success_loading_bg} />
                                            <Box position="absolute">
                                                <Circles
                                                    height="80"
                                                    width="80"
                                                    color="white"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </Box>
                                        </Center>
                                    }
                                />
                            </Box>

                            <Center sx={styleSuccessBottom} position="relative" w="100%" h="150px" mt="0 !important">
                                <Img
                                    src={view_on_opensea}
                                    position="absolute"
                                    top="80px"
                                    w="200px"
                                    onClick={() =>
                                        window.open(
                                            `https://${isDev ? 'testnets.' : ''}opensea.io/assets/${network.name}/${
                                                contracts.mountain
                                            }/${receivedMountainId}`
                                        )
                                    }
                                    _hover={{ cursor: 'pointer' }}
                                />
                            </Center>
                        </VStack>
                    ),
                }[upgradeStatus]
            }
        </Flex>
    )
}

export default SummonCard
