import { VStack } from '@chakra-ui/react'

export default function CenterLayout(props) {
    const { children, ...rest } = props

    return (
        <VStack
            as="section"
            className="centerLayout"
            alignContent="center"
            justifyContent="center"
            m="0"
            h="100%"
            w="100%"
            {...rest}
        >
            {children}
        </VStack>
    )
}
