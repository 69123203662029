import React from 'react'
import { useToast, useMediaQuery } from '@chakra-ui/react'
import { CURRENT_STAGE_DFAULT } from '../pages/hooks/SiteStage'

const DodoStoreContext = React.createContext()
export const useDodoStoreContext = () => {
    const context = React.useContext(DodoStoreContext)
    if (!context) {
        throw new Error('DodoStoreContext must be used within DodoStoreContext.Provider')
    }
    return context
}

const DodoStoreProvider = ({ children }) => {
    const [stage, setStage] = React.useState()
    const toast = useToast()
    const [isMobile] = useMediaQuery('(max-width: 30em)')

    React.useEffect(() => {
        const json = JSON.parse(localStorage.getItem('CURRENT_SERVER_STAGE'))
        const { stage } = json ? json : { stage: CURRENT_STAGE_DFAULT }
        if (!stage) {
            fetch('/api/stages/current')
                .then((_msg) => _msg.json())
                .then((_data) => {
                    setStage(_data.stage)
                    localStorage.setItem('CURRENT_SERVER_STAGE', JSON.stringify({ stage: _data.stage }))
                    console.log('Local Storage: ', JSON.parse(localStorage.getItem('CURRENT_SERVER_STAGE')))
                    console.log('Get Stage: ', _data.stage)
                })
                .catch((err) => {
                    console.log('Fetch Stage Failed: ', err)
                    const toastId = 'get-stage-failed'
                    !toast.isActive(toastId) &&
                        toast({
                            id: toastId,
                            title: `Unstable Network, new requests may fail`,
                            status: 'error',
                            isClosable: true,
                        })
                })
        } else {
            setStage(stage)
        }
    }, [])
    return <DodoStoreContext.Provider value={{ stage, setStage, isMobile }}>{children}</DodoStoreContext.Provider>
}

export default DodoStoreProvider
