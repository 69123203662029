import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
    Box,
    Flex,
    Text,
    Input,
    VStack,
    Button,
    InputGroup,
    InputRightElement,
    useToast,
    Center,
    Heading,
    Spacer,
    chakra,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogCloseButton,
    AlertDialogBody,
    AlertDialogFooter,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Link,
    Tooltip,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FaTwitter } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useAccount } from 'wagmi'
import ImgButton from '../../components/ImgButton'
import { ReactComponent as CheckSuccessIcon } from '../../images/svgs/check_success.svg'
import { ReactComponent as CloseErrorIcon } from '../../images/svgs/close_error.svg'
import { ReactComponent as WaitInfoIcon } from '../../images/svgs/wait_info.svg'
import { useEnv, useLinks } from '../../utils/Environment'
import { useFaithBalance } from '../Forge/ForgeContracts'
import FaithNum from './FaithNum'
import { BtnPray } from './Share'
import { FadeInOut, GrowShrink } from '../../components/Animations'

function ConfirmDialog({ code, isOpen, onOpen, onOk, onCancel }) {
    const acceptRef = React.useRef()
    const [loading, setLoading] = useState(false)

    const { address } = useAccount()

    const { contracts } = useEnv()
    const { openSeaItemUrl } = useLinks()

    return (
        <>
            {/* <Button onClick={onOpen}>Discard</Button> */}

            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={acceptRef}
                onClose={onCancel}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>
                        <Heading>Confirm Invitation </Heading>
                    </AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        <Box>
                            Welcome, my fren, there are 1000
                            <ImgButton display="inline-block" w="30px" h="30px" noMargin variant={'faithFull'} />
                            for you!
                        </Box>
                        <Box mt="10px"></Box>
                        <Box>My RefCode# is {code}</Box>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button onClick={onCancel}>Next Time...</Button>

                        <Button
                            isLoading={loading}
                            ref={acceptRef}
                            colorScheme="green"
                            ml={3}
                            onClick={() => {
                                console.log('Close!')
                                setLoading(true)
                                setTimeout(() => {
                                    setLoading(false)
                                    onOk()
                                }, 1500)
                            }}
                        >
                            Great, Thank You!
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )
}

function StatusBtn({ taskDone }) {
    return (
        <Flex
            h="30px"
            w="30px"
            ml={{
                base: '30px',
                md: '50px',
            }}
        >
            <ImgButton noMargin variant={taskDone ? 'markTick' : 'markAwait'}></ImgButton>
        </Flex>
    )
}

const ShareSteps = ({
    rules,
    usageCount,
    usageTimes,
    faithToClaim,
    onClaimFaith,
    onShareStateChanged,
    shared,
    accepted,
    completed,
}) => {
    const toast = useToast()

    const acceptCode = (_addr, _code) => {
        _addr &&
            _code &&
            fetch(`/api/accept/${_addr}/${_code}`, { method: 'POST' })
                .then(async (_data) => {
                    if (_data.status === 200) {
                        const shareState = await _data.json()
                        console.log('Code Accepted, the Sharing State: ', address, shareState)
                        onShareStateChanged(shareState)
                        toast.closeAll()
                        toast({
                            title: `Code Accepted, you've got ${shareState.rules.accept} more Faith to claim`,
                            status: 'success',
                            isClosable: true,
                        })
                    } else {
                        const errMsg = await _data.text()
                        toast.closeAll()
                        toast({
                            title: ` ${errMsg}`,
                            status: 'error',
                            isClosable: true,
                        })
                        inputValueRef.current.select()
                    }
                    return _data
                })
                .catch((err) => {
                    console.log('[Server Error]', err)
                    toast.closeAll()
                    toast({
                        title: `Incorrect response data, please wait for a while and try again.`,
                        status: 'error',
                        isClosable: true,
                    })
                })
    }

    const inputValueRef = React.useRef(null)
    const { address } = useAccount()

    const [uriRefCode, setUriRefCode] = useState('')
    const { isOpen, onOpen, onClose } = useDisclosure()
    function confirmAccepted() {
        onClose()
        console.log('confirm accepted')
        acceptCode(address, uriRefCode)
    }
    function confirmCancelled() {
        onClose()
        console.log('confirm cancelled')
        window.history.pushState({}, null, window.location.pathname) // remove parameters
    }

    useEffect(() => {
        inputValueRef.current.focus()
        const refCodeUri = window.location.href.split('r=')?.[1]
        if (refCodeUri) {
            const theCode = `${refCodeUri}`.trim().toLowerCase().padStart(7, '0')
            setUriRefCode(theCode)
            onOpen()
            inputValueRef.current.value = theCode
            inputValueRef.current.select()
        }
    }, [])

    const { balance: faithBalance } = useFaithBalance({ address: address })
    const navigate = useNavigate()

    return (
        <VStack w="100%" h="100%" align="start" justify="flex-start" gap="0">
            <ConfirmDialog
                code={uriRefCode}
                isOpen={isOpen}
                onOpen={onOpen}
                onOk={confirmAccepted}
                onCancel={confirmCancelled}
            />

            <Flex
                className="faith-claim-box"
                mt={{ base: '50px', md: '70px' }}
                mb={{ base: '10px', md: '20px' }}
                justify="flex-start"
                align="center"
                w="100%"
                // bg="whiteAlpha.500"
                p="0px"
                gap="0"
                h={{ base: '55px', md: '80px' }}
            >
                <Tooltip label="Click Faith to learn more" placement="top">
                    <Flex px="20px" m="0px" w={{ base: '120px', md: '180px' }} h="100%">
                        <Box w="100%" h="100%" onClick={() => navigate('/forge?from=wakumba')} cursor="pointer"></Box>
                    </Flex>
                </Tooltip>

                <Flex display="inline" mr="10px">
                    <Box fontSize={{ base: '25px', md: '35px' }} m="0px">
                        x {faithToClaim}
                    </Box>
                    <Box
                        fontSize={{ base: '10px', md: '13px' }}
                        m="0px"
                        onClick={() => navigate('/forge?from=wakumba')}
                        cursor="pointer"
                        _hover={{ textDecoration: 'underline' }}
                    >
                        ( {faithBalance} in Wallet )
                    </Box>
                </Flex>
                <Flex h={{ base: '55px', md: '65px' }}>
                    <ImgButton
                        disabled={!onClaimFaith}
                        position="relative"
                        h="100%"
                        w={{ base: '80px', md: '100px' }}
                        variant="btnClaim"
                        noMargin
                        mr={{ base: '30px', md: '80px' }}
                        onClick={() => {
                            if (faithToClaim > 0) {
                                onClaimFaith?.()
                            }
                        }}
                        clickTimeout={1500}
                    />
                </Flex>
            </Flex>

            <Flex
                className="enter-code-box"
                w="100%"
                align="center"
                // bg="blackAlpha.300"
                pt={{ base: '45px', md: '70px' }}
            >
                <StatusBtn taskDone={accepted} />

                <InputGroup
                    // bg="whiteAlpha.500"
                    w={{
                        base: '8rem',
                        md: '11rem',
                    }}
                    fontSize="20px"
                    ml={{
                        base: '100px',
                        md: '150px',
                    }}
                    h="2.5rem"
                >
                    <Input
                        pl="10px"
                        variant="unstyled"
                        placeholder="Ref Code"
                        _placeholder={{
                            color: 'gray',
                        }}
                        ref={inputValueRef}
                        type="text"
                        maxLength={10}
                        onFocus={(e) => {
                            e.target.select()
                        }}
                    />
                    <InputRightElement>
                        <ImgButton
                            h="45px"
                            w="45px"
                            noMargin
                            variant="btnOK"
                            onClick={() => acceptCode(address, inputValueRef.current.value)}
                            clickTimeout={1500}
                        >
                            OK
                        </ImgButton>
                    </InputRightElement>
                </InputGroup>
            </Flex>

            <Flex
                className="share-twitter-box"
                w="100%"
                align="center"
                // bg="blackAlpha.300"
                pt={{ base: '0px', md: '25px' }}
            >
                <StatusBtn taskDone={shared} />
                <Flex color="black" ml={{ base: '180px', md: '260px' }}>
                    <Heading>:</Heading>
                    <BtnPray alt="Pray" ml="10px" onShareStateChanged={onShareStateChanged} isMobile={true} />
                </Flex>
            </Flex>

            <Flex
                className="invitations-box"
                w="100%"
                align="center"
                // bg="blackAlpha.300"
                pt={{ base: '25px', md: '52px' }}
            >
                <Popover placement="bottom" isLazy>
                    <PopoverTrigger>
                        <Box>
                            <StatusBtn taskDone={completed} />
                        </Box>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>Invitation History</PopoverHeader>
                        <PopoverBody>
                            {usageTimes && usageTimes.length > 0 ? (
                                usageTimes.map((s, i) => {
                                    return (
                                        <Box key={i} fontSize={15}>
                                            {i + 1}. {new Date(s).toLocaleString()}
                                        </Box>
                                    )
                                })
                            ) : shared ? (
                                <Box fontSize={15}>No one accepted your blessing code yet ... </Box>
                            ) : (
                                <Box fontSize={15}>Your blessing code is not shared yet ... </Box>
                            )}
                        </PopoverBody>
                        <PopoverFooter></PopoverFooter>
                    </PopoverContent>
                </Popover>

                <Heading
                    fontSize={{
                        base: '26px',
                        md: '40px',
                    }}
                    ml={{
                        base: '6px',
                        md: '15px',
                    }}
                    color="black"
                >
                    {usageCount >= rules.usageCountForSword ? rules.usageCountForSword : usageCount}
                </Heading>
            </Flex>
        </VStack>
    )
}

export default ShareSteps
