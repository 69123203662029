import { React, useEffect, useMemo, useState } from 'react'
import { Box, Center, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Toast, Flex } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { LoreTitle, LoreArticle } from './stories/Lore'
import { ChapterOneOneTitle, ChapterOneOneArticle } from './stories/ChapterOneOne'
import { ChapterOneTwoTitle, ChapterOneTwoArticle } from './stories/ChapterOneTwo'
import { ChapterOneThreeTitle, ChapterOneThreeArticle } from './stories/ChapterOneThree'
import { useLocation } from 'react-router-dom'
import ImgButton from '../components/ImgButton'
import CenterLayout from './layouts/CenterLayout'
import { StoryMap } from '../components/Backgrounds'
import { useEnv } from '../utils/Environment'
import { CURRENT_STAGE_DFAULT } from './hooks/SiteStage'

const stories = [
    {
        name: 'Prologue',
        url: '/story?chapter=prologue',
        title: LoreTitle,
        article: LoreArticle,
        minStage: 1,
    },
    {
        name: 'Chapter 1-1',
        url: '/story?chapter=chapter-1-1',
        title: ChapterOneOneTitle,
        article: ChapterOneOneArticle,
        minStage: 7,
    },
    {
        name: 'Chapter 1-2',
        url: '/story?chapter=chapter-1-2',
        title: ChapterOneTwoTitle,
        article: ChapterOneTwoArticle,
        minStage: 8,
    },
    {
        name: 'Chapter 1-3',
        url: '/story?chapter=chapter-1-3',
        title: ChapterOneThreeTitle,
        article: ChapterOneThreeArticle,
        minStage: 10,
    },
]

function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
}

function onPage(story, chapter) {
    return story.url.endsWith(`?chapter=${chapter}`)
}

function StoryMenu({ chapter, stage }) {
    console.log('stage:', stage)
    return (
        <Center
            id="stories"
            w="100%"
            my={{ base: '4em', md: '2em', lg: '1.5em' }}
            // mx="1em"
            textAlign="center"
            fontSize={{ base: 'xs', md: 'lg', lg: 'xl' }}
            color="blackAlpha.800"
        >
            <Breadcrumb separator={<ChevronRightIcon color="white.500" />}>
                {stories.map((s) => (
                    <BreadcrumbItem
                        key={s.url}
                        isCurrentPage={onPage(s, chapter)}
                        textDecor={onPage(s, chapter) ? 'underline' : ''}
                        fontWeight={onPage(s, chapter) ? 'bold' : ''}
                    >
                        <BreadcrumbLink
                            color={s.minStage > stage ? 'gray' : ''}
                            href={s.minStage > stage ? '#stories' : s.url}
                        >
                            {s.name}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                ))}
            </Breadcrumb>
        </Center>
    )
}

function StoryTitle({ chapter, stage }) {
    return (
        <Box>
            {stories
                .filter((s) => onPage(s, chapter))
                .map((s) => (
                    <s.title key={s.url} />
                ))}
        </Box>
    )
}

function StoryArticle({ chapter, stage, lang }) {
    return (
        <Box w="100%">
            {stories
                .filter((s) => onPage(s, chapter))
                .filter((s) => s.minStage <= stage)
                .map((s) => (
                    <s.article key={s.url} lang={lang} />
                ))}
        </Box>
    )
}

function Story() {
    // site stage
    const [stage, setStage] = useState(CURRENT_STAGE_DFAULT)
    useEffect(() => {
        fetch('/api/stages/current')
            .then((_msg) => _msg.json())
            .then((_data) => setStage(_data.stage))
            .catch((err) => {
                console.log('Fetch Stage Failed: ', err)
                setStage(CURRENT_STAGE_DFAULT)
                Toast({
                    title: `Unstable Network, please check connections`,
                    status: 'error',
                    isClosable: true,
                })
            })
    }, [])

    let query = useQuery()
    let chapter =
        query.get('chapter') ||
        stories
            .filter((s) => s.minStage <= stage)
            .pop()
            .url.split('?chapter=')[1]
    let lang = query.get('lang') || 'en_US'

    console.log('chapter', chapter)

    return (
        <CenterLayout>
            <Box
                id="storyBook"
                mt="10%"
                px={['', '', '30px', '50px']}
                backgroundSize="100% 100%"
                backgroundAttachment="initial"
                backgroundRepeat="no-repeat"
                width="100%"
                height="100%"
            >
                <Box id="storyContent" px="10%" py="0">
                    <StoryMenu chapter={chapter} stage={stage} />

                    <StoryTitle chapter={chapter} stage={stage} />

                    <StoryArticle chapter={chapter} stage={stage} lang={lang} />

                    <Center>
                        <ImgButton
                            m="1em"
                            w="280px"
                            variant="backtop"
                            onClick={() => window.scrollTo(0, 0)}
                        ></ImgButton>
                    </Center>

                    <StoryMenu chapter={chapter} stage={stage} />
                    <Box h={['70px', '90px', '110px', '130px', '150px']}></Box>
                </Box>
            </Box>
        </CenterLayout>
    )
}

export default Story
