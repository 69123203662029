import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Link, Text, useToast } from '@chakra-ui/react'
import { BigNumber, ethers } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { m } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import {
    useBalance,
    useContractEvent,
    useContractRead,
    useContractWrite,
    usePrepareContractWrite,
    useWaitForTransaction,
} from 'wagmi'
import AirdropNFT from '../../contracts/AirDropNFT'
import TheForgeContract from '../../contracts/TheForgeContract'
import { handleContractError } from '../../utils/ContractUtils'
import { useEnv } from '../../utils/Environment'

const swordContractConfig = {
    addressOrName: process.env.REACT_APP_AIRDROP_CONTRACT_ADDR,
    contractInterface: AirdropNFT.abi,
}

const faithTokenConfig = {
    addressOrName: process.env.REACT_APP_FAITH_TOKEN_CONTRACT_ADDR,
}

const forgeContractConfig = {
    addressOrName: process.env.REACT_APP_FORGE_CONTRACT_ADDR,
    contractInterface: TheForgeContract.abi,
}

function useSwordRates() {
    const { data: swordRates } = useContractRead({
        functionName: 'getRates',
        // watch: true,
        ...forgeContractConfig,
        onSuccess(_data) {
            console.log('Get Rates:', _data)
        },
    })

    const rates =
        swordRates && swordRates[0] && swordRates[1]
            ? swordRates[1].map((r, i) => ((r / swordRates[0]) * 100).toFixed(1) + '%')
            : []

    return { rates: rates }
}

function useSwordBalances({ address, swordIds }) {
    const { data: swordBalances, refetch } = useContractRead({
        functionName: 'balanceOfBatch',
        watch: true,
        ...swordContractConfig,
        args: [swordIds.map((_) => address), swordIds],
        onSuccess(_data) {
            console.log('Get balances of Swords:', address, _data)
        },
    })

    return {
        balances: swordBalances ? swordBalances.map((b) => (b && b.toNumber ? b.toNumber() : 0)) : [0, 0, 0, 0, 0],
        refetch: refetch,
    }
}

function useStarBalance({ address }) {
    const { data: starBalance } = useContractRead({
        functionName: 'getStarBalance',
        watch: true,
        ...forgeContractConfig,
        args: [address],
        onSuccess(_data) {
            console.log('Get balance of Star:', address, _data)
        },
    })
    return { balance: starBalance && starBalance.toNumber ? starBalance.toNumber() : 0 }
}

function useFaithBalance({ address }) {
    const { data: faithBalance } = useBalance({
        addressOrName: address,
        token: faithTokenConfig.addressOrName,
        watch: true,
        onSuccess(_data) {
            console.log('Get balance of Faith:', address, _data)
        },
    })
    return {
        balance:
            faithBalance && faithBalance.value && formatEther(faithBalance.value)
                ? parseInt(formatEther(faithBalance?.value))
                : 0,
    }
}

function useFaithToStarContract({ address, args, onTxStarted, onTxSettled }) {
    const toast = useToast()
    const waitForTxToastRef = useRef()

    const { config: faithToStarConfig } = usePrepareContractWrite({
        ...forgeContractConfig,
        functionName: 'faithToStar',
        args: args,
    })
    const {
        data: faithToStarData,
        isError: isFaithToStarError,
        error: faithToStarError,
        isLoading: isFaithToStarLoading,
        isSuccess: isFaithToStarStarted,
        status: faithToStarStatus,
        write: faithToStar,
    } = useContractWrite({
        ...faithToStarConfig,
        onError(error) {
            handleContractError('faithToStar Write', error, toast)

            // if failed, refresh again
            // setRefresh(refresh + 1)
        },
    })

    const [txResponse, setTxResponse] = useState(null)
    const { isDev, network } = useEnv()

    const {
        isLoading: isFaithToStarTxLoading,
        isSuccess: isFaithToStarTxSuccess,
        isError: isFaithToStarTxError,
    } = useWaitForTransaction({
        confirmations: 1,
        hash: faithToStarData?.hash,
        wait: faithToStarData?.wait,
        onSuccess(data) {
            console.log('FaithToStarTxSuccess:', data)
            console.log('FaithToStarTxSuccess Logs:', data.logs)

            if (!txResponse || txResponse.hash !== faithToStarData?.hash) {
                const topicMintStarOfBravery = '0xd7aff45a8d9965b7dac7cec0f3294ade5526ee45875e9637860e2105422f9952'

                const mintData = data.logs.filter((s) => s.topics[0] === topicMintStarOfBravery)[0]?.data

                if (mintData) {
                    setTxResponse({
                        hash: faithToStarData?.hash,
                        status: 'MINT',
                        address: ethers.utils.hexDataSlice(mintData, 0, 32),
                        mintQty: BigNumber.from(ethers.utils.hexDataSlice(mintData, 32, 64)).toNumber(),
                        balanceAfterMint: BigNumber.from(ethers.utils.hexDataSlice(mintData, 64, 96)).toNumber(),
                        from: 'await',
                    })
                } else {
                    setTxResponse(null)
                }
            }
        },
        onError(error) {
            console.log('FaithToStarTxError:', error)
            const msg = error.internal?.message || error.reason
            const [type, reason] = msg.split(': ')
            toast({
                title: type
                    ? `Error: ${type}${reason ? '(' + reason.trim() + ')' : ''}`
                    : `Rejected, please try again later.`,
                status: 'error',
                isClosable: true,
            })
            setTxResponse(null)
        },
        onSettled(data, error) {
            console.log('FaithToStarTxSettle:', data, error)
            toast({
                title: `Transaction Confirmed!`,
                description: (
                    <Link
                        href={`https://${isDev ? network.chain + '.' : ''}etherscan.io/tx/${faithToStarData?.hash}`}
                        isExternal={true}
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        float="left"
                        width="320px"
                    >
                        <ExternalLinkIcon />
                        Tx# {faithToStarData?.hash}
                    </Link>
                ),
                position: 'top-right',
                status: 'info',
                isClosable: true,
            })

            if (waitForTxToastRef.current) {
                toast.close(waitForTxToastRef.current)
            }

            onTxSettled()
        },
    })

    useEffect(() => {
        if (isFaithToStarTxLoading) {
            waitForTxToastRef.current = toast({
                title: `Processing transaction, please wait for a while...`,
                status: 'success',
                isClosable: true,
                duration: 15 * 1000,
            })
        }
    }, [isFaithToStarTxLoading])

    useContractEvent({
        ...forgeContractConfig,
        eventName: 'MintStarOfBravery',
        listener: (event) => {
            console.log('Received: MintStarOfBravery Event', event)
            console.log(JSON.stringify(event), event)
            console.log('addr match?', event[0] === address)
            const hash = event[event.length - 1].transactionHash
            console.log('TransHash:', hash, faithToStarData?.hash)

            if (event[0] === address && hash === faithToStarData?.hash) {
                const mintQty = event[1]?.toNumber()
                const balanceAfterMint = event[2]?.toNumber()

                setTxResponse({
                    hash: faithToStarData?.hash,
                    status: 'MINT',
                    address: address,
                    mintQty: mintQty,
                    balanceAfterMint: balanceAfterMint,
                    from: 'event',
                })
            }
        },
    })

    useEffect(() => {
        if (txResponse) {
            const mintQty = txResponse.mintQty
            const balanceAfterMint = txResponse.balanceAfterMint

            console.log(
                'Faith To Star Completed! Stars Received: ',
                mintQty,
                ', Current balance: ',
                balanceAfterMint,
                ', Source: ',
                txResponse.from
            )

            toast({
                title: `Successfully received ${mintQty}x StarOfBravery!`,
                status: 'success',
                duration: 15_000,
                isClosable: true,
            })
        }
    }, [txResponse, setTxResponse])

    return faithToStar
}

function useEnhanceSwordContract({
    address,
    args,
    swordConfig,
    onLoading,
    onTxStarted,
    onTxCancelled,
    onSwordEnhanceSuccess,
    onSwordEnhanceFail,
    onTxSettled,
}) {
    const toast = useToast()
    const waitForTxToastRef = useRef()

    const { config: enhanceSwordConfig } = usePrepareContractWrite({
        ...forgeContractConfig,
        functionName: 'enhanceSword',
        args: args,
    })

    // add 10% extra gas to avoid OFG error
    if (enhanceSwordConfig && enhanceSwordConfig.request && enhanceSwordConfig.request.gasLimit) {
        console.log('enhanceSwordConfig Gas:', enhanceSwordConfig.request.gasLimit.toBigInt())
        enhanceSwordConfig.request.gasLimit = enhanceSwordConfig.request.gasLimit
            .mul(BigNumber.from('110'))
            .div(BigNumber.from('100'))
        console.log('enhanceSwordConfig NEW Gas:', enhanceSwordConfig.request.gasLimit.toBigInt())
    }

    const {
        data: enhanceSwordData,
        isError: isEnhanceSwordError,
        error: enhanceSwordError,
        isLoading: isEnhanceSwordLoading,
        isSuccess: isEnhanceSwordStarted,
        status: enhanceSwordStatus,
        write: enhanceSword,
    } = useContractWrite({
        ...enhanceSwordConfig,
        onError(error) {
            handleContractError('enhanceSword Write', error, toast)

            // if failed, refresh again
            // setRefresh(refresh + 1)
            onTxCancelled()
        },
        onSuccess(msg) {
            onTxStarted()
        },
    })

    useEffect(() => {
        if (isEnhanceSwordLoading) {
            onLoading()
        }
    }, [isEnhanceSwordLoading])

    const [txResponse, setTxResponse] = useState(null)
    const { isDev, network } = useEnv()

    // Checking Transactions
    const {
        isLoading: isEnhanceTxLoading,
        isSuccess: isEnhanceTxSuccess,
        isError: isEnhanceTxError,
    } = useWaitForTransaction({
        confirmations: 1,
        hash: enhanceSwordData?.hash,
        wait: enhanceSwordData?.wait,
        onSuccess(data) {
            console.log('EnhanceTxSuccess:', data)
            console.log('EnhanceTxSuccess Logs:', data.logs)
            if (!txResponse || txResponse.hash !== enhanceSwordData?.hash) {
                const topicEnhanceSuccess = '0xddc2d03b323881e2e61f92905d311445fa511df8db43fae5e696ab8c3dd6b3a2'
                const topicEnhanceFailed = '0x762be71a6134dc2bfcb91061a407763618fd46269e26298a3d6c43a411f499eb'

                const mintData = data.logs.filter((s) => s.topics[0] === topicEnhanceSuccess)[0]?.data
                const failData = data.logs.filter((s) => s.topics[0] === topicEnhanceFailed)[0]?.data

                if (mintData) {
                    setTxResponse({
                        hash: enhanceSwordData?.hash,
                        status: 'SUCCESS',
                        address: ethers.utils.hexDataSlice(mintData, 0, 32),
                        starsUsed: BigNumber.from(ethers.utils.hexDataSlice(mintData, 32, 64)).toNumber(),
                        swordId: BigNumber.from(ethers.utils.hexDataSlice(mintData, 64, 96)).toNumber(),
                        newSwordId: BigNumber.from(ethers.utils.hexDataSlice(mintData, 96, 128)).toNumber(),
                        from: 'await',
                    })
                } else if (failData) {
                    setTxResponse({
                        hash: enhanceSwordData?.hash,
                        status: 'FAIL',
                        address: ethers.utils.hexDataSlice(failData, 0, 32),
                        starsUsed: BigNumber.from(ethers.utils.hexDataSlice(failData, 32, 64)).toNumber(),
                        swordId: BigNumber.from(ethers.utils.hexDataSlice(failData, 64, 96)).toNumber(),
                        from: 'await',
                    })
                } else {
                    setTxResponse(null)
                }
            }
        },
        onError(error) {
            console.log('EnhanceTxError:', error)
            const msg = error.internal?.message || error.reason
            const [type, reason] = msg.split(': ')
            toast({
                title: type
                    ? `Error: ${type}${reason ? '(' + reason.trim() + ')' : ''}`
                    : `Rejected, please try again later.`,
                status: 'error',
                isClosable: true,
            })
            setTxResponse(null)
        },
        onSettled(data, error) {
            console.log('EnhanceTxSettle:', data, error)
            toast({
                title: `Transaction Confirmed!`,
                description: (
                    <Link
                        href={`https://${isDev ? network.chain + '.' : ''}etherscan.io/tx/${enhanceSwordData?.hash}`}
                        isExternal={true}
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        float="left"
                        width="320px"
                    >
                        <ExternalLinkIcon />
                        Tx# {enhanceSwordData?.hash}
                    </Link>
                ),
                position: 'top-right',
                status: 'info',
                isClosable: true,
            })

            if (waitForTxToastRef.current) {
                toast.close(waitForTxToastRef.current)
            }

            // refresh when complete
            onTxSettled()
        },
    })

    useEffect(() => {
        if (isEnhanceTxLoading) {
            waitForTxToastRef.current = toast({
                title: `Processing transaction, please wait for a while...`,
                status: 'success',
                isClosable: true,
                duration: 15 * 1000,
            })
        }
    }, [isEnhanceTxLoading])

    // Event Listeners
    useContractEvent({
        ...forgeContractConfig,
        eventName: 'SwordEnhanceSuccess',
        listener: (event) => {
            console.log('Received: SwordEnhanceSuccess Event', event)
            console.log(JSON.stringify(event), event)
            console.log('addr match?', event[0] === address)
            const hash = event[event.length - 1].transactionHash
            console.log('TransHash:', hash, enhanceSwordData?.hash)

            if (event[0] === address && hash === enhanceSwordData?.hash) {
                if (!txResponse || txResponse.hash !== hash) {
                    const starsUsed = event[1]
                    const prevSwordId = event[2]
                    const newSwordId = event[3]

                    setTxResponse({
                        hash: enhanceSwordData?.hash,
                        status: 'SUCCESS',
                        address: address,
                        starsUsed: starsUsed,
                        swordId: prevSwordId,
                        newSwordId: newSwordId,
                        from: 'event',
                    })
                }
            }
        },
    })

    useContractEvent({
        ...forgeContractConfig,
        eventName: 'SwordEnhanceFail',
        listener: (event) => {
            console.log('Received: SwordEnhanceFail Event', event)
            console.log(JSON.stringify(event), event)
            console.log('addr match?', event[0] === address)
            const hash = event[event.length - 1].transactionHash
            console.log('TransHash:', hash, enhanceSwordData?.hash)

            if (event[0] === address && hash === enhanceSwordData?.hash) {
                if (!txResponse || txResponse.hash !== hash) {
                    const starsUsed = event[1]
                    const swordId = event[2]

                    setTxResponse({
                        hash: enhanceSwordData?.hash,
                        status: 'FAIL',
                        address: address,
                        starsUsed: starsUsed,
                        swordId: swordId,
                        from: 'event',
                    })
                }
            }
        },
    })

    useEffect(() => {
        if (txResponse) {
            if (txResponse.status === 'SUCCESS') {
                const prevSwordId = txResponse.swordId
                const newSwordId = txResponse.newSwordId
                const starsUsed = txResponse.starsUsed
                console.log(
                    'Sword Enhance Completed! From Level #',
                    prevSwordId,
                    ' To Level #',
                    newSwordId,
                    ', Stars Used: ',
                    starsUsed,
                    ', Source: ',
                    txResponse.from
                )
                toast({
                    title: `Successfully enhanced Sword to Level #${swordConfig[newSwordId].quality}!`,
                    description: `Stars Used: ${starsUsed}`,
                    status: 'success',
                    duration: 15_000,
                    isClosable: true,
                })
                onSwordEnhanceSuccess(newSwordId, prevSwordId, starsUsed)
            } else if (txResponse.status === 'FAIL') {
                const swordId = txResponse.swordId
                const starsUsed = txResponse.starsUsed
                console.log(
                    'Sword Enhance Failed, the swordId: ',
                    swordId,
                    ' Stars Used: ',
                    starsUsed,
                    ', Source: ',
                    txResponse.from
                )
                toast({
                    title: `Fail to enhanced Sword, nothing changed!`,
                    description: `Stars Used: ${starsUsed}`,
                    status: 'warning',
                    duration: 15_000,
                    isClosable: true,
                })
                onSwordEnhanceFail(swordId, starsUsed)
            }
        }
    }, [txResponse, setTxResponse])

    return enhanceSword
}

export {
    useSwordRates,
    useSwordBalances,
    useStarBalance,
    useFaithBalance,
    useFaithToStarContract,
    useEnhanceSwordContract,
}
